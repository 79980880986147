<!--
*************************************************************************************
Campaign Detail View (Header)
*************************************************************************************
-->
<div class="content table-search-row second-header-fixed">
  <div class="text-wrapper">
    <div class="w-100 flex flex-row">
      <div class="col-1 flex flex-row">
        <div class="my-auto">
          <span pButton icon="pi pi-arrow-circle-left" iconPos="left" label="Back" class="p-button-outlined back-button"
            (click)="onBack()"></span>
        </div>
      </div>
      <div class="flex flex-column">
        <div>
          Campaign Name
          <ng-container *ngIf="!campaign?.isPublishDbRecord">
            <button id="btnEditCampaignName" type="button" class="p-column-filter-menu-button header_title_campaign"
              (click)="onShowDescriptionDialog()">
              <span class="fa fa-pencil text-blue pencil"></span>
            </button>
          </ng-container>
        </div>
        <div>
          <label id="lblCampaignName" class="text-dark" style="padding:0; top:0;font-weight: bolder;">
            <span *ngIf="this.campaign.name.length <= 80">
              {{this.campaign.name}}
            </span>
            <span *ngIf="this.campaign.name.length > 80" tooltipPosition="top" pTooltip="{{this.campaign.name}}">
              {{this.campaign.name | slice:0:80 }}...
            </span>
            <span *ngIf="campaign.description" [title]="" class="fa fa-info-circle info-icon info"
              pTooltip="{{campaign.description}}" tooltipPosition="top">
            </span>
          </label>
        </div>
      </div>
      <div class="flex flex-row mr-0 ml-auto">
        <div class="flex flex-column justify-content-center">
          <span class="campaign-field-title header_title_campaign set-position" 
              style="width: auto;margin-right: 10px;font-style: italic;text-align: start;padding:0;">
              Campaign Owner: {{campaign?.ownerName}} 
          </span>
          <span style="text-align: start;"> Last saved: {{
            campaign?.dateUpdatedUTC | date:"M/d/yy h:mm a"
            }}&nbsp;by&nbsp;{{ campaign?.updatedByName?.toString()}}
          </span>
        </div>
        <div class="flex flex-row ml-1">

          <div class="my-auto">
            <span id="btnDuplicateCanpaign" class="p-column-filter-menu-button mr-0" (click)="onShowCopyCampaignDialog();">
              <i class="pi pi-clone"></i>
            </span>
          </div>
          
          <div class="my-auto">
            <ng-container *ngIf="!campaign.isPublishDbRecord">
              <span id="btnDeleteCampaign" class="p-column-filter-menu-button" (click)="onShowDeleteCampaignDialog()">
                <i class="pi pi-trash"></i>
              </span>
            </ng-container>
          </div>

          <ng-container *ngIf="(isCompanyUserWithMarketing && campaign.activationMode =='admin-mandatory'); else showPublishDiv">
            <div class="my-auto">
              <p-inputSwitch disabled id="inputSwithActive" class="inputSwithActive" [(ngModel)]="campaign.published"></p-inputSwitch>
            </div>
          </ng-container>
          
          <ng-template #showPublishDiv>
            <div class="my-auto">
              <span *ngIf="campaign.published" class="switch pub-switch flex flex-row" (click)="onSwithInctive()">
                <div class="my-auto">
                  Active
                </div>
                <div class="my-auto mt-2 ml-1">
                  <p-inputSwitch id="inputSwithActive" class="inputSwithActive" [(ngModel)]="campaign.published" (onChange)="onSwithInctive()"></p-inputSwitch>
                </div>
              </span>
              <span *ngIf="!campaign.published" class="switch pub-switch flex flex-row" (click)="onSwithActive()">
                <span class="pub-text">Inactive</span>
                <div class="my-auto mt-2">
                  <p-inputSwitch id="inputSwithInActive" class="inputSwithActive" disabled="true" [(ngModel)]="campaign.published"></p-inputSwitch>
                </div>
              </span>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid text-wrapper grid-section px-0">
  <p-tabView styleClass="campaign-tabs mt-0" [(activeIndex)]="activeIndex">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span class="tab-label">Campaign Details</span>
      </ng-template>
      <div class="row my-4" *ngIf="!showTemplateEditor && showDetails">
        <div class="col-4 campaign-card">
          <app-campaign-detail-block #campaignDetailBlock (notifyParent)="openShowDetailsDialog($event)"
            [campaignDetail]="campaignBlockDetail">
          </app-campaign-detail-block>
        </div>
        <div class="col-md-8 pe-0">
          <div *ngIf="!isCampaignStatsLoading && campaign.templateTypeCount==1 && currentClientID !== AduvoAdmin"
            class="campaign-stats d-flex justify-content-between mb-4">
            <div class="card rounded-1 d-flex justify-content-center align-items-center">
              <span>Sent</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.sentCount | number}}</a>
            </div>
            <div class="card rounded-1 d-flex justify-content-center align-items-center">
              <span>Opened</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.openedPercent | number}}%</a>
            </div>
            <div class="card rounded-1 d-flex justify-content-center align-items-center">
              <span>Clicked</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.clickedPercent | number}}%</a>
            </div>
            <div class="card rounded-1 d-flex justify-content-center align-items-center "
              pTooltip="Emails that weren't able to be delivered because the email address is permanently unavailable, an email server outage is occurring, or the recipient's inbox is full."
              tooltipPosition="top">
              <span>Bounced</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.bouncedPercent | number}}%</a>
            </div>
            <div class="card rounded-1 d-flex justify-content-center align-items-center">
              <span>Opted&nbsp;Out</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.optedOutPercent | number}}%</a>
            </div>
          </div>
          <div *ngIf="!isCampaignStatsLoading && campaign.templateTypeCount==2 && currentClientID !== AduvoAdmin"
            class="campaign-stats d-flex justify-content-between mb-4">
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>Sent</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.SmsStats?.sentCount | number}}</a>
            </div>
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>Delivered</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.SmsStats?.totalDeliveredCount | number}}%</a>
            </div>
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>Blocked by Carrier</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.SmsStats?.totalBlockedCount | number}}%</a>
            </div>
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>Invalid Number</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.SmsStats?.totalInvalidCount | number}}%</a>
            </div>
          </div>
          <div *ngIf="!isCampaignStatsLoading && campaign.templateTypeCount==3 && currentClientID !== AduvoAdmin"
            class="campaign-stats d-flex justify-content-between mb-4">
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>Emails Sent</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.sentCount | number}}</a>
            </div>
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>Emails Opened</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.EmailStats?.openedPercent | number}}%</a>
            </div>
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>SMS Sent</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.SmsStats?.sentCount | number}}</a>
            </div>
            <div class="card card4 rounded-1 d-flex justify-content-center align-items-center">
              <span>SMS Delivered</span>
              <i *ngIf="isCampaignStatsLoading" class="pi pi-spin pi-spinner"
                style="font-size: 25px; margin-top: 10px;"></i>
              <a *ngIf="!isCampaignStatsLoading">{{campaignEmailStats?.SmsStats?.totalDeliveredCount | number}}%</a>
            </div>
          </div>
          <div class="card rounded-1 editor-card-header-text ">
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-white cam-msg-haeder-text">Campaign Messages</span>
              <button type="button" *ngIf="!campaign?.isPublishDbRecord"
                class="bg-tranparent-btn btn-rounded font-size-16 fw-bold" (click)="onShowNewMessageDialog()"
                [disabled]="!canAddMessage">
                <i class="pi pi-plus text-white"></i> Add Message
              </button>
            </div>
          </div>
          <div class="editor-card-hr message-table mt-3" style="height: 60%; background-color: white;">
            <div *ngIf="messages?.data?.length === 0" class="noMessages">
              <span>There are no messages in this campaign.</span>
              <span class="fa fa-flag-o" style="padding-left: 10px; color : red;" *ngIf="showValidationFlag"></span>
            </div>
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <div *ngIf="messages?.data?.length > 0" style="height: 100%;" pDroppable="!campaign.isPublishDbRecord">
                <p-table class="campaignMessageDetail" dataKey="campaignMessageId" [value]="messages?.data"
                  [expandedRowKeys]="expandedRows">
                  <ng-template pTemplate="body" let-message let-expanded="expanded" let-index="rowIndex">
                    <div cdkDrag [cdkDragDisabled]="campaign.isPublishDbRecord"
                      class="align-items-center d-flex justify-content-between message-item ng-star-inserted p-4 standard-row mt-4"
                      [pRowToggler]="message" (click)="getMessageDetails(message)"
                      [pReorderableRow]="campaign.isPublishDbRecord ?'' :index">
                      <div [ngClass]="!expanded && message.QuickPickStatusField? 'mb-25': 'mb-0'">
                        <span *ngIf="!campaign.isPublishDbRecord else noDragDrop" class="message-icon"
                          pReorderableRowHandle>
                          <i class="fa-regular fa-envelope fa-fw message-dialog-icon"
                            *ngIf="message.templateTypeId == 1"></i>
                          <i class="fa-regular fa-comment-lines fa-fw message-dialog-icon facomment"
                            *ngIf="message.templateTypeId == 2"></i>
                        </span>
                        <ng-template #noDragDrop>
                          <span class="message-icon">
                            <i class="fa-regular fa-envelope fa-fw message-dialog-icon"
                              *ngIf="message.templateTypeId == 1"></i>
                            <i class="fa-regular fa-comment-lines fa-fw message-dialog-icon facomment"
                              *ngIf="message.templateTypeId == 2"></i>
                          </span>
                        </ng-template>
                      </div>

                      <div class="d-flex flex-fill fs-5 fw-semibold px-4 text-black">
                        <div class="mb-0 message-list-header" style="cursor: pointer;">
                          {{message.messageTemplateTitle}} <br>
                          <div [innerHTML]="message.ScheduleMessage" class="template-info"
                            *ngIf="message.ScheduleMessage && !expanded">
                          </div>
                        </div>
                      </div>
                      <div>
                        <a role="button" class="p-3">
                          <img src="assets/images/blue-triangle.svg" alt="" style="height: 10px;" *ngIf="!expanded">
                          <img src="assets/images/blue-triangle.svg" alt=""
                            style="height: 10px; transform: rotate(180deg);" *ngIf="expanded">
                        </a>
                      </div>
                      <span class="fa fa-flag-o" style="float:right; color:red;"
                        *ngIf="showValidationFlag && !message.ScheduleMessage"></span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-message>
                    <tr style="background-color: #F5F5F5;position: relative;" *ngIf="message.isScheduleAlertShown">
                      <td style="padding: 0px 11px 12px 14px;">
                        <div class="card rounded-1 editor-card-header-text message-pop-up-header">
                          <div class="d-flex justify-content-between align-items-center px-3 py-2">
                            <span class="message-pop-up">{{message.QueuedMessage}}</span>
                            <a (click)="OnCancelQueueMessage(message)"
                              style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;text-decoration: underline;font-size: 14px;"
                              styleClass="p-button-secondary">Cancel Sending</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr style="background-color:#f5f5f5;">
                      <td colspan="3" style="margin-bottom: 10px; padding-top: 0; padding-left: 3rem;">
                        <div style="background-color: #f5f5f5;margin-top: 0px;">
                          <div style="margin-left: 10px; padding-left: 10px; padding-bottom: 40px;margin-top: -18px">
                            <button pButton pRipple type="button" class="p-button-secondary float-left btn-edit-msg"
                              style="background-color: #6e11f2;margin-top: 10px;margin-bottom: 10px;"
                              (click)="onEditMessageClick(message)"
                              *ngIf="!(campaign.isPublishDbRecord || message.isPublishingDb)">
                              Edit Message
                            </button>
                            <a href="javascript:void(0)" *ngIf="!campaign.isPublishDbRecord" style="margin-top: 16px;"
                              (click)="onChangeMessage($event, message);" class="ancher-links">Replace Message</a>
                            <a href="javascript:void(0)" style="margin-top: 16px;"
                              (click)="onMessagePreview($event, message);" class="ancher-links">Preview</a>
                            <a href="javascript:void(0)" *ngIf="!campaign.isPublishDbRecord" class="ancher-links"
                              style="margin-top: 16px;margin-bottom: 10px;"
                              (click)="onMessageDelete($event, message);">Delete Message</a>
                            <a href="javascript:void(0)" *ngIf="message?.templateTypeId==1"
                              style="margin-top: 16px;margin-bottom: 10px;" (click)="openSendEmailDialoge(message);"
                              class="ancher-links">Send Test Email</a>
                            <a href="javascript:void(0)" *ngIf="message?.templateTypeId==2"
                              style="margin-top: 16px;margin-bottom: 10px;"
                              (click)="openSendSMSDialog(message.campaignMessageId);" class="ancher-links">Send Test
                              Text
                              Message </a>
                          </div>

                          <div class="editcard-msg">
                            <table
                              *ngIf="!isCampaignStatsLoading && message?.templateTypeId==1 && currentClientID !== AduvoAdmin"
                              class="stats-table" style="margin-top: 5px;">
                              <tr>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Sent</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.sentCount |
                                      number
                                      }}
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Opened</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.openedPercent
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Clicked</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.clickedPercent
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div class="tooltip_width"
                                    pTooltip="Emails that weren't able to be delivered because the email address is permanently unavailable, an email server outage is occurring, or the recipient's inbox is full."
                                    tooltipPosition="top">
                                    <span>Bounced</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.bouncedPercent
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Opted&nbsp;Out</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.optedOutPercent
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <table
                              *ngIf="!isCampaignStatsLoading && message?.templateTypeId==2 && currentClientID !== AduvoAdmin"
                              class="stats-table" style="margin-top: 5px;">
                              <tr>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Sent</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.sentCount |
                                      number
                                      }}
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Delivered</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.totalDeliveredCount
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Blocked</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.totalBlockedCount
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                                <td valign="top" align="center">
                                  <div>
                                    <span>Invalid Number</span>
                                    <i *ngIf="message?.isCampaignMessageStatsLoading" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>
                                    <a *ngIf="!message?.isCampaignMessageStatsLoading">
                                      {{
                                      message.MessageStats?.totalInvalidCount
                                      | number
                                      }}%
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <div class="row" style="margin-bottom: 10px;">
                              <table class="col-auto" *ngIf="message.templateTypeId == 1">
                                <tr>
                                  <td>
                                    <span class="template-label">Subject Line</span>
                                  </td>
                                  <td align="right">
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2">
                                    <span class="subject-line" *ngIf="message.subject">{{message.subject}}</span>
                                    <span class="subject-line" *ngIf="!message.subject">(no subject)</span>
                                  </td>
                                </tr>
                              </table>
                              <div class="editor-card-hr"></div>
                              <table class="col-auto" *ngIf="message.templateTypeId == 1">
                                <tr>
                                  <td>
                                    <span class="template-label">Preview Text</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2">
                                    <span class="message-subject"
                                      *ngIf="message.preHeaderText">{{message.preHeaderText}}</span>
                                    <span class="message-subject" *ngIf="!message.preHeaderText">(no preview
                                      text)</span>
                                  </td>
                                </tr>
                              </table>
                              <table class="col-auto" *ngIf="message.templateTypeId == 2" style="margin-top: -15px;">
                                <tr>
                                  <td>
                                    <span class="template-label">Message Text</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2">
                                <span class="message-subject" *ngIf="message?.messageBody?.length">
                                      <p *ngFor="let messageText of message.messageBody;">{{messageText}}</p>

                                    </span>
                                    <span class="message-subject" *ngIf="message.messageBody.length==0">(no message
                                      text)</span>
                                  </td>
                                </tr>
                              </table>
                              <br />
                              <div style="width: 100%; background: #F5F5F5; height: 2px; margin-top: 10px;"></div>
                              <br />
                              <table style="margin-top: 10px;">
                                <tr>
                                  <td>
                                    <span class="template-label">Schedule</span>
                                  </td>
                                  <td align="right">
                                    <ng-container *ngIf="!campaign.isPublishDbRecord && !loadingPage">
                                      <span style="cursor: pointer;" [disabled]="campaign.isPublishDbRecord"
                                        class="fa fa-pencil text-blue pencil" id="btnEditCampaignMessageSchedule"
                                        (click)="onShowMessageScheduleDialog(message)"></span>
                                    </ng-container>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2">
                                    <i *ngIf="loadingPage" class="pi pi-spin pi-spinner"
                                      style="font-size: 25px; margin-top: 10px;"></i>

                                    <div *ngIf="!loadingPage">
                                      <ng-container #noLoader *ngIf="message.ScheduleMessage; else noSchedule">
                                        <div class="divScheduleMessage" [innerHTML]="message.ScheduleMessage"></div>
                                      </ng-container>
                                      <ng-template #noSchedule>
                                        (No Schedule)
                                      </ng-template>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </ng-template>

                  <ng-template pTemplate="footer" class="">
                    <br style="height:20px;" />
                    <tr>
                      <td colspan="3" style="padding: 0; background-color: white;">
                        <button pButton pRipple class="p-button-text campaignAddMessageButton"
                          *ngIf="!campaign.isPublishDbRecord"
                          style="color:#BDD1E6 !important;  width: 100%;border: 1px dashed #BDD1E6; border-radius: 0;text-align: center; min-height: 5rem; max-height: 5rem;"
                          (click)="onShowNewMessageDialog()" [disabled]="!canAddMessage">
                          <i class="pi pi-plus" style="margin-left: 40%;"></i>&nbsp;Add Message
                          <span class="fa fa-flag-o" style="padding-left: 10px; float:right; color:red;"
                            *ngIf="showValidationFlag"></span>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span class="tab-label">Report</span>
      </ng-template>
      <div>
        <app-campaign-report (loadMessageLogEvent)="loadMessageLogsWithFiltered($event)"></app-campaign-report>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span class="tab-label">Message Log</span>
      </ng-template>
      <div>
        <app-message-log-grid></app-message-log-grid>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="this.currentClientID === 'AduvoAdmin'">
      <ng-template pTemplate="header">
        <span class="tab-label">Usage</span>
      </ng-template>
      <div>
        <app-campaign-usage></app-campaign-usage>
      </div>
    </p-tabPanel>
  </p-tabView>

</div>

<div *ngIf="!showTemplateEditor && showSentMessageLog">
  <sentMessageLog-root #sentMessageLog (onReportLinkClick)="onReportLinkClick()"></sentMessageLog-root>
</div>
<div *ngIf="!showTemplateEditor && showSubscriberActivity">
  <subscriberActivity-root #subscriberActivity></subscriberActivity-root>
</div>
<div *ngIf="!showTemplateEditor && showReports">
  <campaignStatsOverview-root #campaignStats [emailStats]="emailStats"></campaignStatsOverview-root>
</div>

<p-dialog class="custom-dialog" id="campaignDesc" #campaignDesc [resizable]="false"
  (onHide)="onCloseDescriptionDialog()" [(visible)]="showCampaignDescDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '45em','font-weight': '700'}">
  <p-header>
    <span class="dialog-head">{{campaignDescDialogTitle}}</span>
  </p-header>
  <campaignDescriptionDialog-root #campaignDescDialog [campaign]="campaign"></campaignDescriptionDialog-root>
  <ng-template pTemplate="footer">
    <div class="d-flex">
      <a (click)="onCancelDescriptionDialog()" class="link-text"
        style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (onClick)="onSaveDescriptionDialog()" [style]="{'margin-left': '17.5px'}"
        [disabled]="campaign.name == ''" label="Save"></p-button>
    </div>
  </ng-template>
</p-dialog>


<!--
*************************************************************************************
Campaign Details Dialog
*************************************************************************************
-->
<p-dialog id="campaign_details" class="custom-dialog" #campaignDetails [resizable]="false"
  (onHide)="onCloseDetailsDialog()" [(visible)]="showCampaignDetailsDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '50em','font-weight': '700'}">
  <p-header>
    <span class="dialog-head">{{campaignDetailsDialogTitle}}</span>
  </p-header>
  <campaignDetailsDialog-root [campaignCategoryList]="campaignCategoryList"
    [isCompanyUserWithMarketing]="isCompanyUserWithMarketing" [loanAssignTypeRoles]="loanAssignTypeRoles"
    [masterContactTypes]="masterContactTypes" [loanStatusTypeMasterList]="loanStatusTypeMasterList"
    [userList]="userList" [loanStatusList]="loanStatusList" #campaignDetailsDialog></campaignDetailsDialog-root>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a (click)="onCloseDetailsDialog()"
        style="cursor: pointer;margin: 0px 17.5px; color:#2e2e2e;text-decoration: underline;font-weight: 500;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (onClick)="onUpdateCampaignDetailsDialog()" [style]="{'margin-left': '17.5px'}"
        [disabled]="campaign.isPublishDbRecord || validateCampaignDetails()" label="Save"></p-button>
    </div>

  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Campaign Message Subject Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" id="campaignMessageSubject" #campaignMessageSubject [resizable]="false"
  (onHide)="onCloseMessageSubjectDialog()" [(visible)]="showMessageSubjectDialog" [modal]="true"
  [style]="{'width': '53em','font-weight': '700', 'z-index' : '1000!important'}">
  <p-header>
    <i class="pi pi-send" style="font-size: 1em; margin-right: 10px;"></i>
    <span class="dialog-head">Edit Subject Line & Preview Text</span>
  </p-header>
  <div class="mt-25"></div>
  <table style="width: 100%;">
    <tr>
      <td style="width: 20%; text-align: right;">
        <div style="width: 70%;"><span class="dialog-head">Subject Line</span></div>
      </td>
      <td style="width: 80%;">
        <div class="position-relative input-icon">
          <input #txtSubject id="txtSubject" type="text" class="form-control" style="width: 100%; padding-right: 50px;"
            [(ngModel)]="messageSubject" pInputText>
          <div class="subject-line-input-icons" style="cursor: pointer;">
            <i class="pi pi-tag fs-5 me-2" (click)="onEmailSubjectMergeTagRequested()"></i>
            <i class="fa-regular fs-5 fa-face-smile mx-2" (click)="op.toggle($event)"></i>
          </div>
        </div>
        <p-overlayPanel #op [showCloseIcon]="false" appendTo="body">
          <ng-template pTemplate>
            <emoji-mart title="Pick your emoji…" #emojiMart emoji="point_up" [darkMode]="false"
              (emojiClick)="emojiClicked($event)">
            </emoji-mart>
          </ng-template>
        </p-overlayPanel>
      </td>
    </tr>
    <div class="mt-25"></div>
    <tr>
      <td style="width: 20%;text-align: right;">
        <div style="width: 70%;"><span class="dialog-head">Preview Text</span></div>
      </td>
      <td style="width: 80%;">
        <div class="position-relative input-icon">
          <input maxlength="500" #txtMessagePreviewtext id="txtMessagePreviewtext" class="form-control" type="text"
            style="width: 100%; padding-right: 50px;" [(ngModel)]="preHeaderText" pInputText>
          <div class="subject-line-input-icons" style="cursor: pointer;">
            <i class="pi pi-tag fs-5 me-2" (click)="onEmailPreviewTextMergeTagRequested()"></i>
            <i class="fa-regular fs-5 fa-face-smile mx-2" (click)="opPreview.toggle($event)"></i>
          </div>
        </div>
        <p-overlayPanel #opPreview [showCloseIcon]="false" [appendTo]="'body'" [styleClass]="'emoji-mart-overlay'">
          <ng-template pTemplate>
            <emoji-mart title="Pick your emoji…" #emojiMartPreview emoji="point_up" [darkMode]="false"
              (emojiClick)="emojiClickedPreview($event)">
            </emoji-mart>
          </ng-template>
        </p-overlayPanel>
      </td>
    </tr>
    <div class="mt-25"></div>
  </table>
  <ng-template pTemplate="footer">
    <div style="display: flex;">
      <a (click)="onCancelMessageSubjectDialog()" type="button"
        style="cursor: pointer;margin: 8px 17.5px;text-decoration: underline;font-weight: 500;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (onClick)="onSaveMessageSubjectDialog()" [style]="{'margin-left': '17.5px'}"
        [disabled]="!messageSubject" label="Save"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Campaign Message Preview text Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" id="campaignMessagePreviewtext" #campaignMessagePreviewtext [resizable]="false"
  (onHide)="onCancelMessagePreviewtextDialog()" [(visible)]="showMessagePreviewtextDialog" [modal]="true"
  [style]="{'width': '45em','font-weight': '700', 'z-index' : '1000!important'}">
  <p-header>
    <span class="dialog-head">Email Preview Text</span>
  </p-header>
  <input #txtMessagePreviewtext id="txtMessagePreviewtext" type="text" style="width: 100%;" [(ngModel)]="preHeaderText"
    pInputText>
  <button type="button" style="margin-right: 0.5rem;" class="p-button-secondary" pButton=pButton icon="pi pi-plus"
    label="Merge Tags" (click)="onEmailPreviewTextMergeTagRequested()"></button>
  <ng-template pTemplate="footer">
    <div>
      <a (click)="onCancelMessagePreviewtextDialog()"
        style="color:#2e2e2e;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (onClick)="onSaveMessagePreviewtextDialog()" [style]="{'margin-left': '17.5px'}" label="Save">
      </p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Campaign Message Schedule Dialog
*************************************************************************************
-->
<app-campaign-message-schedule (DialogCloseEvent)="DialogCloseEvent($event)"
  *ngIf="messageScheduleData.showMessageScheduleDialog" [MessageScheduleData]="messageScheduleData">
</app-campaign-message-schedule>

<!--
*************************************************************************************
Send test email Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" id="sendEmail" #sendEmail [resizable]="false" (onHide)="onCloseSendTestEmailDialog()"
  [(visible)]="showSendTestEmailDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '62.5em','font-weight': '700'}">
  <p-header>
    <span class="dialog-head">Send Test Email</span>
  </p-header>
  <div *ngIf="!isEmailResponse">
    <table width="100%">
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">From</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtFromEmail id="txtFromEmail" [disabled]="true" (input)="validateEmailDialog()" type="text"
            style="width: 20rem;" [(ngModel)]="fromEmail" pInputText>
        </td>
      </tr>
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">To</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtTpEmail id="txtToEmail" type="text" (input)="validateEmailDialog()" style="width: 20rem;"
            [(ngModel)]="toEmail" pInputText>
        </td>
      </tr>
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">Subject Line</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtEmailSubject id="txtSubject" type="text" (input)="validateEmailDialog()" style="width: 100%;"
            [(ngModel)]="emailSubject" pInputText>
        </td>
      </tr>
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">Preview Text</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtEmailPreviewText id="txtPreheaderText" type="text" style="width: 100%;" [(ngModel)]="preHeaderText"
            maxlength="255" pInputText>
        </td>
      </tr>
    </table>
  </div>
  <div class="isEmailResponse">
    <div class="failed-message" *ngIf="!responseType">
      <p>{{responseMessage}}</p>
    </div>
    <div class="success-message" style="text-align: center;font-weight: 400;color: green;" *ngIf="responseType">
      <p>{{responseMessage}}</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a (click)="onCloseSendTestEmailDialog()" style="cursor: pointer;margin: 0px 17.5px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button *ngIf="!isEmailResponse" (onClick)="onSendTestEmail()"
        [disabled]=" !isEmailDialogeValid || fromEmail=='' || toEmail=='' || emailSubject ==''"
        [style]="{'margin-left': '17.5px'}" label="Send Email"></p-button>
      <p-button *ngIf="isEmailResponse" [style]="{'margin-left': '17.5px'}" (onClick)="onSendAnother()"
        label="Send Another">
      </p-button>
    </div>
  </ng-template>
  <div class="invalid" [hidden]="isEmailDialogeValid">
    <p style="color: red;">{{emailDialogeErrorMessage}}</p>
  </div>
</p-dialog>

<!--
*************************************************************************************
Create Message Dialog
*************************************************************************************
-->
<p-dialog (onHide)="onCloseNewMessageDialog()" class="custom-dialog" id="createMessageDialog" #createMessageDialog
  [(visible)]="showNewMessageWizardVisible" [resizable]="false" [modal]="true" [style]="{ 'min-width': '90%' }">
  <p-header class="addMessageHeader">
    <span class="dialog-head">{{messageTemplateDialogModel?.currentMessageType >0 ? 'Replace Message':'Add Message'}}</span>
  </p-header>
  <div class="card-hr"></div>

   <!-- <app-content-library-filter *ngIf="showNewMessageWizardVisible" [IsCalledFromWizard]="true"
    [UpdateMsgModel]="updateMsgModel"
    (onItemsSelectForWizardEvent)="onItemsSelectForWizardEvent($event)"></app-content-library-filter> -->
  <app-content-library-filter [hideAllAndCampaignTab]="true" [showBuildYourOwnButton]="true" *ngIf="showNewMessageWizardVisible" [UpdateMsgModel]="updateMsgModel"
    (onItemsSelectForWizardEvent)="onItemsSelectForWizardEvent($event)"></app-content-library-filter>

  <!-- <messageAddDialog-root [MessageTemplateValues]="messageTemplateDialogModel" 
    (onItemsSelectForWizardEvent)="onItemsSelectForWizardEvent($event)"></app-content-library-filter> --> 

  <div class="card-hr"></div>
  <ng-template pTemplate="footer">
    <div style="margin-right: 40px; display: flex; align-items: center;">
      <a [routerLink]="" styleclass="p-button-secondary" (click)="closeNewMessageDialog()"
        style="color: #2e2e2e; cursor: pointer; margin-right: 17.5px; text-decoration: underline; font-weight: 400;">Cancel</a>
      <p-button [disabled]="!selectItemsForWizard.length" (click)="onSaveNewMessage()" label="Save"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Delete Campaign Dialog
*************************************************************************************
-->
<p-dialog class="custom-alert custom-modal" id="delete_campaign" #deleteCampaignDialog
  [(visible)]="showDeleteCampaignDialog" [resizable]="false" [modal]="true"
  [style]="{'width': '52.5em','font-weight': '700'}">
  <div class="pb-25">
    <p class="confirm-sms">Are you sure you want to delete this campaign? </p>
  </div>

  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button (onClick)="deleteCampaign()" [style]="{'margin-right': '17.5px'}" label="Yes, I want to delete"
        styleClass="p-button-rounded"></p-button>
      <p-button (click)="showDeleteCampaignDialog = false;" [style]="{'cursor': 'pointer','margin-left': '17.5px'}"
        styleClass="p-button-secondary">Cancel</p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
  *************************************************************************************
  Preview Dialog
  *************************************************************************************
  -->
<p-dialog class="custom-dialog" #preview id="preview" [resizable]="false" (onHide)="onClosePreviewDialog()"
  [(visible)]="showPreviewDialog" [modal]="true" [baseZIndex]="10000" [style]="{'width': '768px','font-weight': '700'}">
  <previewDialog-root #previewDialog [compiledEmail]="'Test body'" [templateWidth]="616" [templateHeight]="936">
  </previewDialog-root>
  <p-header>
    <span class="dialog-head">{{messageTitle}}</span>
  </p-header>
</p-dialog>

<!--
*************************************************************************************
SMS Preview Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" #preview id="smspreview" [resizable]="false" (onHide)="onClosePreviewDialog()"
  [(visible)]="showTextPreviewDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '800px', 'height': '640px', 'font-weight': '500', 'padding' : '0px !important'}">
  <p-header>
    <span class="dialog-head">{{messageTitle}}</span>
  </p-header>

  <div class="flex-fill" style="padding: 0px !important">
    <div class="row">
      <div class="col-sm-5" style="background-color:#F5F5F5;">
        <div style="padding: 50px;">
          <ul class="list-group" style="border-radius: 0">
            <li class="list-group-item"> <span class="dialog-head">Message Summary </span></li>
            <li class="list-group-item"> <span class="fade-txt">Message Category</span> <br />
              <span style="font-size: 16px;">{{messageCategory}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-7" style="padding: 50px;">
        <div class="sms-preview-container" style="background-image: url(../../../assets/images/phone-frame.svg)">
          <div class="sms-preview-block" *ngIf="smsMsgBody.length">
            <div class="sms-block" *ngFor="let smsBodyForm of smsMsgBody; last as isLast">
              <span>{{smsBodyForm}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<!--
*************************************************************************************
Update Subscription Dialog
*************************************************************************************
-->
<p-dialog class="custom-alert custom-modal" id="upgradeSubscriptionDialog" class="custom-alert custom-modal"
  #upgradeSubscriptionDialog [resizable]="false" (onHide)="onCloseUpgradeSubscriptionDialog()"
  [(visible)]="showUpgradeSubscriptionDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '52.5em','font-weight': '700'}">
  <p-header>
  </p-header>
  <div class="pb-25">
    <p class="confirm-sms">Please confirm you want to activate {{this.campaign.name}} </p>
  </div>
  <div class="pb-35" *ngIf="userRoleFoundInToRecipientList">{{ userRoleNameFoundInToRecipientListMessage }}</div>
  <div class="d-flex align-items-center">
    <p-button [style]="{'margin-right': '17.5px'}" (onClick)="onUpgradeSubscriptionDialog()"
      label="Yes, I want to activate">
    </p-button>
    <div>
      <p-button (click)="showUpgradeSubscriptionDialog = false;" label="Continue Editing"
        styleClass="p-button-secondary"></p-button>
    </div>
  </div>
</p-dialog>

<p-dialog class="custom-alert custom-modal" id="deactivated" class="custom-alert custom-modal" [resizable]="false"
  [(visible)]="isDeactivated" [modal]="true" [baseZIndex]="10000" [style]="{'width': '52.5em','font-weight': '700'}">
  <div class="pb-25">
    <p class="confirm-sms">This Campaign has been deactivated.</p>
  </div>
  <div>
    <p class="dialog-head">Campaigns can only be edited while inactive and will not execute
      any queued steps until reactivated. </p>
  </div>
  <div class="pb-35"></div>
  <div class="d-flex align-items-center">
    <p-button [style]="{'margin-right': '17.5px'}" (onClick)="closeDeactivate()" label="Close">
    </p-button>
  </div>
</p-dialog>

<!--
*************************************************************************************
Confirm Dialogs
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<!-- Copy campaign dialog -->
<p-dialog class="custom-dialog" id="copyCampaignDialog" #copyCampaignDialog class="custom-dialog" [resizable]="false"
  (onHide)="onCloseCopyCampaignDialog()" [(visible)]="showCopyCampaignDialog" [modal]="true"
  [style]="{'width': '53em','font-weight': '700', 'z-index' : '1000!important'}">
  <p-header>
    <span class="dialog-head">Copy Campaign</span>
  </p-header>
  <table width="100%">
    <tr>
      <td style="text-align: left; font-family: HelveticaNeue; font-weight: 500;">
        <label class="form-label fw-bold">New campaign name</label>
        <div class="pb-2 text-secondary fst-italic">For internal use only</div>
      </td>
    </tr>
    <tr>
      <td style="width: 100%;">
        <input placeholder="[Copy] Campaign Name" #txtCopyCampaign maxlength="100" id="txtCopyCampaign" type="text"
          style="width: 100%; font-size: 14px;" [(ngModel)]="copyCampaignName" pInputText>
      </td>
    </tr>
    <tr>
      <td style="text-align: left; font-family: HelveticaNeue; font-weight: 500;">
        <label class="form-label fw-bold">Description</label>
      </td>
    </tr>
    <tr>
      <td style="width: 100%;">
        <textarea maxlength="255" [(ngModel)]="copyCampaignDesc" style="width: 100%; font-size: 14px; height: 100px;"
          pInputText></textarea>
      </td>
    </tr>
    <tr *ngIf="alertMessageDuplicateCampaignName">
      <td>
        <span class="text-danger">{{alertMessageDupCampaignNameString}}</span>
      </td>
    </tr>
  </table>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end footermodal align-items-center ">
      <a (click)="onCloseCopyCampaignDialog()"
        style="color:#2e2e2e;cursor: pointer;margin-right: 17.5px;text-decoration: underline;font-weight: 400;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (onClick)="onSaveCopyCampaignDialog()" [style]="{'margin-left': '17.5px'}"
        [disabled]="!copyCampaignName || (saveClicked)" label="Save New Campaign"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--Delete Campaign Message-->
<p-dialog id="delete_camp" class="custom-alert custom-modal" #deleteCampaignMessageDialog
  [(visible)]="showDeleteCampaignMessageDialog" [resizable]="false" [modal]="true"
  [style]="{'width': '52.5em','font-weight': '700'}">
  <div class="pb-25">
    <p class="confirm-sms" [innerHTML]="deleteCampaignMessageTitle"></p>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button (onClick)="onDeleteCampaignMessage()" [style]="{'margin-right': '17.5px'}" label="Yes, I want to delete"
        styleClass="p-button-rounded"></p-button>
      <p-button (click)="showDeleteCampaignMessageDialog = false;" label="Cancel"
        [style]="{'cursor': 'pointer','margin-left': '17.5px'}" styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--Validation Campaign dialog -->
<p-dialog class="custom-alert" #validateCampaignDialog [(visible)]="showValidateCampaignDialog" [resizable]="false"
  [modal]="true" [style]="{'font-size':'16px' ,'padding':'0px !important'}">
  <div>
    <span class="fw-bold mb-4" style="font-size: 20px;"> The following are incomplete and required to activate this
      campaign: </span>
    <ul [innerHTML]="validationString" style="padding: 2% 8%;">
    </ul>

  </div>

  <div class="">
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex">

      <p-button class="pt-2" (click)="showValidateCampaignDialog = false" [style]="{'margin-right': '17.5px'}"
        label="Close" styleClass="p-button-rounded">

      </p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- TEST SMS Template -->
<p-dialog class="custom-dialog" [resizable]="false" (onHide)="onCloseSendTestSMSDialog()"
  [(visible)]="showSendTestSMSDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '62.5em','font-weight': '700'}">
  <p-header>
    <span class="dialog-head">Send Test Text Message</span>
  </p-header>
  <div *ngIf="!smsSentSuccess">
    <table width="100%">
      <tr>
        <td valign="middle" class="div-label" width="10px">
          <span class="email-label">To Mobile Number</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input style="width: 60%;" pInputText id="txtNumber" type="tel" [formControl]="toNumber" maxlength="20">
        </td>
      </tr>
    </table>
  </div>
  <div class="isEmailResponse">
    <div class="success-message" style="text-align: center;font-weight: 400;color: green;" *ngIf="smsSentSuccess">
      <p>SMS was sent successfully!</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a (click)="onCloseSendTestSMSDialog()"
        style="color: #2e2e2e;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;font-weight: 400;"
        styleClass="p-button-secondary">Cancel</a>

      <p-button *ngIf="!smsSentSuccess" (onClick)="sendTestSMS()" [style]="{'margin-left': '17.5px'}"
        [disabled]="smsMsgBody?.length == 0 || !toNumber?.value || SMSInProgress" label="Send SMS"></p-button>
      <p-button *ngIf="smsSentSuccess" (click)="sendTestSMSAnother()" label="Send Another"
        [style]="{'cursor': 'pointer','margin-left': '17.5px'}" styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="SMSInProgress" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem;"></i>
</p-dialog>

<!--
*************************************************************************************
Test Mode Alert Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" [(visible)]="showTestModeDialog" [resizable]="false" [modal]="true"
  [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">Your campaign has been activated, but you're currently in TEST mode.</span>
  </div>
  <div class="pb-35">
    <span>At this time, messages will be sent to loan officer only. To begin sending to your contacts, change the
      Marketing Mode switch in your company profile from Test to Live.
    </span>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="GoToCompanyProfile()"
        label="Go To Company Profile" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showTestModeDialog = false" label="Ok"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>