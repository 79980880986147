<div *ngIf="user?.original_userfullname"
     style="width: 100%;background: yellow;display: block;position: absolute;top: 0px;text-align: center; z-index: 999999 !important;">
  <table>
    <tr>
      <td style="padding-left:255px">
        Logged in as {{user.original_userfullname}}. Impersonating: {{user.getFullName()}} [{{user.TenantName}}].
        Role: {{role}}
      </td>

      <td style="padding-left:25px">
        <div role="button" (click)="changeImpersonatedUser()"><u> Switch Tenant</u></div>
      </td>
    </tr>
  </table>
</div>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
               style="width:95%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="setup-form position-relative container-middle">
    <div class="container-size">
      <div class="welcome-form h-100 position-relative">
        <div class="m-auto d-flex justify-content-center flex-column text-center mt-4">
          <h5 class="title-onboarding">Finally let's book a time with our product specialist to finish your setup.</h5>
          <h5 class="title-onboarding">Select a date and time.</h5>
          <hr class="hr-onboarding" />
        </div>
      </div>
    </div>
    <div class="m-auto">
      <div class="calendly-inline-widget" style="min-width:320px;height:700px;" data-auto-load="false"></div>
    </div>
  </div>

  <div class="footer-section p-2">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button p-button-rounded float-end font-16-bold" (click)="redirectToCTAScreenDirectly()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>

        <app-to-dashboard-button class="float-end"></app-to-dashboard-button>
      </div>
    </div>
  </div>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
          [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>
