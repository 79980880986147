import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { ContentLibraryFilterModel, ContentLibraryModel, ContentLibraryOperation, ContentLibraryType } from '../../../../models/ContentLibraryModel';
import { ContentLibraryService } from '../../../../../src/services/contentLibrary.service';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { ToastService } from 'src/services/toast.service';
import { PagingData } from 'src/models/PagingData';
import { RequestResponse } from '../../../../../src/models/RequestResponse';
import { UtcConverterService, UtcToLocalTimeFormat } from '../../../../services/utc-converter.service';
import { environment } from 'src/environments/environment';
import { LookupService } from 'src/services/lookup.service';
import { FormControl } from '@angular/forms';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Menu } from 'primeng/menu';
import { CampaignPreviewDetails } from 'src/models/CampaignPreviewDetails';
import { MessageTemplate } from 'src/models/MessageTemplate';
import { CampaignsService } from 'src/services/campaigns.service';
import { CommonHelperService } from 'src/services/commonHelper.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { SMSService } from 'src/services/sms.service';
import { ConfirmModalComponent } from '../../designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { MakeACopyComponent } from '../../designlibrary/dialogs/make-a-copy/make-a-copy.component';
import { MessagesService } from 'src/services/messages.service';
import { stripoComponent } from '../../designlibrary/stripo.component';
import { UserTokenService } from 'src/services/user-token.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { CampaignInfo, TemplateHtmlData, UpdateMsgModel } from 'src/models/Campaign';
import { JsonPipe } from '@angular/common';
import { LoansService } from 'src/services/Loans.service';
import { FilterTypes } from 'src/components/custom-grid/enums/filter-types';
import { FiltersComponent } from 'src/components/custom-grid/filters/filters.component';
import { CelebrationService } from 'src/components/celebration/celebration.service';

@Component({
  selector: 'app-content-library-filter',
  templateUrl: './content-library-filter.component.html',
  styleUrls: ['./content-library-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentLibraryFilterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('ContentLibraryDataView', { static: false }) dataTable: Table;
  @ViewChild('ellipseMenu', { static: false }) ellipseMenu: Menu;
  @ViewChild('makeACopyDialog', { static: false }) makeACopyDialog: MakeACopyComponent;
  @ViewChild('previewDialog', { static: false }) previewDialog: Component;
  @ViewChild('templateEditor', { static: false }) templateEditor: stripoComponent;
  @ViewChild(FiltersComponent) filtersComponent!: FiltersComponent;
  @Input() hideAllAndCampaignTab: boolean = false;
  @Input() showBuildYourOwnButton: boolean = false;
  public loadingPage: boolean = false;
  public searchStr: string;
  private subscription: Array<Subscription>;
  public webAPI: RequestResponse;
  public searchKeyword: string = '';
  public noRecordFound: boolean = false;
  public pageIndex: number = 0;
  public currentClientID: string;
  public messageCategory: string;
  public messageCategoryList: any;
  public contentFilter: ContentLibraryFilterModel;
  public contextMenuItems: MenuItem[];
  public campaignPreviewDetail: CampaignPreviewDetails;
  public campaignPreviewFlag: boolean;
  public showCampaignCopyDialog: boolean;
  public showTemplateCopyDialog: boolean;
  public copyMessageTemplate: boolean;

  //copy campaign
  public copyCampaignName: string;
  public copyCampaignDesc: string;
  public alertMessageDupCampaignNameString: string;
  public saveClicked: boolean;
  public copyContent: ContentLibraryModel;
  public selectedTemplateClone: MessageTemplate;

  //Sms 
  public showSendTestSMSDialog: boolean;
  public smsMsgBody: any;
  public smsSentSuccess: boolean;
  public smsInProgress: boolean;
  public toNumber = new FormControl('');
  public noImage: string;
  public msgImage: string;
  public previewMessageTitle: string;
  public templatePreviewFlag: boolean;
  public textPreviewFlag: boolean;
  public showTextPreviewDialog: boolean;
  public operationInProgress: boolean;
  public showEmailEditor: boolean;
  public validationString: string = '';
  public showValidateCampaignDialog: boolean = false;
  public sortByList: any[];
  public selectedSortby: string = "";
  public isCompanyUserWithMarketing: boolean;//isStandardUserWithMarketing
  public loadGrid: boolean;
  public isCalledFromWizard: boolean;//called from create wizard
  public selectItemsForWizard: TemplateHtmlData[];//called from create wizard
  public updateMsgModel: UpdateMsgModel;

  private searchParam$ = new Subject<number>();
  private searchObservable: any;
  private storageHelper: StorageHelper;
  public showTestModeDialog: boolean;
  public parameter: string = '';
  public userRoleFoundInToRecipientList: boolean;
  public userRoleNameFoundInToRecipientListMessage: string;
  @Input() get UpdateMsgModel(): UpdateMsgModel {
    return this.updateMsgModel;
  }
  set UpdateMsgModel(item: UpdateMsgModel) {
    this.updateMsgModel = item;
    this.isCalledFromWizard = item.isCalledFromWizard > 0 ? true : false;
    this.clearLibraryFilterCache();
    this.contentFilter = JSON.parse(JSON.stringify(this.contentFilter));
    this.contentFilter.isCalledFromWizard = item.isCalledFromWizard;
    this.contentFilter.messageTypeToSend = item.messageTypeToSend;
    this.contentFilter.campaignID = item.campaignId;
    this.contentFilter.campaignMessageId = item.campaignMessageId;
  }

  @Output() onItemsSelectForWizardEvent = new EventEmitter<TemplateHtmlData[]>();

  public activeTab: number = 0;

  public filters: Array<any> = [
    {
      field: 'owner',
      header: 'Owner',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.KEYPAIR,
      display: true,
      filterValues: [],
      filterOptions: [
      ]
    },
    {
      field: 'category',
      header: 'Category',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.KEYPAIR,
      display: true,
      filterValues: [],
      filterOptions: [
        {
          value: {
            value: "df5a649e-04f2-4f20-a76f-44ecc879eed0",
            field: "Birthdays",
          },
          field: "Birthdays",
        },
        {
          value: {
            value: "73d86b36-7d5c-4261-9104-236b9e7c807e",
            field: "Holidays"
          },
          field: "Holidays"
        },
        {
          value: {
            value: "b87ce8cd-22b4-43da-a8f0-a6f8f62ba321",
            field: "Lead Nurture"
          },
          field: "Lead Nurture"
        },
        {
          value: {
            value: "e7b76799-5cbf-46c4-883b-50155f3682f5",
            field: "Loan Status"
          },
          field: "Loan Status"
        },
        {
          value: {
            value: "f391408f-7d0d-4442-8ce8-8eda4c26e414",
            field: "Newsletter"
          },
          field: "Newsletter"
        },
        {
          value: {
            value: "6b09fe96-aba2-4e68-9dd6-309d4c692adc",
            field: "Post-Closing"
          },
          field: "Post-Closing"
        },
        {
          value: {
            value: "a5e0af0b-e75a-46b1-8f72-7c089bfba064",
            field: "Reminders"
          },
          field: "Reminders"
        }
      ]
    },
    {
      field: 'aduvoContent',
      header: 'Aduvo Content',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.SELECTABLE,
      display: true,
      filterValues: [],
      filterOptions: [],
      selected: false
    },
    {
      field: 'myContent',
      header: 'My Content',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.SELECTABLE,
      display: true,
      filterValues: [],
      filterOptions: [],
      selected: false
    },
    {
      field: 'aduvoQuickStart',
      header: 'Aduvo Quick Start',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.SELECTABLE,
      display: true,
      filterValues: [],
      filterOptions: [],
      selected: false
    },
    {
      field: 'aduvoRecommended',
      header: 'Aduvo Recommended',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.SELECTABLE,
      display: true,
      filterValues: [],
      filterOptions: [],
      selected: false
    },
    {
      field: 'messageType',
      header: 'Message Type',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      display: true,
      filterValues: [],
      filterOptions: [
        {
          value: "Email",
          field: "Email"
        },
        {
          value: "Text Message",
          field: "Text Message"
        }
      ]
    },
    {
      field: 'campaignStatus',
      header: 'Campaign Status',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      display: true,
      filterValues: [],
      filterOptions: [
        {
          value: "Active",
          field: "Active"
        },
        {
          value: "InActive",
          field: "InActive"
        }
      ]
    },
    {
      field: 'recipients',
      header: 'Recipients',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.KEYPAIR,
      display: true,
      filterValues: [],
      filterOptions: [
      ]
    },
    {
      field: 'sender',
      header: 'Sender',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.KEYPAIR,
      display: true,
      filterValues: [],
      filterOptions: [
        {
          value: {
            value: 'ContactOwner',
            field: 'Contact Owner',
          },
          field: 'Contact Owner',
        },
        {
          value: {
            value: 'CustomAddress',
            field: 'Custom Address',
          },
          field: 'Custom Address',
        }
      ]
    }
  ];
  
  constructor(private activatedRoute: ActivatedRoute,
    private contentLibraryService: ContentLibraryService,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private campaignsService: CampaignsService,
    private messageTemplatesService: MessageTemplatesService,
    private smsService: SMSService,
    private messagesService: MessagesService,
    private router: Router,
    private userTokenService: UserTokenService,
    private confirmationService: ConfirmationService,
    public dateConverter: UtcConverterService,
    private loanService: LoansService,
    private celebrationService: CelebrationService
  ) {
    this.subscription = new Array<Subscription>();
    this.contentFilter = new ContentLibraryFilterModel();
    this.sortByList = [
      { label: "Last Updated", value: "LastUpdated" },
      { label: "Template / Campaign Name", value: "Name" },
    ];
    this.selectedSortby = '';
    this.isCompanyUserWithMarketing = false;
    this.noImage = "assets/images/NoMessages.png";
    this.msgImage = "assets/images/MessagesImage.png";
    this.storageHelper = new StorageHelper();
    this.loadGrid = false;
    this.isCalledFromWizard = false;
    this.selectItemsForWizard = [];
    this.showTestModeDialog = false;
    this.userRoleFoundInToRecipientList = false;
    this.userRoleNameFoundInToRecipientListMessage = "";
  }

  ngOnInit(): void {


    this.activatedRoute.queryParams.subscribe(qry => {
      let category = qry?.id?.trim().toLowerCase();
      if (category) {
        this.contentFilter.categoryCSV = category;
      }
    });

    if (this.activatedRoute.snapshot.data.id) {
      let param = this.activatedRoute.snapshot.data.id;
      this.parameter = param;
      if (param == "mycontent") {
        this.contentFilter.myContent = true;
        this.filters.find(s => s.field === 'owner').display = false;
      } else if (param == "activecontent") {
        this.contentFilter.activeContent = true;
        this.contentFilter.campaign = true;
        this.contentFilter.active = true;
      } else if (param == "aduvoquickstart") {
        this.contentFilter.aduvoQuickStart = true;
        this.contentFilter.aduvoContent = true;
      } else if (param == "aduvorecommended") {
        this.contentFilter.aduvoRecommended = true;
        this.contentFilter.aduvoContent = true;
      } else if (param == 'explore') {
        this.contentFilter.singleMessage = true;
        this.contentFilter.email = true;
      } else if (param == 'all') {
        this.contentFilter.categoryCSV = 'All';
      }
    }

    let prevUrl = this.router?.url;
    if (prevUrl != '/marketing/campaigns/create/new') {
      this.storageHelper.ClearStorageByKey('contentLibrarybackURL');
      if (prevUrl) {
        this.storageHelper.SetDataInStorage('contentLibrarybackURL', prevUrl);
      }
    }
    this.webAPI = new RequestResponse();

    if (this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterCurrentPageNumber")) {
      this.webAPI.paging.currentPage = parseInt(this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterCurrentPageNumber").value);
    } else {
      this.webAPI.paging.currentPage = 1;
    }
    this.pageIndex = this.webAPI.paging.currentPage - 1;

    if (this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterPageSize")) {
      this.webAPI.paging.pageSize = parseInt(this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterPageSize").value);
    } else {
      this.webAPI.paging.pageSize = 12;
    }
    if (this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterSearchKeyword")) {
      this.searchKeyword = this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterSearchKeyword").value;
    }
    if (this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterPageIndex")) {
      this.pageIndex = parseInt(this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterPageIndex").value);
    } else {
      this.pageIndex = 0;
    }

    this.currentClientID = environment.clientId;
    this.setSearchObservable();

    this.lookupService.GetCampaignCategories().subscribe((result) => {
      if (result && result.data) {
        this.messageCategoryList = result.data.map((obj) => {
          return {
            item_id: obj.categoryId,
            item_text: obj.categoryName,
          };
        });
      }
    });

    
    this.contentLibraryService.getContentLibraryOwnerFilter(this.webAPI).subscribe((result) => {
      if (result && result.data) {
        console.log(result.data);
        this.filters.find(s => s.field === 'owner').filterOptions = result.data.map(s => {
          return {
            value: {
              value: s.ownerTenantUserId,
              field: s.ownedBy,
            },
            field: s.ownedBy,
          };
        });
      }
    });

    this.contentLibraryService.getContentLibraryRecipientFilter(this.webAPI).subscribe((result) => {
      if (result && result.data) {
        console.log(result.data);
        this.filters.find(s => s.field === 'recipients').filterOptions = result.data.map(s => {
          return {
            value: {
              value: s.masterContactTypeId,
              field: s.name,
            },
            field: s.name,
          };
        });
      }
    });

    if(this.hideAllAndCampaignTab){
      this.contentFilter.singleMessage = true;
      this.contentFilter.campaign = false;
      this.activeTab = 0;
      this.filters.forEach(filter => {
        if (['sender', 'recipients', 'campaignStatus'].includes(filter.field)) {
          filter.display = false;
        }
      });
    }
  }

  /** Occurs after view initialization */
  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);

    if (this.parameter == "aduvoquickstart") {
      this.filtersComponent.columns.find(s => s.field === 'aduvoQuickStart').selected = true
      this.filtersComponent.selectedFilterColumns.push(this.filtersComponent.columns.find(s => s.field === 'aduvoQuickStart'));
    } else if (this.parameter == "aduvorecommended") {
      this.filtersComponent.columns.find(s => s.field === 'aduvoRecommended').selected = true
      this.filtersComponent.selectedFilterColumns.push(this.filtersComponent.columns.find(s => s.field === 'aduvoRecommended'));
    } else if (this.parameter == "mycontent") {
      this.filtersComponent.columns.find(s => s.field === 'myContent').selected = true;
      this.filtersComponent.selectedFilterColumns.push(this.filtersComponent.columns.find(s => s.field === 'myContent'));
    } else if (this.parameter == "activecontent") {
      this.filtersComponent.columns.find(s => s.field === 'campaignStatus')
        .filterValues.push(this.filtersComponent.columns.find(s => s.field === 'campaignStatus').filterOptions.find(s => s.field === 'Active').value);
      this.filtersComponent.selectedFilterColumns.push(this.filtersComponent.columns.find(s => s.field === 'campaignStatus'));
    }

  }

  /** Sets control states based upon permissions/current data values. */
  setControlStates() {
    let session = this.userTokenService.getUser();
    this.isCompanyUserWithMarketing = session.isCompanyUserWithMarketing();
  }

  onSortBySelectionChange(event) {
    if (event.value == 'Name') {
      this.selectedSortby = event.value + ' ASC';
    } else {
      this.selectedSortby = event.value + ' DESC';
    }

    this.callSearchObservable();
  }

  onPageChanged(event) {

    let pagNum = this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterCurrentPageNumber")?.value;
    let pageSize = this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterPageSize")?.value;
    let pageIndex = this.storageHelper.GetDataFromStorage("AduvoContentLibraryFilterPageIndex")?.value;

    if (pagNum) {
      this.webAPI.paging.currentPage = parseInt(pagNum);
    } else {
      this.webAPI.paging.currentPage = event.first / event.rows + 1;
    }
    if (pageSize) {
      this.webAPI.paging.pageSize = parseInt(pageSize);
    } else {
      this.webAPI.paging.pageSize = event.rows;
    }

    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    } else {
      //this.pageIndex = 0;
      this.pageIndex = (this.webAPI.paging.currentPage - 1) * this.webAPI.paging.pageSize + 1
    }

    this.clearLibraryFilterCache();

    this.callSearchObservable();
  }

  onGotoPage(event) {
    if (this.webAPI.paging.currentPage) {
      let tot = this.webAPI.paging.totalRecords;
      var pageSize = this.webAPI.paging.pageSize;
      var totalPage = Math.trunc(tot / pageSize);
      let quo = tot % 2;
      totalPage = totalPage + quo;
      if (this.webAPI.paging.currentPage > totalPage) {
        this.webAPI.paging.currentPage = totalPage;
      }
    }

    this.pageIndex = (this.webAPI.paging.currentPage - 1) * this.webAPI.paging.pageSize + 1
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onGotoDVPage(this.webAPI.paging, this.dataTable)) {
      this.callSearchObservable();
    }
  }

  onFilterChangeEvent(event: ContentLibraryFilterModel) {
    if (event) {
      this.loadGrid = true;
      this.webAPI.paging.currentPage = 1;//so it will be sent as -1 =0 later
      this.pageIndex = 0;
      this.dataTable.first = 0;
      this.contentFilter = event;
      this.callSearchObservable();
    }
  }

  onGlobalFilterChange() {
    if (this.searchKeyword.length == 0) {
      this.noRecordFound = false;
      this.onApplyGlobalFilter();
    }
  }

  async onApplyGlobalFilter() {
    setTimeout(() => {

      this.webAPI.paging.currentPage = 1;//so it will be sent as -1 =0 later
      this.pageIndex = 0;
      this.dataTable.first = 0;

      this.callSearchObservable();
    });
  }

  callSearchObservable() {
    let random = Math.floor(Math.random() * 1000);
    this.searchParam$.next(random);
  }

  setSearchObservable() {
    try {
      //----      
      this.searchObservable = this.searchParam$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((x) => {
            if (this.currentClientID === 'AduvoAdmin' && this.isCalledFromWizard) {//in case of publishDb only show active template.
              this.contentFilter.active = true;
            }
            this.loadingPage = true;
            this.contentFilter.searchStr = this.searchKeyword;
            this.contentFilter.orderBy = this.selectedSortby;
            this.webAPI.searchInfo = JSON.stringify(this.contentFilter);
            this.cdr.detectChanges();
            return this.contentLibraryService.getContentDataByFilter(this.webAPI);
          }),
          catchError((err) => of(null))
        )
        .subscribe((response: RequestResponse) => {
          if (response) {
            if (response.status == 200 && response?.message == '') {
              this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;

              this.noRecordFound = response?.pageInfo?.totalRecords < 1;

              // assign returned object to class variable.
              let dataList = response.data as ContentLibraryModel[];; // Required - do not remove.
              dataList.forEach((item:any) => {
                item.ownerIntials = item.ownerImageSrc.replaceAll(' ','');
              });
              //if (this.isCalledFromWizard) { 
              if (this.contentFilter.isCalledFromWizard == 2) {

                const dataListWithNewProperty = dataList.map((item) => {
                  return { ...item, dummySelectTemplate: this.getSelectTemplateFlag(item.id) };
                });

                this.webAPI.data = dataListWithNewProperty;

              } else {
                this.webAPI.data = dataList;
              }
              //this.webAPI.data = response.data; // Required - do not remove.
              this.loadingPage = false;
            } else {
              this.toastService.showError(response?.message);
            }
          }
          this.loadingPage = false;
          this.cdr.detectChanges();
        },
          (err) => {
            this.toastService.showError('Error : ' + err);
            this.loadingPage = false;
          }
        );

      //----
      // this.subscription.push(this.contentLibraryService.getContentDataByFilter(this.webAPI).subscribe((response) => {
      //   if (response.status == 200 && response?.message == '') {
      //     this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;

      //     if (response.pageInfo.totalRecords < 1) {
      //       this.noRecordFound = true;
      //     }
      //     // assign returned object to class variable.
      //     this.webAPI.data = response.data; // Required - do not remove.
      //     this.loadingPage = false;
      //     this.cdr.detectChanges();
      //   } else {
      //     this.toastService.showError(response.message);
      //   }
      // },
      //   (err) => {
      //     this.toastService.showError('Error : ' + err);
      //     this.loadingPage = false;
      //   }
      // )
      // );
    }
    catch (strError: any) {
      this.webAPI.paging.totalRecords = 0;
      this.toastService.showError(strError);
      this.loadingPage = false;
      this.cdr.detectChanges();
    }
  }

  getSelectTemplateFlag(id: string): any {
    if (this.selectItemsForWizard.length) {
      let hasValue = this.selectItemsForWizard.find(x => x.id == id);
      return hasValue ? true : false;
    }
    return false;
  }

  //Activates/Deactivates campaign/template
  public onActiveInactiveContent(content: ContentLibraryModel) {
    let id: string = content.id,
      isCampaign: boolean = content.isCampaign,
      isPublished: boolean = content.published,
      campaignName: string = content.title,
      isPublishDb: boolean = content.isPublishDB

    let activeStr = isPublished ? 'activate' : 'inactive';
    let showMsg = '';
    let showSuccessButonText = '';

    if (isCampaign) {
      if (isPublished) {
        showMsg = `Please confirm you want to ${activeStr} ${campaignName}`;
        showSuccessButonText = `Yes, I want to ${activeStr}`;
        this.campaignsService.GetCampaignbyId(id).subscribe((result) => {
          if (result && result.data) {
            var campaignBlockDetail = result.data;

            let userRoleNameFoundInToRecipientList = '';
            this.userRoleFoundInToRecipientList = false;
            this.loanService.GetLoanAssignTypeRoles(isPublishDb).subscribe(
              (res) => {
                if (res.data) {
                  if (campaignBlockDetail.to_LoanAssignTypeIdCSV) {
                    let toRecipientList = campaignBlockDetail.to_LoanAssignTypeIdCSV.split(',');

                    res.data.forEach((item1) => {
                      const index = toRecipientList.findIndex(obj => obj === item1.loanAssignTypeId)
                      if (index > -1) {
                        this.userRoleFoundInToRecipientList = true;
                        userRoleNameFoundInToRecipientList += item1.item_text + ", ";
                      }
                    })
                    var index = userRoleNameFoundInToRecipientList.lastIndexOf(", ");
                    if (index > 0) {
                      userRoleNameFoundInToRecipientList = userRoleNameFoundInToRecipientList.substring(0, index);
                    }
                  }
                }
              },
              (err) => {
              }
            );

            if (this.userRoleFoundInToRecipientList) {
              this.userRoleNameFoundInToRecipientListMessage = "Please be aware that users such as Loan Officers placed in the 'To Recipients' field will only be processed for Status Quick Pick messages.";
            } else {
              this.userRoleNameFoundInToRecipientListMessage = '';
            }

            var ref = this.dialogService.open(ConfirmModalComponent, {
              width: '650px',
              contentStyle: { 'max-height': '500px', overflow: 'auto' },
              styleClass: 'confirm-modal',
              baseZIndex: 10000,
              data: {
                message: showMsg,
                secondLineMessage: this.userRoleNameFoundInToRecipientListMessage,
                successButtonText: showSuccessButonText,
                cancelButtonText: 'Cancel',
              },
            });

            ref.onClose.subscribe(async (result: boolean) => {
              if (result) {
                if (isCampaign) {//template activation/ only in case of admin  
                  if (activeStr == 'activate') {
                    this.GetCampaignMsg(content, campaignBlockDetail);
                  }
                }
                else if (this.currentClientID === 'AduvoAdmin') {//template publish
                  this.messageTemplatesService.setPublished(content.id, content.published).subscribe((response) => {
                    if (response.status == 200) {
                      this.toastService.showSuccess(content.published ? 'Template Activated' : 'Template Deactivated');
                    } else {
                      this.toastService.showError((content.published ? 'Error Activating! ' : 'Error Deactivating! ') + response.message);
                      content.published = !content.published;
                      return false;
                    }
                  });
                }
              }
              else {
                content.published = !content.published;
              }
              this.cdr.detectChanges();
            });
          }
        });
      } else {
        showMsg = `Are you sure you want to set '${campaignName}' to ${activeStr}?`
        showSuccessButonText = 'Yes';

        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message: showMsg,
            successButtonText: showSuccessButonText,
            cancelButtonText: 'Cancel',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            if (isCampaign) {//template activation/ only in case of admin  
              if (activeStr == 'activate') {
                //do nothing
              } else {
                //deactivate campaign directly
                this.SwitchPublished(content);
              }
            }
            else if (this.currentClientID === 'AduvoAdmin') {//template publish
              this.messageTemplatesService.setPublished(content.id, content.published).subscribe((response) => {
                if (response.status == 200) {
                  this.toastService.showSuccess(content.published ? 'Template Activated' : 'Template Deactivated');
                } else {
                  this.toastService.showError((content.published ? 'Error Activating! ' : 'Error Deactivating! ') + response.message);
                  content.published = !content.published;
                  return false;
                }
              });
            }
          }
          else {
            content.published = !content.published;
          }
          this.cdr.detectChanges();
        });
      }
    }
    else {
      const ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: `Please confirm you want to ${activeStr} ${content.title}`,
          successButtonText: `Yes, I want to ${activeStr}`,
          cancelButtonText: 'Cancel',
        },
      });
      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          this.messageTemplatesService.setPublished(content.id, content.published)
            .subscribe((response) => {
              if (response.status == 200) {
                this.toastService.showSuccess(content.published ? 'Template Activated' : 'Template Deactivated');
              }
              else {
                this.toastService.showError((content.published ? 'Error Activating! ' : 'Error Deactivating! ') + response.message);
                content.published = !content.published;
              }
            });
        } else {
          content.published = !content.published;
        }
        this.cdr.detectChanges();
      });
    }
  }

  async GetCampaignMsg(content: ContentLibraryModel, campaignBlockDetail: any) {
    var startText = 'The following are incomplete and required to activate this campaign:  \n';
    var text = '';
    var msgScheduled = false;
    var messages: any;

    await this.messagesService.GetCampaignMessagesObs(content.id).subscribe((result) => {
      if (result && result.data) {
        messages = result.data;

        if (messages.length == 0) {
          msgScheduled = false;
        }

        messages.forEach((item, index) => {
          if ((item.scheduleType != '' || item.scheduleSubtype != '') && msgScheduled == false) {
            msgScheduled = true;
          }
        });

        if (!msgScheduled) {
          text += ' <li> At least one scheduled message </li> ';
        }
      }

      if (!campaignBlockDetail.campaignCategoryId) {
        text += ' <li> Campaign Category </li> ';
      }

      if (!campaignBlockDetail.fromLoanAssignTypeId && !campaignBlockDetail.fromContactOwner &&
        !campaignBlockDetail.fromEmailAddress && !campaignBlockDetail.fromEmailAddressName) {
        text += '  <li>  From </li> ';
      }

      if (!campaignBlockDetail.toListCSV) {
        text += ' <li> To list </li> ';
      }

      if (!campaignBlockDetail.to_ContactTypeIdCSV && !campaignBlockDetail.to_LoanAssignTypeIdCSV && !campaignBlockDetail.to_EmailCSV) {
        text += ' <li> To recipients </li> ';
      }

      let singleList = '';
      //if (campaignBlockDetail.fromEmailAddress) {
      //  singleList = campaignBlockDetail.fromEmailAddress + ',';
      //}
      if (campaignBlockDetail.cc_ContactTypeIdCSV) {
        singleList += campaignBlockDetail.cc_ContactTypeIdCSV + ',';
      }
      if (campaignBlockDetail.cc_EmailCSV) {
        singleList += campaignBlockDetail.cc_EmailCSV + ',';
      }
      if (campaignBlockDetail.cc_LoanAssignTypeIdCSV) {
        singleList += campaignBlockDetail.cc_LoanAssignTypeIdCSV + ',';
      }
      if (campaignBlockDetail.to_ContactTypeIdCSV) {
        singleList += campaignBlockDetail.to_ContactTypeIdCSV + ',';
      }
      if (campaignBlockDetail.to_EmailCSV) {
        singleList += campaignBlockDetail.to_EmailCSV + ',';
      }
      if (campaignBlockDetail.to_LoanAssignTypeIdCSV) {
        singleList += campaignBlockDetail.to_LoanAssignTypeIdCSV + ',';
      }
      if (campaignBlockDetail.bcc_ContactTypeIdCSV) {
        singleList += campaignBlockDetail.bcc_ContactTypeIdCSV + ',';
      }
      if (campaignBlockDetail.bcc_EmailCSV) {
        singleList += campaignBlockDetail.bcc_EmailCSV + ',';
      }
      if (campaignBlockDetail.bcc_LoanAssignTypeIdCSV) {
        singleList += campaignBlockDetail.bcc_LoanAssignTypeIdCSV + ',';
      }
      let commonInList = singleList.split(',');
      let commonFoundInList = this.toFindDuplicatesExists(commonInList);

      if (commonFoundInList) {
        text += " <li> Each role may only appear once within the To, CC, and BCC fields</li>  ";
      }

      if (text) {
        content.published = false;
        this.validationString = startText + text;

        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            innerHtmlMessage: this.validationString,
            successButtonText: 'Continue Editing',
            cancelButtonText: 'Close',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.router.navigate(['/marketing/campaigns/edit/' + content.id + '/default']);
          } else {//close click

            this.webAPI.data.forEach(element => {
              if (element?.id == content.id) {
                element.published = false;
              }
            });
            this.cdr.detectChanges();
          }
        });
      } else {
        this.showValidateCampaignDialog = false;
        if (msgScheduled) {
          this.SwitchPublished(content);
        }
      }
    });
  }

  toFindDuplicatesExists(arry) {
    let toMap = {};
    let resultToReturn = false;
    for (let i = 0; i < arry.length; i++) {
      if (toMap[arry[i]]) {
        resultToReturn = true;
        // terminate the loop
        break;
      }
      toMap[arry[i]] = true;
    }
    return resultToReturn;
  }

  async SwitchPublished(content: ContentLibraryModel) {
    try {

      var numberOfCampaignsActivatedBefore = await this.campaignsService.HasActivated();

      var resp = await this.campaignsService.TogglePublishedCampaign(content.id, content.isPublishDB);
      var response = await this.campaignsService.Get();

      var runMode;
      response.data.forEach(element => {
        runMode = element.runMode
      });

      if (!resp?.data && resp?.message) {
        this.toastService.showError(resp.message);
        content.published = !content.published;
      }
      else {

        if(content.published){

          if(numberOfCampaignsActivatedBefore === 0){

            this.celebrationService.triggerCelebration();

          } else {

            this.toastService.showSuccess(`Campaign Activated`);

          }

        } else {

          this.toastService.showSuccess(`Campaign Deactivated`);

        }
        
        

        if (runMode === "TEST" && content.published && this.currentClientID != 'AduvoAdmin') {
          this.showTestModeDialog = true;
        } else {
          this.showTestModeDialog = false;
        }
        this.cdr.detectChanges();
        this.contentLibraryService.setContentLibraryOperation(new ContentLibraryOperation(content.requestType, false, content.id));

      }

    } catch (message: any) {
      this.operationInProgress = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onEllipseClick(event: any, content: ContentLibraryModel) {
    this.contextMenuItems = this.getContextMenuItems(content);

    if (!content.isCampaign) {
      this.contextMenuItems.filter(x => x.label == "Edit").forEach(element => {
        element.disabled = content.isPublishDB;//edit
        element.visible = !content.isPublishDB;
      });
    }

    this.contextMenuItems.filter(x => x.label == "Delete").forEach(element => {
      element.disabled = content.isPublishDB;//edit
      element.visible = !content.isPublishDB;
    });
    this.ellipseMenu.toggle(event);
  }

  getContextMenuItems(content: ContentLibraryModel): MenuItem[] {
    let result: MenuItem[];
    let isPreviewDisabled = content.msgCount > 0 ? false : true;
    if (content.isCampaign) {
      result = [
        {
          label: 'Preview',
          // icon: 'pi pi-eye',
          command: () => this.onPreview(content),
          disabled: isPreviewDisabled,
          visible: true,
        },
        {
          label: 'Edit',
          // icon: 'fa fa-pencil pencil',
          command: () => this.onEdit(content),
          //routerLink: `/marketing/campaigns/edit/${content.id}/default`,
          //visible: !content.isPublishDB,
        },
        {
          label: 'Send Test',
          // icon: 'pi pi-send',
          command: () => this.onTestSend(content),
          visible: !content.isCampaign && !content.isPublishDB
          // command: () => this.onSendSMS(template),
          // command: () => this.onSendEmail(template),
        },
        {
          label: 'Duplicate',
          // icon: 'pi pi-copy',
          command: () => this.onCopyDialog(content),
        },
        {
          label: 'Delete',
          // icon: 'pi pi-trash',
          disabled: true,
          visible: !content.isPublishDB,
          command: () => this.onDeleteContent(content),
        },
      ];
    } else {//is template
      result = [
        {
          label: 'Preview',
          // icon: 'pi pi-eye',
          command: () => this.onPreview(content),
          // command: () => this.onPreviewCampaign(campaign),
          visible: true,
        },
        {
          label: 'Edit',
          // icon: 'fa fa-pencil pencil',
          command: () => this.onEdit(content),
          //routerLink: `/marketing/template-library/edit/${content.id}`,
          visible: !content.isPublishDB,//if owned by Aduvo do not show
        },
        {
          label: 'Send Test',
          // icon: 'pi pi-send',
          command: () => this.onTestSend(content),
          visible: !content.isPublishDB && !content.isCampaign,//if owned by Aduvo do not show
        },
        {
          label: 'Duplicate',
          // icon: 'pi pi-copy',
          command: () => this.onCopyDialog(content),
        },
        {
          label: 'Delete',
          // icon: 'pi pi-trash',
          disabled: true,
          visible: !content.isPublishDB,
          command: () => this.onDeleteContent(content),
        },
      ];
    }
    return result;
  }

  public async onEdit(content: ContentLibraryModel) {
    let pageIndex = ((this.webAPI.paging.currentPage - 1) * this.webAPI.paging.pageSize) + 1;
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterTotalRecords", this.webAPI.paging.totalRecords.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterCurrentPageNumber", this.webAPI.paging.currentPage.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterPageSize", this.webAPI.paging.pageSize.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterPageIndex", pageIndex.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterSearchKeyword", this.searchKeyword.toString());

    if (content.isCampaign) {//Campaign
      await this.router.navigate(['/marketing/campaigns/edit/' + content.id + '/default']);
    }
    else {
      await this.router.navigate(['/marketing/template-library/edit/' + content.id]);
    }
  }

  public async onTestSend(content: ContentLibraryModel) {
    this.copyContent = content;
    if (content.isCampaign) {//Campaign

    }
    else {
      try {
        //onSendSMS
        this.operationInProgress = true;
        this.cdr.detectChanges();
        let template = await this.messageTemplatesService.GetTemplateById(
          content.id,
          content.isPublishDB
        );

        let isEmail = template.data.emailSubject ? true : false;
        if (isEmail) {
          this.showEmailEditor = true;
          this.cdr.detectChanges();

          this.templateEditor.editorState.messageTemplate.templateId = template.data.templateId;

          if (this.currentClientID === 'AduvoAdmin')
            this.templateEditor.editorState.messageTemplate.published = template.data.published;
          else
            this.templateEditor.editorState.messageTemplate.published = true;

          this.templateEditor.openEmailDialogueFromDesignLib(
            template.data.emailSubject,
            template.data.preHeaderText,
            template.data.isPublishingDb
          );
          this.templateEditor.editorState.showSendTestEmailDialog = true;

          this.operationInProgress = false;
          this.cdr.detectChanges();
        }
        else {
          this.showSendTestSMSDialog = true;
          if (template.data.previewBody === '') {
            //do nothing
          } else {
            this.smsMsgBody = JSON.parse(template.data.previewBody);
          }
          this.operationInProgress = false;
          this.cdr.detectChanges();
        }
      }
      catch (error) {
        this.operationInProgress = false;
      }
      this.cdr.detectChanges();
    }
  }

  async onCloseSendTestSMSDialog() {
    this.showSendTestSMSDialog = false;
    this.copyContent = new ContentLibraryModel();
    this.smsSentSuccess = false;
    this.smsInProgress = false;
    this.toNumber.setValue('');
    this.cdr.detectChanges();
  }

  sendTestSMS() {
    if (this.toNumber.value && this.smsMsgBody.length) {
      this.smsInProgress = true;
      this.smsService.SendSms(this.smsMsgBody, this.toNumber.value).subscribe(
        (response: RequestResponse) => {
          if (response?.data) {
            this.smsSentSuccess = true;
            this.toastService.showSuccess('Test Sms sent successfully.');

          } else {
            this.smsSentSuccess = false;
            this.toastService.showError(response.message);
            this.toNumber.setValue('');
          }
          this.smsInProgress = false;
          this.cdr.detectChanges();
        },
        (err) => {
          this.smsInProgress = false;
        }
      );
    } else {
      this.smsSentSuccess = false;
      this.toastService.showError('Please enter a correct number');
    }
  }

  sendTestSMSAnother() {
    this.smsSentSuccess = false;
    this.smsInProgress = false;
    this.toNumber.setValue('');
  }

  public onCopyDialog(content: ContentLibraryModel) {
    let pageIndex = ((this.webAPI.paging.currentPage - 1) * this.webAPI.paging.pageSize) + 1;
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterTotalRecords", this.webAPI.paging.totalRecords.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterCurrentPageNumber", this.webAPI.paging.currentPage.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterPageSize", this.webAPI.paging.pageSize.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterPageIndex", pageIndex.toString());
    this.storageHelper.SetDataInStorage("AduvoContentLibraryFilterSearchKeyword", this.searchKeyword.toString());

    this.copyContent = content;
    if (content.isCampaign) {//Campaign
      this.copyCampaignName = `${content.title} Copy`;
      this.copyCampaignDesc = content.description;
      this.showCampaignCopyDialog = true;
    }
    else {
      //MessageTemplate
      this.showTemplateCopyDialog = true;
      this.selectedTemplateClone = new MessageTemplate();
      this.selectedTemplateClone.title = this.copyContent.title;
      this.makeACopyDialog.title = this.copyContent.title;
      this.makeACopyDialog.initializeDialog();
    }
  }

  public onDeleteContent(content: ContentLibraryModel) {
    let id: string = content.id,
      isCampaign: boolean = content.isCampaign,
      isPublished: boolean = content.published,
      name: string = content.title,
      isPublishDb: boolean = content.isPublishDB

    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message: `Please confirm you want to Delete ${name}`,
        successButtonText: `Yes, I want to Delete`,
        cancelButtonText: 'Cancel',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        if (isCampaign) {//Delete campaign
          if (isPublishDb) return;

          try {
            await this.campaignsService.Delete(id);
            this.toastService.showSuccess('Campaign deleted successfully.');
            this.contentLibraryService.setContentLibraryOperation(new ContentLibraryOperation(content.requestType, true));

            // this.hardReload = true;
            // this.loadData(content.requestType); //@@## todo  use subject 
            this.callSearchObservable();

          } catch (message: any) {
            this.toastService.showError(message);
          }
        } else {
          this.messageTemplatesService.DeleteTemplate(id).subscribe((response: RequestResponse) => {
            if (response.status == 200) {
              this.toastService.showSuccess(`Template ${name} successfully deleted`);
              this.contentLibraryService.setContentLibraryOperation(new ContentLibraryOperation(content.requestType, true));

              // this.hardReload = true;
              // this.loadData(content.requestType); //@@## todo  use subject 
              this.callSearchObservable();
            }
            else {
              this.toastService.showError(response.message);
            }
          }, (err) => { this.toastService.showError('Error : ' + err); });

        }
      }
      //this.cdr.detectChanges();
    });
  }

  public async onPreview(content: ContentLibraryModel) {
    if (content.isCampaign) {
      this.campaignPreviewDetail = new CampaignPreviewDetails(content.id, content.title, content.isCampaign, content.activationMode, content.activatedByTenantUserId);
      this.campaignPreviewFlag = true;
      this.operationInProgress = false;
      this.cdr.detectChanges();
    } else {//template

      let template = await this.messageTemplatesService.GetTemplateById(
        content.id,
        content.isPublishDB
      );
      this.previewMessageTitle = template.data.title;
      if (template.data.templateTypeId == 1) {
        //Email Preview
        let dlg: any = this.previewDialog;
        console.log(template.data.previewBody);
        dlg.initializeDialog(template.data.previewBody, 616, 936);
        this.templatePreviewFlag = true;
        this.operationInProgress = false;
      } else {
        //SMS Preview
        this.smsMsgBody = JSON.parse(template.data.previewBody);
        this.textPreviewFlag = true;
        this.messageCategoryList.forEach((item, index) => {
          if (item.item_id == template.data.categoryId) {
            this.messageCategory = item.item_text;
          }
        });
        this.operationInProgress = false;
      }
      this.cdr.detectChanges();
    }
  }

  onClosePreviewDialog() {
    this.templatePreviewFlag = false;
    this.showTextPreviewDialog = false;
    this.showEmailEditor = false;
  }

  async onSaveCopyCampaignDialog(content: ContentLibraryModel) {
    if (this.copyCampaignName) {
      let response = await this.campaignsService.CopyCampaign(
        content.id,
        this.copyCampaignName,
        this.copyCampaignDesc,
        this.copyMessageTemplate,
        content.isPublishDB
      );
      if (response && response.status == 200 && response.data) {
        this.alertMessageDupCampaignNameString = '';
        this.showCampaignCopyDialog = false;
        this.saveClicked = false;
        this.copyContent = new ContentLibraryModel();
        this.copyCampaignName = '';
        this.copyCampaignDesc = '';
        this.copyMessageTemplate = false;
        this.toastService.showSuccess(`Campaign copied successfully.`);
        this.cdr.detectChanges();
        //this.contentLibraryService.setContentLibraryOperation(new ContentLibraryOperation(content.requestType, true));
        //reload 
        this.callSearchObservable();

        // this._router.navigate([
        //   '/marketing/campaigns/edit/' + response.data + '/default',
        // ]);
      } else {
        this.toastService.showError(response.message);
        if (response?.message?.startsWith('Campaign with same name is')) {
          this.alertMessageDupCampaignNameString =
            "Campaign '" + this.copyCampaignName + "' already exists.";
          this.saveClicked = false;
        }
      }
    }
  }

  async onCloseCopyDialog() {
    this.showCampaignCopyDialog = false;
    this.showTemplateCopyDialog = false;
    this.alertMessageDupCampaignNameString = '';
    this.copyContent = new ContentLibraryModel();
    this.cdr.detectChanges();
  }

  async onSaveMakeACopy(content: ContentLibraryModel) {
    var copyResponse = await this.messageTemplatesService.CopyTemplate(
      content.id,
      this.selectedTemplateClone.title,
      content.isPublishDB
    );

    if (copyResponse.data == true) {
      this.toastService.showSuccess('Template ' + this.selectedTemplateClone.title + ' successfully copied');
      this.makeACopyDialog.alertMessageCopyTemplateName = false;
      this.makeACopyDialog.alertMessageCopyTemplateNameString = '';

      // create template thumbnail in background
      let newMessageTemplateId = copyResponse.message;
      var responseThumbnail = await this.messageTemplatesService.CreateTemplateThumbnailURL(newMessageTemplateId, content.isPublishDB);

      //this.router.navigate(['/marketing/template-library/edit/' + copyResponse.message]);
      this.onCloseCopyDialog();
      //this.contentLibraryService.setContentLibraryOperation(new ContentLibraryOperation(content.requestType, true));
      this.callSearchObservable();

    } else {
      this.makeACopyDialog.saveClicked = false;
      this.makeACopyDialog.alertMessageCopyTemplateName = true;
      this.makeACopyDialog.alertMessageCopyTemplateNameString =
        "Template '" + this.selectedTemplateClone.title + "' already exists.";
      this.toastService.showError(copyResponse.message);
    }
  }

  private unSubscribeAllSubs() {
    if (this.subscription?.length) {
      this.subscription.forEach((element) => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.subscription = [];
    }
  }

  public DialogCloseEvent(event: any) {
    if (event == 'close') {
      this.campaignPreviewFlag = false;
    }
  }
  public convertDate(date) {
    return this.dateConverter.convertUtcToLocalTime(date, UtcToLocalTimeFormat.FULL);
  }

  public onClose(event: any) {
    this.showEmailEditor = false;
  }
  // public selectTemplateForWizard(isChecked: boolean, id: string, title: string, event: any) {
  public selectTemplateForWizard(content: any, event: any) {
    if (!content.dummySelectTemplate) {
      content.dummySelectTemplate = !content.dummySelectTemplate;
      if (content.dummySelectTemplate) {
        //this.selectItemsForWizard.push(new TemplateHtmlData(id, title));
        //called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4
        if (this.updateMsgModel?.isCalledFromWizard == 3) {//if calling From edit For Replace allow once or allow multiple
          this.webAPI.data.forEach(element => {
            if (element.id != content.id) {
              element.dummySelectTemplate = false;
            }
          });
          this.selectItemsForWizard = [];
          this.selectItemsForWizard = [new TemplateHtmlData(content.id, content.title, content.isPublishDB)]
        }
        else { //Normal scenario  
          this.selectItemsForWizard = [...this.selectItemsForWizard, new TemplateHtmlData(content.id, content.title, content.isPublishDB)]
        }
      }
      else {
        let idx = this.selectItemsForWizard.findIndex(x => x.id == content.id);
        if (idx > -1) {
          this.selectItemsForWizard.splice(idx, 1);
          this.selectItemsForWizard = [...this.selectItemsForWizard]
        }
      }
      this.cdr.detectChanges();
      this.emitSelectItemsForWizard();
    }
  }
  public emitSelectItemsForWizard() {
    this.onItemsSelectForWizardEvent.emit(this.selectItemsForWizard);
  }

  removeFilterClick(event: any, id: string) {
    let idx = this.selectItemsForWizard.findIndex(x => x.id == id);
    if (idx > -1) {
      this.selectItemsForWizard.splice(idx, 1);
      this.selectItemsForWizard = [...this.selectItemsForWizard]
    }

    this.webAPI.data.forEach(element => {
      if (element && element.id == id) {
        element.dummySelectTemplate = false;
      }
    });

    this.cdr.detectChanges();
    this.emitSelectItemsForWizard();
  }
  //to do later
  confirmAndRemoveFilterClick(event: any, id: string) {
    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: 'Yes, Remove',
      rejectLabel: 'Cancel',
      message: "Are you sure you want to remove this template?",
      accept: () => {
        // setTimeout(() => {
        let idx = this.selectItemsForWizard.findIndex(x => x.id == id);
        if (idx > -1) {
          this.selectItemsForWizard.splice(idx, 1);
          this.selectItemsForWizard = [...this.selectItemsForWizard]
        }

        this.webAPI.data.forEach(element => {
          if (element && element.id == id) {
            element.dummySelectTemplate = false;
          }
        });

        this.cdr.detectChanges();
        this.emitSelectItemsForWizard();

        //}, 0);

      },
      reject: () => {
        //this.toastService.showSuccess('A filter has been removed.');
      }
    });
  }

  clearLibraryFilterCache() {
    this.storageHelper.ClearStorageByKey("AduvoContentLibraryFilterTotalRecords");
    this.storageHelper.ClearStorageByKey("AduvoContentLibraryFilterCurrentPageNumber");
    this.storageHelper.ClearStorageByKey("AduvoContentLibraryFilterPageSize");
    this.storageHelper.ClearStorageByKey("AduvoContentLibraryFilterPageIndex");
    this.storageHelper.ClearStorageByKey("AduvoContentLibraryFilterSearchKeyword");
  }

  ngOnDestroy(): void {
    this.unSubscribeAllSubs();

    if (this.searchObservable) {
      this.searchObservable.unsubscribe();
      this.searchObservable = null;
    }
    //cleanup
    this.operationInProgress = false;
    this.contextMenuItems = [];
    this.campaignPreviewDetail = null;
    this.copyContent = new ContentLibraryModel();
    this.selectedTemplateClone = null;
    this.smsMsgBody = '';
  }

  GoToCompanyProfile() {
    this.router.navigate(['/company-profile']);
  }

  tabSelected($event: any) {

    if ($event === undefined) {

      const myContentIndex = this.filters.findIndex(s => s.field === 'myContent');
      const myContentValue = this.filters[myContentIndex].selected === true;
      this.contentFilter.myContent = myContentValue;

      const aduvoContentIndex = this.filters.findIndex(s => s.field === 'aduvoContent');
      const aduvoContentValue = this.filters[aduvoContentIndex].selected === true;
      this.contentFilter.aduvoContent = aduvoContentValue;

      const aduvoQuickStartIndex = this.filters.findIndex(s => s.field === 'aduvoQuickStart');
      const aduvoQuickStartValue = this.filters[aduvoQuickStartIndex].selected=== true;
      this.contentFilter.aduvoQuickStart = aduvoQuickStartValue;

      const aduvoRecommendedStartIndex = this.filters.findIndex(s => s.field === 'aduvoRecommended');
      const aduvoRecommendedValue = this.filters[aduvoRecommendedStartIndex].selected === true;
      this.contentFilter.aduvoRecommended = aduvoRecommendedValue;


      const categoryCSVIndex = this.filters.findIndex(s => s.field === 'category');
      const categoryCSVValue = this.filters[categoryCSVIndex].filterValues.map(s => s.value).join(',');
      this.contentFilter.categoryCSV = categoryCSVValue;

      const recipientsIndex = this.filters.findIndex(s => s.field === 'recipients');
      const recipientsValue = this.filters[recipientsIndex].filterValues.map(s => s.value).join(',');
      this.contentFilter.recipients = recipientsValue;

      const senderIndex = this.filters.findIndex(s => s.field === 'sender');
      const senderValue = this.filters[senderIndex].filterValues.map(s => s.value).join(',');
      this.contentFilter.sender = senderValue;

      const ownerIndex = this.filters.findIndex(s => s.field === 'owner');
      const ownerValue = this.filters[ownerIndex].filterValues.map(s => s.value).join(',');
      this.contentFilter.ownerID = ownerValue;

      const messageTypeIndex = this.filters.findIndex(s => s.field === 'messageType');
      this.contentFilter.email = this.filters[messageTypeIndex].filterValues.find(s => s === 'Email') !== undefined;
      this.contentFilter.textMessage = this.filters[messageTypeIndex].filterValues.find(s => s === 'Text Message') !== undefined;

      if(this.contentFilter.email || this.contentFilter.textMessage){
        this.activeTab = 2;
      }

      const campaignStatusIndex = this.filters.findIndex(s => s.field === 'campaignStatus');
      this.contentFilter.active = this.filters[campaignStatusIndex].filterValues.find(s => s === 'Active') !== undefined;
      this.contentFilter.inactive = this.filters[campaignStatusIndex].filterValues.find(s => s === 'InActive') !== undefined;

      switch(this.activeTab){
        case 0:
          this.contentFilter.singleMessage = false;
          this.contentFilter.campaign = false;
          this.activeTab = 0;
          break;
        case 1:
          this.contentFilter.singleMessage = false;
          this.contentFilter.campaign = true;
          this.activeTab = 1;
          break;
        case 2:
          this.contentFilter.singleMessage = true;
          this.contentFilter.campaign = false;
          this.activeTab = 2;
        break;
      }
    }

    switch ($event?.index) {
      case 0:
        this.contentFilter.singleMessage = false;
        this.contentFilter.campaign = false;
        this.activeTab = 0;
        break;
      case 1:
        this.contentFilter.singleMessage = false;
        this.contentFilter.campaign = true;
        this.activeTab = 1;
        break;
      case 2:
        this.contentFilter.singleMessage = true;
        this.contentFilter.campaign = false;
        this.activeTab = 2;
        break;
    }

    if(this.hideAllAndCampaignTab){
      this.contentFilter.singleMessage = true;
      this.contentFilter.campaign = false;
    }

    this.onFilterChangeEvent(this.contentFilter);
  }

  public campaignInfo: CampaignInfo;
  public contentFilterModel: ContentLibraryFilterModel;
  public buildYourOwnDialog: boolean;

  public buildYourOwnClick($event: any) { //Replace Message 3/Add Message From Editor 4
    // if change message is called, just redirect
    this.campaignInfo = new CampaignInfo();
    this.campaignInfo.campaignId = this.contentFilter?.campaignID;
    this.buildYourOwnDialog = true;
    
  }
}
