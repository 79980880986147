import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { BillingService } from 'src/services/billingService';
import { LookupService } from 'src/services/lookup.service';

@Component({
  selector: 'app-client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.scss']
})
export class ClientEditorComponent implements OnInit {

  tenantId: string = '';
  tenantForm: FormGroup = null;
  loadOriginationSystemOptions = [ 
    {label: 'None Selected', value: null},
    {label: 'LendingPad', value: 'LendingPad'},
    {label: 'ICE/Encompass', value: 'ICE/Encompass'},
    {label: 'PointCentral', value: 'PointCentral'},
    {label: 'CalyxPath', value: 'CalyxPath'},
    {label: 'Arive', value: 'Arive'}
  ];
  salesRepOptions = [{ label: 'None Selected', value: null }];
  sendgridApiKeyLoanStatusShow: boolean = false;
  sendgridApiKeyMarketingShow: boolean = false;
  twilioAPIKeyShow: boolean = false;
  initialFormValues: any;
  name: string = '';

  constructor(private billingService: BillingService, private lookupService: LookupService, private route: ActivatedRoute, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.tenantId = params['tenantId'];
      this.billingService.GetTenantById(this.tenantId).subscribe((response) => {
        this.populateForm(response.data);
      });
    });

    this.lookupService.GetSalesReps().subscribe(result => {
      if (result && result.data) {
        let salesReps: string[]  = result.data;
        salesReps.forEach((name) => {
          this.salesRepOptions.push({ label: name, value: name })
        })
      }
    });

  }

  populateForm(data: any){

    this.initialFormValues = data;
    this.name = data.name;
    this.tenantForm = this.formBuilder.group({
      lenderId: [data.lenderId, []],
      enabled: [data.enabled, []],
      deleted: [data.deleted, []],
      sendgridApiKeyLoanStatus: [data.sendgridApiKeyLoanStatus, []],
      sendgridApiKeyMarketing: [data.sendgridApiKeyMarketing, []],
      twilioAccountSid: [data.twilioAccountSid, []],
      twilioAPIKey: [data.twilioAPIKey, []],
      twilioMessagingServiceSid: [data.twilioMessagingServiceSid, []],
      twilioMessagingServiceSidMarketing: [data.twilioMessagingServiceSidMarketing, []],
      pointCentralSubscriptionId: [data.pointCentralSubscriptionId, [guidValidator()]],
      loanOriginationSystem: [data.loanOriginationSystem, []],
      fulfillmentUtcTime: [data.fulfillmentUtcTime, []],
      salesRep: [data.salesRep, []],
    });

  }

  get isFormChanged(): boolean {
    if(this.tenantForm == null)
      return false;
    return Object.keys(this.tenantForm.controls).some(key => this.tenantForm.get(key).dirty);
  }

  resetForm(): void {
    this.tenantForm.reset(this.initialFormValues);
  }

  save(){
      
    if(this.tenantForm.invalid){
      return;
    }
    

    this.billingService.PutTenant(this.tenantId, this.tenantForm.value).subscribe((response) => {
      const currentValues = this.tenantForm.value;

      // Reset the form
      this.tenantForm.reset();

      // Restore the captured values
      this.tenantForm.setValue(currentValues);
    });
      
  }

}

export function guidValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if(control.value == null)
      return null;
    const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    const valid = guidPattern.test(control.value);
    return valid ? null : { invalidGuid: true };
  };
}
