import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// ******************************************************
// Add import statements for all modules available/used
// ******************************************************

// Angular Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// PrimeNG Modules
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
// import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';

// *******************************************************************************
// Add import statements for all external services/data providers available/used
// *******************************************************************************
import { ConfirmationService } from 'primeng/api';
import { FilterService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { OnBoardingService } from 'src/services/onBoarding.service';
import { AuthenticationInterceptorService } from 'src/services/authInterceptor.service';
import { UserAnalyticsService } from 'src/services/userAnalytics.service';
import { ContactsService } from 'src/services/contacts.service';
// import { CustomerService } from 'src/services/customer.service';
import { LoansService } from 'src/services/Loans.service';
import { ToastService } from 'src/services/toast.service';
import { FilterHelperService } from 'src/services/filterHelper.service';
import { BitwiseService } from 'src/services/bitwise.service';
import { ContactTypeService } from 'src/services/contactType.service';
// import { TeamsService } from 'src/services/teams.service';
import { UserManagementService } from 'src/services/userManagement.service';
import { USAStatesService } from 'src/services/usstates.service';
import { ImageService } from 'src/services/image.service';
import { WhereClauseService } from 'src/services/whereClause.service';
import { ReportsService } from 'src/services/reports.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { MergeTagsService } from 'src/services/mergeTags.service';
import { StripoService } from 'src/services/stripo.service';
import { ImageLibraryService } from 'src/services/imageLibrary.service';
import { MessagesService } from 'src/services/messages.service';
// import { RulesService } from 'src/services/rules.service';
import { EmailStatsService } from 'src/services/emailStats.service';
// import { TestService } from 'src/services/test.service';
import { CampaignsService } from 'src/services/campaigns.service';
// import { AdministrationService } from 'src/services/administration.service';
import { UserTokenService } from 'src/services/user-token.service';
import { UserPreferenceService } from 'src/services/user-preference.service';
import { ProfileObserverService } from 'src/services/profileObserver.service';
import { CompanyObserverService } from 'src/services/companyObserver.service';
import { BillingService } from 'src/services/billingService';
import { NotificationService } from 'src/services/notificationService';
import { ContactAndLoanService } from 'src/services/contactAndLoan.service';

// *******************************************************************************
// Add import statements for all components we develop
// *******************************************************************************
import { defaultSkeletonComponent } from 'src/components/defaultSkeleton.component';
import { Dashboard } from 'src/components/dashboard/dashboard.component';
import { DashboardAnalytics } from 'src/components/dashboard/analytics/analytics.component';
import { analyticsSkeletonComponent } from 'src/components/dashboard/analytics/analyticsSkeleton.component';
import { recommendationsComponent } from 'src/components/dashboard/recommendations/recommendations.component';
import { recommendationsSkeletonComponent } from 'src/components/dashboard/recommendations/recommendationsSkeleton.component';
// import { activeMessagesComponent } from 'src/components/marketing/activemessages/activeMessages.component';
// import { allMessagesComponent } from 'src/components/marketing/allmessages/allMessages.component';
import { designLibraryComponent } from 'src/components/marketing/designlibrary/designLibrary.component';
import { contactsComponent } from 'src/components/contacts/contacts.component';
import { allReportsComponent } from 'src/components/reports/allreports/allReports.component';
import { activeLeadsComponent } from 'src/components/reports/activeleads/activeLeads.component';
import { activeLoansComponent } from 'src/components/reports/activeloans/activeLoans.component';
import { closedLoansComponent } from 'src/components/reports/closedloans/closedLoans.component';
import { billingComponent } from 'src/components/accounts/billing/billing.component';
// import { administrationComponent } from 'src/components/accounts/administration/administration.component';
// import { teamManagementComponent } from 'src/components/accounts/teammanagement/teamManagement.component';
// import { teamEditorComponent } from 'src/components/accounts/teammanagement/teamEditor.component';
// import { teamGridComponent } from 'src/components/accounts/teammanagement/teamGrid.component';
import { userManagementComponent } from 'src/components/accounts/usermanagement/userManagement.component';
import { userGridComponent } from 'src/components/accounts/usermanagement/userGrid.component';
import { reportManagementComponent } from 'src/components/reports/reportManagement.component';
import { reportContactsComponent } from 'src/components/reports/contacts/reportContacts.component';
import { reportLoansComponent } from 'src/components/reports/loans/reportLoans.component';
import { reportMessagesComponent } from 'src/components/reports/messages/reportMessages.component';
import { reportCampaignsComponent } from 'src/components/reports/campaigns/reportCampaigns.component';
import { reportSummaryComponent } from 'src/components/reports/summary/reportSummary.component';
// import { loginComponent } from 'src/components/login/login.component';
import { AuthorizationFailedComponent } from 'src/components/errors/Authorization/AuthorizationFailed.component';
import { createLeadDialogComponent } from 'src/components/dialogs/createLeadDialog.component';
import { createRealtorDialogComponent } from 'src/components/dialogs/createRealtorDialog.component';
import { createContactDialogComponent } from 'src/components/dialogs/createContactDialog.component';
import { contactEditComponent } from 'src/components/contacts/contactEdit.component';
import { contactImageComponent } from 'src/components/contacts/contactImage.component';
import { contactInformationComponent } from 'src/components/contacts/contactInformation.component';
import { contactDetailsComponent } from 'src/components/contacts/contactDetails.component';
import { contactMessagesComponent } from 'src/components/contacts/contactMessages.component';
import { contactLoanRecordsComponent } from 'src/components/contacts/contactLoanRecords.component';
import { ReportEditorComponent } from 'src/components/reports/reportEditor/reportEditor.component';
import { gridviewSkeletonComponent } from 'src/components/skeletons/gridviewSkeleton.component';
import { detailsviewSkeletonComponent } from 'src/components/skeletons/detailsviewSkeleton.component';
import { saveReportDialogComponent } from 'src/components/dialogs/saveReportDialog.component';
import { createMessageDialogComponent } from 'src/components/dialogs/createMessageDialog.component';
// import { forgotPasswordComponent } from 'src/components/login/forgotPassword.component';
import { stripoComponent } from 'src/components/marketing/designlibrary/stripo.component';
import { imageLibraryComponent } from 'src/components/marketing/designlibrary/imageLibrary.component';
import { imageUploadDialogComponent } from 'src/components/dialogs/imageUploadDialog.component';
import { imagePreviewDialogComponent } from 'src/components/dialogs/imagePreviewDialog.component';
import { previewDialogComponent } from 'src/components/dialogs/previewDialog.component';
import { contactUploadDialogComponent } from 'src/components/dialogs/contactUploadDialog.component';
import { messageTemplateEditorComponent } from 'src/components/marketing/designlibrary/messageTemplateEditor.component';
import { textMessageEditorComponent } from 'src/components/marketing/designlibrary/textMessageEditor.component';
import { textMessagePreviewDialogComponent } from 'src/components/dialogs/textMessagePreviewDialog.component';
// import { messageEditorComponent } from 'src/components/marketing/allmessages/messageEditor.component'
// import { templateEditorComponent } from 'src/components/marketing/allmessages/templateEditor.component'
import { campaignGridComponent } from 'src/components/marketing/campaigns/campaignGrid.component';
import { campaignEditorComponent } from 'src/components/marketing/campaigns/campaignEditor.component';
// import { rulesComponent } from 'src/components/rules/rules.component'
import { campaignManagementComponent } from 'src/components/marketing/campaigns/campaignManagement.component';
import { campaignDescriptionDialogComponent } from 'src/components/marketing/campaigns/campaignDescriptionDialog.component';
import { campaignDetailsDialogComponent } from 'src/components/marketing/campaigns/campaignDetailsDialog.component';
import { messageScheduleDialogComponent } from 'src/components/marketing/campaigns/messageScheduleDialog.component';
import { messageSubjectDialogComponent } from 'src/components/marketing/campaigns/messageSubjectDialog.component';
import { messageScheduleRepeatingComponent } from 'src/components/marketing/campaigns/messageScheduleRepeating.component';
import { messageScheduleSequenceComponent } from 'src/components/marketing/campaigns/messageScheduleSequence.component';
import { messageScheduleOneTimeComponent } from 'src/components/marketing/campaigns/messageScheduleOneTime.component';
import { StatsOverviewComponent } from 'src/components/marketing/campaigns/stats-overview/stats-overview.component';
import { reportStatsOverviewComponent } from 'src/components/reports/campaigns/report-stats-overview/reportStatsOverview.component';
import { savedReportsComponent } from 'src/components/reports/campaigns/savedReports.component';
import { subscriberActivityComponent } from 'src/components/marketing/campaigns/subscriber-activity/subscriberActivity.component';
import { addContactListDialogComponent } from 'src/components/dialogs/addContactListDialog.component';
import { listContactDetailComponent } from 'src/components/reports/contacts/list-contact-detail/listContactDetail.component';
import { listMessageHistoryComponent } from 'src/components/reports/messages/listMessageHistory.component';
import { sharingAndPermissionsComponent } from 'src/components/reports/contacts/SharingPermissions/sharingAndPermissions.component';
import { addPermissionsDialogComponent } from 'src/components/reports/contacts/SharingPermissions/addPermissionsDialog.component';
import { sentMessageLogComponent } from 'src/components/marketing/campaigns/send-message-log/sentMessageLog.component';
import { CreateNewComponent } from 'src/components/reports/allreports/create-new/createNewList.component';
import { messageAddDialogComponent } from 'src/components/marketing/campaigns/messageAddDialog.component';
import { contactNotesComponent } from 'src/components/contacts/contactNotes.component';
import { contactLeadsLoansComponent } from 'src/components/contacts/contactLeadsLoans.component';
import { contactRelatedContactsComponent } from 'src/components/contacts/contactRelatedContacts.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgIdleModule } from '@ng-idle/core';
import { TreeModule } from 'primeng/tree';

// *******************************************************************************
// Sandboxes - Remove for Production
// *******************************************************************************
// import { NealComponent } from 'src/components/sandbox/neal.component';
// import { MikeComponent } from 'src/components/sandbox/mike.component';
// import { PeterComponent } from 'src/components/sandbox/peter.component';
// import { RobertComponent } from 'src/components/sandbox/robert.component';
import { NotFoundComponent } from 'src/components/errors/NotFound/not-found.component';
import { CreateCampaignComponent } from '../components/marketing/campaigns/create-campaign/createCampaign.component';
// import { amandaComponent } from 'src/components/sandbox/amanda.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { RippleModule } from 'primeng/ripple';
import { MakeACopyComponent } from '../components/marketing/designlibrary/dialogs/make-a-copy/make-a-copy.component';
import { ConfirmModalComponent } from '../components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { PeopleAndLoanService } from '../services/peopleandloans.service';
import { SendTestEmailComponent } from '../components/marketing/designlibrary/dialogs/send-test-email/send-test-email.component';
import { EmailTemplatePreviewComponent } from '../components/marketing/designlibrary/dialogs/email-template-preview/email-template-preview.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SmsBodyTextareaComponent } from '../components/marketing/designlibrary/sms-body-textarea/sms-body-textarea.component';
import { TermsAndConditionsComponent } from '../screens/terms-and-conditions/terms-and-conditions.component';
import { PrivacyAndPolicyComponent } from '../screens/privacy-and-policy/privacy-and-policy.component';
import { SubmitATicketComponent } from '../screens/submit-a-ticket/submit-a-ticket.component';
import { WebUserAgreementComponent } from 'src/screens/web-user-agreement/web-user-agreement.component';
import { TermsUseComponent } from 'src/screens/terms-use/terms-use.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { SendTestSmsComponent } from 'src/components/marketing/designlibrary/dialogs/send-test-sms/send-test-sms.component';
import { CampaignMessageScheduleComponent } from '../components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule.component';
import { CampaignMessageScheduleStatusQuickPickComponent } from '../components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-status-quick-pick/campaign-message-schedule-status-quick-pick.component';
import { CampaignMessageScheduleRepeatingContactComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-repeating-contact-quick-pick/campaign-message-schedule-repeating-contact.component';
import { CampaignMessageScheduleRepeatingCustomDateComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-repeating-custom-date/campaign-message-schedule-repeating-custom-date.component';
import { CampaignMessageScheduleRepeatingHolidayComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-repeating-holiday-quick-pick/campaign-message-schedule-repeating-holiday.component';
import { CampaignMessageScheduleRepeatingWeeklyComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-repeating-weekly/campaign-message-schedule-repeating-weekly.component';
import { CampaignMessageScheduleRepeatingMonthlyComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-repeating-monthly/campaign-message-schedule-repeating-monthly.component';
import { CalendarAndTimeZoneService } from './services/CalendarAndTimeZoneService';
import { CampaignMessageScheduleCustomDateComponent } from '../components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-custom-date/campaign-message-schedule-custom-date.component';
import { ClickWrapComponent } from './click-wrap/click-wrap.component';
import { CampaignPreviewComponent } from '../components/marketing/campaigns/campaign-preview/campaign-preview.component';
import { CampaignDetailBlockComponent } from '../components/marketing/campaigns/campaign-detail-block/campaign-detail-block.component';
import { CreateCampaignWizardComponent } from '../components/marketing/campaigns/create-campaign-wizard/create-campaign-wizard.component';
import { CampaignMessageScheduleRepeatingDailyComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-repeating-daily/campaign-message-schedule-repeating-daily.component';
import { NewTextMessageEditorComponent } from 'src/components/marketing/designlibrary/newTextMessageEditor/create-new-text-message-editor.component';
import { ContentLibraryComponent } from '../components/marketing/campaigns/content-library/content-library.component';
import { ContentLibraryFilterComponent } from '../components/marketing/campaigns/content-library-filter/content-library-filter.component';
import { CampaignMessageScheduleHolidayQuickPickComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-holiday-quick-pick/campaign-message-schedule-holiday-quick-pick.component';
import { OnboardingComponent } from './onboarding/onboarding/onboarding.component';
import { WelcomeFormComponent } from './onboarding/welcome-form/welcome-form.component';
import { SendQueryComponent } from './onboarding/send-query/send-query.component';
import { CarrierFormComponent } from './onboarding/carrier-form/carrier-form.component';
//import { SubscriptionTextComponent } from './onboarding/subscription-text/subscription-text.component';
import { BookTimeComponent } from './onboarding/book-time/book-time.component';
import { CtaScreenComponent } from './onboarding/cta-screen/cta-screen.component';
import { AddAdminComponent } from './onboarding/add-admin/add-admin.component';
import { PermissionCollectComponent } from './onboarding/permission-collect/permission-collect.component';
import { FirstScreenComponent } from './onboarding/first-screen/first-screen.component';
import { OptInComponent } from './onboarding/opt-in/opt-in.component';
import { OptInEmailComponent } from './onboarding/opt-in-email/opt-in-email.component';
import { SetUpAccountComponent } from './onboarding/set-up-account/set-up-account.component';
import { AddIndividualComponent } from './onboarding/add-individual/add-individual.component';
//import { PSModule } from 'pactsafe-angular-sdk';
import { CampaignMessageScheduleContactQuickPickComponent } from 'src/components/marketing/campaigns/campaign-message-schedule/campaign-message-schedule-contact-quick-pick/campaign-message-schedule-contact-quick-pick.component';
import { ContentLibraryCarouselListComponent } from '../components/marketing/campaigns/content-library-carousel-list/content-library-carousel-list.component';
import { ContentLibraryFilterNavBarComponent } from '../components/marketing/campaigns/content-library-filter-nav-bar/content-library-filter-nav-bar.component';
import { ChipsModule } from 'primeng/chips';
import { ContentMessageScheduleComponent } from 'src/components/marketing/campaigns/content-message/content-message-schedule.component';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { LyTheme2, StyleRenderer, LY_THEME, LY_THEME_NAME, LyHammerGestureConfig } from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { Color } from '@alyle/ui/color';
import { LoadingComponent } from 'src/components/errors/Loading/loading.component';
import { SignalrService } from './services/signalr.service';
import { SelectPlanComponent } from './onboarding/select-plan/select-plan.component';
import { PeopleAndLoansModule } from './people-and-loans/people-and-loans.module';
import { ContactAndLoanModule } from './contact-and-loan/contact-and-loan-module';
import { BuildYourOwnMessageComponent } from '../components/marketing/campaigns/build-your-own-message/build-your-own-message.component';
import {MessageLogGridComponent} from '../components/marketing/campaigns/message-log-grid/message-log-grid.component';
import { CustomGridComponent } from 'src/components/custom-grid/custom-grid.component';
import { FiltersComponent } from 'src/components/custom-grid/filters/filters.component';
import { MessageTypePipe } from 'src/pipes/message-type.pipe';
import { MessageTypes } from 'src/enums/message-types';
import { DisplayFilterOptionPipe } from 'src/components/custom-grid/pipes/display-filter-option.pipe';
import { CapitalizePipe } from 'src/pipes/capitalize.pipe';
import { ClientManagerComponent } from 'src/components/accounts/client-manager/client-manager.component';
import { CampaignReportComponent } from 'src/components/marketing/campaigns/campaign-report/campaign-report.component';
import { CampaignUsageComponent } from 'src/components/marketing/campaigns/campaign-usage/campaign-usage.component';
import { ImpersonationBarComponent } from './impersonation-bar/impersonation-bar.component';
import { ClientEditorComponent } from 'src/components/accounts/client-editor/client-editor.component';
import { ToDashboardButtonComponent } from 'src/components/to-dashboard-button/to-dashboard-button.component';
import { CelebrationComponent } from 'src/components/celebration/celebration.component';
import { CreateContactDialogComponent } from './create-contact-dialog/create-contact-dialog.component';
import { HasTenantUserIdPipe } from 'src/pipes/has-tenant-user-id.pipe';

export class CustomMinimaLight {
  name = 'minima-light';
  demoBg = new Color(0x8c8c8c);
}

export type AppThemeVariables = MinimaLight
  & MinimaDark
  & CustomMinimaLight;

@NgModule({
  declarations: [
    AppComponent,
    // *******************************************************************************
    // Declare all components we develop
    // *******************************************************************************
    activeLeadsComponent,
    activeLoansComponent,
    // activeMessagesComponent,
    // allMessagesComponent,
    allReportsComponent,
    analyticsSkeletonComponent,
    AuthorizationFailedComponent,
    defaultSkeletonComponent,
    Dashboard,
    DashboardAnalytics,
    recommendationsComponent,
    recommendationsSkeletonComponent,
    designLibraryComponent,
    contactsComponent,
    addContactListDialogComponent,
    closedLoansComponent,
    closedLoansComponent,
    billingComponent,
    // administrationComponent,
    //  teamManagementComponent,
    //  teamGridComponent,
    //  teamEditorComponent,
    userManagementComponent,
    userGridComponent,
    reportCampaignsComponent,
    reportContactsComponent,
    reportLoansComponent,
    ReportEditorComponent,
    reportManagementComponent,
    reportMessagesComponent,
    reportSummaryComponent,
    // loginComponent,
    createLeadDialogComponent,
    createRealtorDialogComponent,
    createContactDialogComponent,
    contactEditComponent,
    contactImageComponent,
    contactInformationComponent,
    contactDetailsComponent,
    contactMessagesComponent,
    contactLoanRecordsComponent,
    listContactDetailComponent,
    saveReportDialogComponent,
    gridviewSkeletonComponent,
    detailsviewSkeletonComponent,
    createMessageDialogComponent,
    // forgotPasswordComponent,
    stripoComponent,
    imageLibraryComponent,
    imageUploadDialogComponent,
    imagePreviewDialogComponent,
    previewDialogComponent,
    contactUploadDialogComponent,
    messageTemplateEditorComponent,
    textMessageEditorComponent,
    textMessagePreviewDialogComponent,
    // messageEditorComponent,
    // templateEditorComponent,
    campaignGridComponent,
    campaignEditorComponent,
    campaignManagementComponent,
    campaignDescriptionDialogComponent,
    campaignDetailsDialogComponent,
    messageScheduleDialogComponent,
    messageScheduleRepeatingComponent,
    messageScheduleSequenceComponent,
    messageScheduleOneTimeComponent,
    messageSubjectDialogComponent,
    subscriberActivityComponent,
    // rulesComponent,
    StatsOverviewComponent,
    savedReportsComponent,
    reportStatsOverviewComponent,
    listMessageHistoryComponent,
    sharingAndPermissionsComponent,
    addPermissionsDialogComponent,
    sentMessageLogComponent,
    messageAddDialogComponent,

    // *******************************************************************************
    // Sandboxes - Remove for Production
    // *******************************************************************************
    // NealComponent,
    // MikeComponent,
    // PeterComponent,
    // RobertComponent,
    NotFoundComponent,
    CreateNewComponent,
    CreateCampaignComponent,
    // amandaComponent,
    contactNotesComponent,
    contactLeadsLoansComponent,
    contactRelatedContactsComponent,
    MakeACopyComponent,
    ConfirmModalComponent,
    contactRelatedContactsComponent,
    ConfirmModalComponent,
    SendTestEmailComponent,
    EmailTemplatePreviewComponent,
    SmsBodyTextareaComponent,
    TermsAndConditionsComponent,
    PrivacyAndPolicyComponent,
    WebUserAgreementComponent,
    TermsUseComponent,
    SubmitATicketComponent,
    SendTestSmsComponent,
    CampaignMessageScheduleComponent,
    CampaignMessageScheduleStatusQuickPickComponent,
    CampaignMessageScheduleHolidayQuickPickComponent,
    CampaignMessageScheduleContactQuickPickComponent,
    CampaignMessageScheduleRepeatingContactComponent,
    CampaignMessageScheduleRepeatingCustomDateComponent,
    CampaignMessageScheduleRepeatingHolidayComponent,
    CampaignMessageScheduleRepeatingWeeklyComponent,
    CampaignMessageScheduleRepeatingMonthlyComponent,
    CampaignMessageScheduleCustomDateComponent,
    CampaignMessageScheduleRepeatingDailyComponent,
    ClickWrapComponent,
    CampaignPreviewComponent,
    CampaignDetailBlockComponent,

    CreateCampaignWizardComponent,
    NewTextMessageEditorComponent,
    ContentLibraryComponent,
    ContentLibraryFilterComponent,
    OnboardingComponent,
    WelcomeFormComponent,
    SendQueryComponent,
    CarrierFormComponent,
    //SubscriptionTextComponent,
    BookTimeComponent,
    CtaScreenComponent,
    AddAdminComponent,
    PermissionCollectComponent,
    FirstScreenComponent,
    OptInComponent,
    OptInEmailComponent,
    SetUpAccountComponent,
    AddIndividualComponent,
    ContentLibraryCarouselListComponent,
    ContentLibraryFilterNavBarComponent,
    ContentMessageScheduleComponent,
    LoadingComponent,
    SelectPlanComponent,
    BuildYourOwnMessageComponent,
    MessageLogGridComponent,
    CustomGridComponent,
    FiltersComponent,
    MessageTypePipe,
    HasTenantUserIdPipe,
    DisplayFilterOptionPipe,
    CapitalizePipe,
    ClientManagerComponent,
    CampaignReportComponent,
    CampaignUsageComponent,
    ImpersonationBarComponent,
    ClientEditorComponent,
    ToDashboardButtonComponent,
    CelebrationComponent,
    CreateContactDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gTagMeasurementId),
    NgxGoogleAnalyticsRouterModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarGroupModule,
    AvatarModule,
    BadgeModule,
    BrowserAnimationsModule,
    BrowserModule,
    BlockUIModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CardModule,
    CascadeSelectModule,
    CheckboxModule,
    ChipModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DragDropModule,
    DropdownModule,
    DynamicDialogModule,
    FieldsetModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    InputSwitchModule,
    InputTextModule,
    KeyFilterModule,
    KnobModule,
    ListboxModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    OrderListModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    //PSModule.forRoot(),
    RadioButtonModule,
    ReactiveFormsModule,
    ScrollTopModule,
    ScrollPanelModule,
    SkeletonModule,
    SliderModule,
    SlideMenuModule,
    SplitButtonModule,
    SplitterModule,
    TabMenuModule,
    TableModule,
    TabViewModule,
    TagModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToolbarModule,
    TooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
    AuthConfigModule,
    RippleModule,
    PickerModule,
    NgIdleModule.forRoot(),
    TreeModule,
    ChipModule,
    ChipsModule,
    HammerModule,
    ContactAndLoanModule,
    PeopleAndLoansModule
  ],
  providers: [
    ConfirmationService,
    ContactsService,
    FilterService,
    MessageService,
    OnBoardingService,
    AuthenticationInterceptorService,
    // CustomerService,
    UserAnalyticsService,
    LoansService,
    ToastService,
    FilterHelperService,
    BitwiseService,
    ContactTypeService,
    // TeamsService,
    UserManagementService,
    USAStatesService,
    ImageService,
    WhereClauseService,
    ReportsService,
    MessageTemplatesService,
    MergeTagsService,
    StripoService,
    ImageLibraryService,
    MessagesService,
    // RulesService,
    EmailStatsService,
    // TestService,
    CampaignsService,
    // AdministrationService,
    UserTokenService,
    UserPreferenceService,
    DialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true,
    },
    PeopleAndLoanService,
    ProfileObserverService,
    CompanyObserverService,
    CalendarAndTimeZoneService,
    DatePipe,
    BillingService,
    StyleRenderer,
    SignalrService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (signalrService: SignalrService) => () => signalrService.initiateSignalrConnection(),
    //   deps: [SignalrService],
    //   multi: true,
    // },
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: LY_THEME, useClass: CustomMinimaLight, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    NotificationService,
    ContactAndLoanService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MakeACopyComponent,
    ConfirmModalComponent,
    SendTestEmailComponent,
    EmailTemplatePreviewComponent,
    SendTestSmsComponent,
    CampaignPreviewComponent,
    CampaignDetailBlockComponent,
    ContentLibraryFilterNavBarComponent, 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class AppModule { }
