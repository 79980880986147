<div id="manageListsDiv" class="content" role="main"
  [ngClass]="{ 'content-margin-wiz':(contentFilter.isCalledFromWizard >=3)  }" role="main">
  <div [ngClass]="{ 'flex':true, 
    'content table-search-row second-header-fixed wizardmargin ': !isCalledFromWizard,
    'content-search-wiz':isCalledFromWizard, ' w-100':isCalledFromWizard }">
    <table class="text-wrapper">
      <tr>
        <td>
          <label *ngIf="!isCalledFromWizard" id="lblPageHeader" class="px-1">Content Library</label>
          <span class="p-input-icon-left p-float-label">
            <input id="contentFilterSrchInput" maxlength="250" [(ngModel)]="searchKeyword"
              [ngClass]="{ 'w-180':isCalledFromWizard }" class="search-input rounded-pill" pInputText type="text"
              (keyup.enter)="onApplyGlobalFilter()" (input)="onGlobalFilterChange()"
              [placeholder]="isCalledFromWizard ? 'Search all templates by keyword' : 'Search by keyword'" />
          </span>
          <span type="button" pButton pRipple icon="fal fa-search search-fa" pTooltip="Execute Search"
            tooltipPosition="bottom" showDelay="500" (click)="onApplyGlobalFilter()"
            class="p-button-secondary execute-search"
            [ngClass]="{  'l-icon': !isCalledFromWizard, 's-icon':isCalledFromWizard}"> </span>
          <div class="input-group search-field position-relative p-ml-4"></div>
        </td>
        <td align="right" *ngIf="contentFilter?.isCalledFromWizard == 0 ">
          <div>
            <button pButton type="button" class="header-button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa-light fa-pen font-weight-bold"></i><span class="font-weight-bold">&nbsp; Create New Content &nbsp;</span>
              <img class="btn-close-white" src="../../../assets/images/blue-triangle.svg" alt="" />
            </button>
            <ul class="dropdown-menu" aria-labelledby="create-new-dropdown">
              <li><a class="dropdown-item" [routerLink]="['/marketing/campaigns/create/new']">Campaign</a></li>
              <li><a class="dropdown-item" [routerLink]="['/marketing/template-library/email']">Email</a></li>
              <li><a class="dropdown-item" [routerLink]="['/marketing/template-library/sms']">SMS Text Message</a></li>
            </ul>
          </div>
        </td>
      </tr>
    </table>
    <button *ngIf="showBuildYourOwnButton" pButton type="button" (click)="buildYourOwnClick($event)"
      class="btn build-button overflow-visible">
      <i class="fa fa-pencil pencil"></i>&nbsp;&nbsp;Build Your Own
    </button>
  </div>
</div>
<div class="" [ngClass]="{ 
  'short-grid-section': !isCalledFromWizard ,
'grid-section': isCalledFromWizard  }">
  <div class="filters-row mb-2">
    <app-filters class="d-table w-100" [columns]="filters" (updateTable)="tabSelected($event)">
    </app-filters>
  </div>
  <div class="container-fluid mb-2 text-wrapper">
    <p-tabView class="d-table w-100" styleClass="content-tabs content-library-filter-tabs"
      (onChange)="tabSelected($event)">
      <p-tabPanel *ngIf="!hideAllAndCampaignTab">
        <ng-template pTemplate="header">
          <span class="tab-label">All</span>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="!hideAllAndCampaignTab">
        <ng-template pTemplate="header">
          <span class="tab-label">Campaigns</span>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="tab-label">Templates</span>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>


  <div class="container-fluid text-wrapper">
    <div class="d-flex">
      <div class="col-12">
        <div class="cardGrid">
          <div class="row">
            <div [ngClass]="{ 'content-sort': !isCalledFromWizard}">
              <p-dropdown [options]="sortByList" optionLabel="label" [autoZIndex]="false" [baseZIndex]="1"
                optionValue="value" placeholder="Sort by" class="content-sort"
                (onChange)="onSortBySelectionChange($event)">
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <div *ngIf="contentFilter?.isCalledFromWizard > 0 && selectItemsForWizard.length">
              <div class="templateChip mt-2">
                <label class="chiplabel">MESSAGES ADDED TO CAMPAIGN</label><br />
                <p-confirmPopup></p-confirmPopup>

                <p-chips id="templateChip" field="html" [(ngModel)]="selectItemsForWizard"
                  styleClass="list_none float-left">
                  <ng-template let-item pTemplate="item">
                    <div [innerHTML]="item.html"></div>
                    <i class="fa fa-times" (click)="removeFilterClick($event, item.id)"></i>
                  </ng-template>
                </p-chips>
              </div>
            </div>
            <p-dataView #ContentLibraryDataView [value]="webAPI.data" [paginator]="webAPI.paging.totalRecords > 0"
              [rows]="webAPI.paging.pageSize" [rowsPerPageOptions]="[12, 24, 48]" [lazy]="true"
              (onLazyLoad)="onPageChanged($event)" [totalRecords]="webAPI.paging.totalRecords" layout="grid"
              scrollWidth="100%" scrollHeight="150px;" emptyMessage=" "
              class="contentLibraryDataViewPadding  content-library-grid" id="content-grid" [(first)]="pageIndex">
              <ng-template pTemplate="gridItem" let-row let-content let-rowIndex="rowIndex" let-columns="columns">
                <div class="card content-card mb-5">
                  <ng-container *ngIf="contentFilter?.isCalledFromWizard > 0; else showLibraryFilterDiv">
                    <div class="card-top">
                      <button class="add-camp-btn" (click)="selectTemplateForWizard(content, $event)"
                        [ngClass]="{'add-camp-btn': content.dummySelectTemplate,'add-camp-btn-enable': !content.dummySelectTemplate}"
                        id="selectTemplate">
                        <i id="faPlus" class="fa font-xs me-1" [ngClass]="{
                          'fa-check': content.dummySelectTemplate,
                          'fa-plus': !content.dummySelectTemplate
                          }"></i>
                        <span>{{content.dummySelectTemplate ? 'Added to Campaign': 'Add to Campaign'}}</span>
                      </button>
                    </div>
                  </ng-container>
                  <ng-template #showLibraryFilterDiv>
                    <div class="card-top">
                      <ng-container
                        *ngIf="isCompanyUserWithMarketing && content.activationMode == 'admin-mandatory'; else showPublishDiv">
                        <p-inputSwitch disabled id="inputSwithActive" class="inputSwithActive"
                          [(ngModel)]="content.published">
                        </p-inputSwitch>
                      </ng-container>
                      <ng-template #showPublishDiv>
                        <p-inputSwitch *ngIf="content.isCampaign || currentClientID === 'AduvoAdmin'" id="activate"
                          class="inputSwithActive" (onChange)="onActiveInactiveContent(content)"
                          [(ngModel)]="content.published">
                        </p-inputSwitch>
                      </ng-template>
                      <button type="button" aria-haspopup="true"
                        class="p-column-filter-menu-button table-ellipse-button" (focus)="ellipseMenu.hide()"
                        (click)="onEllipseClick($event, content)">
                        <span class="pi pi-ellipsis-h"></span>
                      </button>
                    </div>
                  </ng-template>
                  <div class="card-effect">
                    <div [ngClass]="{'image-holder': content.isCampaign, 'image-holder-temp' : !content.isCampaign }">
                      <ng-container *ngIf="content.htmlImageSrc; else showDiv2">
                        <img
                          [ngClass]=" {'card-img-top': content.isCampaign, 'card-img-top-temp' : !content.isCampaign }"
                          src="{{content.htmlImageSrc}}" alt="Card Message Image">
                      </ng-container>
                      <ng-template #showDiv2>
                        <ng-container *ngIf="content.msgCount==0">
                          <img
                            [ngClass]="{'card-img-top': content.isCampaign, 'card-img-top-temp' : !content.isCampaign }"
                            src="{{noImage}}" alt="Card image cap">
                        </ng-container>

                        <ng-container *ngIf="content.msgCount > 0">
                          <div class="messages"> {{content.msgCount}} Message(s) </div>
                        </ng-container>
                      </ng-template>
                    </div>
                    <div class="card-body mt-4">
                      <label class="card-title">{{content.title}} </label>
                      <span class="card-text">{{content.description}}</span>
                      <!-- <span class="card-text">{{content.ownedBy}}</span> -->
                      <!-- <span class="card-text">Last updated {{content.updatedDateUtc | date}}</span> -->
                    </div>
                    <div class="card-cover">
                      <div class="button-container">
                        <button pButton type="button" label="Preview" (click)="onPreview(content)"
                          [disabled]="content.isCampaign && content?.msgCount ==0">
                        </button><br>
                        <button pButton type="button" label="Edit" *ngIf="!isCalledFromWizard"
                          [disabled]="!content.isCampaign && content.isPublishDB" (click)="onEdit(content)">
                        </button>
                      </div>
                      <ng-container *ngIf="content.isCampaign">
                        <ng-container *ngIf="content.recipients">
                          <span class="f-bold">
                            Recipients:
                          </span>
                          <span class="recipientsDetails">
                            {{content.recipients}}
                          </span>
                        </ng-container>
                        <ng-container *ngIf="content.sender">
                          <span class="f-bold">
                            Sender:
                          </span>
                          <span class="senderDetails">
                            {{content.sender}}
                          </span>
                        </ng-container>
                      </ng-container>
                      <br>
                      <p class="LastUpdate" *ngIf="content?.updatedDateUtc">
                        Last updated {{ convertDate(content.updatedDateUtc)}}
                      </p>
                    </div>
                  </div>
                  <div class="card-footer d-flex justify-content-between mb-0 mt-auto">
                    <div class="d-flex align-items-center">
                      <ng-container *ngIf="content.isCampaign; else showMsgDiv">
                        <i *ngIf="content.templateTypeCount == 1" class="fa fa-envelope-o  fa-lg"
                          aria-hidden="true"></i>
                        <i *ngIf="content.templateTypeCount == 2" class="fa fa-regular fa-comment-lines fa-lg"
                          aria-hidden="true"></i>
                        <img *ngIf="content.templateTypeCount == 3" class="emailtexticon"
                          src="assets/images/msgtexticon.png" alt="Email & Text" style="height: 25px;" />
                        <ng-container *ngIf="content.msgCount==0; else showMsgSpan">
                          <i class="pi pi-circle-off"></i> <i class="pi pi-minus slash"></i>
                          <span>Empty Campaign</span>
                        </ng-container>
                        <ng-template #showMsgSpan>
                          <span *ngIf="content.msgCount == 1">
                            Single Message
                          </span>
                          <span *ngIf="content.msgCount > 1">
                            {{content.msgCount}} Messages Campaign
                          </span>
                        </ng-template>
                      </ng-container>
                      <ng-template #showMsgDiv>
                        <i *ngIf="content.templateTypeCount == 1" class="fa fa-envelope-o  fa-lg"
                          aria-hidden="true"></i>
                        <i *ngIf="content.templateTypeCount == 2" class="fa fa-regular fa-comment-lines fa-lg"
                          aria-hidden="true"></i>
                        <img *ngIf="content.templateTypeCount == 3" class="emailtexticon"
                          src="assets/images/msgtexticon.png" alt="Email & Text" style="height: 25px;" />
                        <span *ngIf="content.templateTypeCount == 1">Email Template</span>
                        <span *ngIf="content.templateTypeCount == 2">SMS Template</span>
                      </ng-template>
                    </div>
                    <div>
                      <ng-container *ngIf="content.isPublishDB; else showImg">
                        <img src="assets/images/logo-aduvo.png" pTooltip="Owned By Aduvo" tooltipPosition="bottom"
                          alt="Aduvo" class="img-fluid rounded-circle me-1" width="40">
                      </ng-container>
                      <ng-template #showImg>
                        <img *ngIf="content.ownerImageSrc?.length>3" src="{{content.ownerImageSrc}}"
                          pTooltip="{{content?.ownedBy}}" tooltipPosition="bottom" alt="{{content?.ownedBy}}"
                          class="img-fluid rounded-circle me-1" width="40">
                        <div *ngIf="content.ownerImageSrc?.length==3" pTooltip="{{content?.ownedBy}}"
                          tooltipPosition="bottom">
                          <p-avatar shape="circle" class="content-card-avatar img-fluid rounded-circle me-1"
                            [label]="content.ownerIntials">
                          </p-avatar>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="paginatorleft" let-state>
                <label style="font-weight: 400">{{ webAPI.paging.totalRecords }} {{ webAPI.paging.totalRecords > 1 ?
                  "results" : "result" }}</label>
              </ng-template>
              <ng-template pTemplate="paginatorright" let-state>
                <div>
                  <label style="margin-top: 1px; margin-right: 8px; font-weight: 400">Go to Page</label>
                  <input type="text" pInputText pKeyFilter="int" [(ngModel)]="webAPI.paging.currentPage" width="3"
                    maxlength="3" style="text-align: center; width: 4rem; margin-right: 8px; border-radius: 20px;"
                    (change)="onGotoPage($event)" />
                </div>
              </ng-template>
            </p-dataView>
            <div *ngIf="noRecordFound">
              <span> No records found</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-menu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [autoZIndex]="false" class="groundmenu"
  #ellipseMenu [popup]="true" [model]="contextMenuItems"></p-menu>

<app-campaign-preview *ngIf="campaignPreviewFlag" (DialogCloseEvent)="DialogCloseEvent($event)"
  [CampaignPreviewData]="campaignPreviewDetail">
</app-campaign-preview>

<p-dialog class="custom-dialog" #preview id=" " [resizable]="false" (onHide)="onClosePreviewDialog()"
  [(visible)]="templatePreviewFlag" [modal]="true" [baseZIndex]="10000"
  [style]="{ width: '768px', 'font-weight': '700' }">
  <previewDialog-root #previewDialog [compiledEmail]="'Test body'" [templateWidth]="616" [templateHeight]="936">
  </previewDialog-root>
  <p-header>
    <span class="dialog-head">{{ previewMessageTitle }}</span>
  </p-header>
</p-dialog>

<p-dialog class="custom-dialog" #preview id="smspreview" [resizable]="false" (onHide)="onClosePreviewDialog()"
  [(visible)]="textPreviewFlag" [modal]="true" [baseZIndex]="10000"
  [style]="{ width: '800px', height: '640px', 'font-weight': '500' }">
  <p-header>
    <span class="dialog-head">{{ previewMessageTitle }}</span>
  </p-header>

  <div class="flex-fill" style="padding: 0px !important">
    <div class="row">
      <div class="col-sm-5" style="background-color: #f5f5f5">
        <div style="padding: 50px">
          <ul class="list-group" style="border-radius: 0">
            <li class="list-group-item">
              <span class="dialog-head">Message Summary </span>
            </li>
            <li class="list-group-item">
              <span class="fade-txt">Message Category</span> <br />
              <span style="font-size: 16px">{{ messageCategory }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-7" style="padding: 50px">
        <div class="sms-preview-container" style="background-image: url(../../../../assets/images/phone-frame.svg)">
          <div class="sms-preview-block">
            <div class="sms-block" *ngFor="let smsBodyForm of smsMsgBody; last as isLast">
              <span>{{ smsBodyForm }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="showCampaignCopyDialog && copyContent" class="custom-dialog" id="copyCampaignDialog"
  #copyCampaignDialog class="custom-dialog" [resizable]="false" (onHide)="onCloseCopyDialog()" [modal]="true"
  [style]="{'width': '53em','font-weight': '700', 'z-index' : '1000!important'}">
  <p-header>
    <span class="dialog-head">Copy Campaign</span>
  </p-header>

  <table width="100%">
    <tr>
      <td style="text-align: left; font-family: HelveticaNeue; font-weight: 500;">
        <label class="form-label fw-bold">New campaign name</label>
        <div class="pb-2 text-secondary fst-italic">For internal use only</div>
      </td>
    </tr>
    <tr>
      <td style="width: 100%;">
        <input placeholder="[Copy] Campaign Name" #txtCopyCampaign maxlength="100" id="txtCopyCampaign" type="text"
          style="width: 100%; font-size: 14px;" [(ngModel)]="copyCampaignName" pInputText>
      </td>
    </tr>
    <tr>
      <td style="text-align: left; font-family: HelveticaNeue; font-weight: 500;">
        <label class="form-label fw-bold">Description</label>
      </td>
    </tr>
    <tr>
      <td style="width: 100%;">
        <textarea maxlength="255" [(ngModel)]="copyCampaignDesc" style="width: 100%; font-size: 14px; height: 100px;"
          pInputText></textarea>
      </td>
    </tr>
    <!--PV6UI-1269
    <tr *ngIf="copyContent?.isPublishDB">
      <td>
        <p-checkbox id="chkCopyMessgeTemplate" name="groupname" [binary]="true"
                    label="Make a copy of the Message Template." [(ngModel)]="copyMessageTemplate"></p-checkbox>
      </td>
    </tr>-->
    <tr *ngIf="alertMessageDupCampaignNameString">
      <td>
        <span class="text-danger">{{alertMessageDupCampaignNameString}}</span>
      </td>
    </tr>
  </table>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end footermodal align-items-center ">
      <a (click)="onCloseCopyDialog()"
        style="color:#2e2e2e;cursor: pointer;margin-right: 17.5px;text-decoration: underline;font-weight: 400;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (onClick)="onSaveCopyCampaignDialog(copyContent)" [style]="{'margin-left': '17.5px'}"
        [disabled]="!copyCampaignName || (saveClicked)" label="Save New Campaign"></p-button>
    </div>
  </ng-template>
</p-dialog>

<app-make-a-copy #makeACopyDialog [display]="showTemplateCopyDialog" (onCloseDialog)="onCloseCopyDialog()"
  [selectedMessageTemplate]="selectedTemplateClone" (onSaveMakeACopyClick)="onSaveMakeACopy(copyContent)"
  [title]="copyContent?.title">
</app-make-a-copy>

<p-dialog class="custom-dialog" [resizable]="false" (onHide)="onCloseSendTestSMSDialog()"
  [(visible)]="showSendTestSMSDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{ width: '62.5em', 'font-weight': '700' }">
  <p-header>
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="dialog-head">Send Test Text Message</span>
  </p-header>
  <div *ngIf="!smsSentSuccess">
    <table width="100%">
      <tr>
        <td valign="middle" class="div-label" width="10px">
          <span class="email-label">To Mobile Number</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input style="width: 60%" pInputText id="txtNumber" type="tel" [formControl]="toNumber" maxlength="20" />
        </td>
      </tr>
    </table>
  </div>
  <div class="isEmailResponse">
    <div class="success-message" style="text-align: center; font-weight: 400; color: green" *ngIf="smsSentSuccess">
      <p>SMS was sent successfully!</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a (click)="onCloseSendTestSMSDialog()" style="
          color: #2e2e2e;
          cursor: pointer;
          margin: 0px 17.5px;
          text-decoration: underline;
          font-weight: 400;
        " styleClass="p-button-secondary">Cancel</a>

      <p-button *ngIf="!smsSentSuccess" (onClick)="sendTestSMS()" [style]="{ 'margin-left': '17.5px' }" [disabled]="
          smsMsgBody?.length == 0 || !toNumber?.value || SMSInProgress
        " label="Send SMS"></p-button>
      <p-button *ngIf="smsSentSuccess" (click)="sendTestSMSAnother()" label="Send Another"
        [style]="{ 'cursor': 'pointer', 'margin-left': '17.5px' }" styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<stripo-root #templateEditor isEditorOpen="false" *ngIf="showEmailEditor" (onCloseBtnClick)="onClose('closedClicked')">
</stripo-root>

<p-dialog class="custom-alert custom-user-alert" [(visible)]="showTestModeDialog" [resizable]="false" [modal]="true"
  [style]="{ width: '52.5em', 'font-weight': '700'}">
  <div class="pb-25">
    <span class="alert-header">Your campaign has been activated, but you're currently in TEST mode.</span>
  </div>
  <div class="pb-35">
    <span class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;">
      At this time, messages will be sent to loan officer only. To begin sending to your contacts, change the
      Marketing Mode switch in your company profile from Test to Live.
    </span>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="GoToCompanyProfile()"
        label="Go To Company Profile" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showTestModeDialog = false" label="Ok"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="loadingPage || operationInProgress" class="pulseLoadingDialog" [draggable]="false"
  [resizable]="false" [modal]="true" [closeOnEscape]="false" closeable="false" [showHeader]="false"
  [baseZIndex]="1000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<p-dialog class="custom-dialog" #buildYourOwn [resizable]="false" (onHide)="buildYourOwnDialog = false"
  [(visible)]="buildYourOwnDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '62.5em','font-weight': '700'}">
  <p-header>
    <span class="dialog-head">Select Type</span>
  </p-header>
  <app-build-your-own-message [campaignInfo]="campaignInfo"></app-build-your-own-message>
</p-dialog>