import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ToastService } from 'src/services/toast.service';
import { EmailStats } from '../../../models/EmailStats';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignsService } from 'src/services/campaigns.service';
import { Campaign, CampaignDetailBlockObject, TemplateHtmlData, UpdateMsgModel } from '../../../models/Campaign';
import { RequestResponse } from '../../../models/RequestResponse';
import { ContactTypeService } from 'src/services/contactType.service';
import { Message } from 'src/models/Message';
import { MessagesService } from 'src/services/messages.service';
import { MessageTemplate, MessageTemplateDialogModel } from '../../../../src/models/MessageTemplate';
import { messageTemplateEditorComponent } from '../designlibrary/messageTemplateEditor.component';
import { ConfirmationService } from 'primeng/api';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { MergeTagsService } from 'src/services/mergeTags.service';
declare var ExternalMergeTags: any;
import { ExternalMergeTags } from 'src/scripts/mergeTags';
import { campaignDetailsDialogComponent } from './campaignDetailsDialog.component';
import { CampaignDetailBlockComponent } from './campaign-detail-block/campaign-detail-block.component';
import { UserAnalyticsService } from 'src/services/userAnalytics.service';
import { ContactsEventList, LoanStatusType } from '../../../../src/models/LoanStatusType';
import { LoansService } from '../../../../src/services/Loans.service';
import { UserManagementService } from 'src/services/userManagement.service';
import { TenantUser, TenantUserItem, DropDownItem } from 'src/models/TenantUser';
import { MessageTemplateEditorState } from 'src/models/state/messageTemplateEditor.state';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { LookupService } from 'src/services/lookup.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs/internal/Subscription';
import { CompanyObserverService } from 'src/services/companyObserver.service';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SMSService } from 'src/services/sms.service';
import {
  MessageScheduleData, RepeatingDailyData, RepeatingMonthlyData, RepeatingWeeklyScheduleData, RepeatingYearlyContactData,
  RepeatingYearlyCustomData, RepeatingYearlyHolidayData, SavedContactQuickPick, SavedOneTimeCustomDate} from '../../../../src/models/MessageScheduleData';
import { CommunicationService } from '../../../services/communication.service';
import { CMS_Data, SavedHolidayQuickPick } from '../../../models/MessageScheduleData';
import { DatePipe } from '@angular/common';
import { HolidayEventVM, TimezoneVM } from '../../../models/LookupModel';
import { CampaignMessageScheduleDataService } from '../../../services/campaignMessageSchedule.Data.service';
import { CalendarAndTimeZoneService } from '../../../app/services/CalendarAndTimeZoneService';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from '../designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { StatusQuickPickData } from '../../../../src/models/MessageScheduleData';
import { MessageCampaign } from 'src/models/MessageCampaign';
import { MessageLogGridComponent } from './message-log-grid/message-log-grid.component';
import { CelebrationService } from 'src/components/celebration/celebration.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'campaignEditor-root',
  templateUrl: './campaignEditor.component.html',
  styleUrls: [
    './campaignEditor.component.scss',
    './campaignManagement.component.scss',
  ]
})

export class campaignEditorComponent implements OnInit, OnDestroy {
  @ViewChild('previewDialog', { static: false }) previewDialog: Component;
  @ViewChild('textPreviewDialog', { static: false })
  textPreviewDialog: Component;
  @ViewChild('campaignStats', { static: false })
  StatsOverviewComponent: Component;

  @ViewChild('templateEditor', { static: false })
  templateEditor: messageTemplateEditorComponent;

  @ViewChild('txtSubject', { static: false })
  txtEmailSubject: HTMLTextAreaElement;
  @ViewChild('txtMessagePreviewtext', { static: false })
  txtMessagePreviewtext: HTMLTextAreaElement;

  @ViewChild('campaignDetailBlock') campaignDetailBlock: CampaignDetailBlockComponent;

  campaignId: any;
  ccList: string = '';
  bccList: string = '';
  toRecipient: string = '';
  reorderMessage: Message;
  loadingPage: boolean = false;
  webAPI: RequestResponse = new RequestResponse();
  campaign: Campaign = new Campaign();
  editorState: MessageTemplateEditorState = new MessageTemplateEditorState();
  messages: any;
  showPreviewDialog: boolean = false;
  showTextPreviewDialog: boolean = false;
  showCampaignDescDialog: boolean = false;
  campaignDescDialogTitle: string = 'Campaign';
  originalName: string = '';
  originalDesc: string = '';
  showCampaignDetailsDialog: boolean = false;
  showCopyCampaignDialog: boolean = false;
  showDeleteCampaignDialog: boolean = false;
  campaignDetailsDialogTitle: string = 'Edit Campaign Details';
  showMessageSubjectDialog: boolean = false;
  messageScheduleData: MessageScheduleData;
  messageScheduleDialogTitle: string = 'Message Schedule';
  message: Message = new Message();
  showSendTestEmailDialog: boolean = false;
  isEmailResponse = false;
  isEmailDialogeValid = false;
  emailDialogeErrorMessage = '';
  isSendTestEmailRequesting = false;
  responseType: boolean;
  responseMessage: string;
  fromEmail: string = 'noreply@focusitinc.com';
  toEmail: string = '';
  emailSubject: string = '';
  preHeaderText: string = '';
  messageTemplateId: string = '';
  isPublishedDbMessage: boolean = false;
  smsMsgBody: string[] = [];
  emailStats: EmailStats = new EmailStats('{}');
  campaignEmailStats: any;
  showNewMessageWizardVisible: boolean = false;
  showTemplateEditor: boolean = false;
  showSubscriberActivity: boolean = false;
  showSentMessageLog: boolean = false;
  showReports: boolean = false;
  showDetails: boolean = true;
  showUpgradeSubscriptionDialog: boolean = false;
  messageSubject = '';
  messageId = '';
  templatePreHeaderText = '';
  showMessagePreviewtextDialog = false;
  currentMessage: any;

  /* Stats*/
  deliveredPercent: number = 0;
  openedPercent: number = 0;
  clickedPercent: number = 0;
  undeliveredPercent: number = 0;
  optedOutPercent: number = 0;
  activeNav: string = 'details';

  //campaign edit
  campaignCategoryList: DropDownItem[] = [];
  loanStatusList: DropDownItem[] = [];
  userList: TenantUserItem[] = [];
  loanStatusTypeMasterList: DropDownItem[] = [];
  masterContactTypes: DropDownItem[] = [];
  loanAssignTypeRoles: DropDownItem[] = [];
  fromValue: string;
  WhoCanUsers: string;
  campaignCategory: string;
  toList: string;
  toListCustomConditions: string;
  ccData: string;
  bccData: string;
  toRecipientData: string;
  templateBody: any;
  templateTypeCount: number;
  copyCampaignName: string;
  copyCampaignDesc: string;
  showDeleteCampaignMessageDialog = false;
  deleteCampaignMessageTitle = '';
  userToken: UserToken;
  isCompanyUserWithMarketing = false;
  isCampaignStatsLoading = true;
  isCampaignDetailsLoading = true;
  previousCampaignMessages = [];
  copyMessageTemplate: boolean;
  subscription: Subscription;
  @ViewChild('campaignDetailsDialog')
  campaignDetails: campaignDetailsDialogComponent;
  storageHelper: StorageHelper;
  readonly expiredtimeInHour: number;
  canAddMessage: boolean = false;
  showSendTestSMSDialog: boolean = false;
  alertMessageDuplicateCampaignName: boolean = false;
  alertMessageDupCampaignNameString: string = '';
  saveClicked: boolean = false;
  messageTitle: string = '';
  messageCategory: string = '';
  messageCategoryList: any;
  currentClientID: string;
  invalidCampaignDetails: boolean;
  public smsSentSuccess: boolean = false;
  public toNumber = new FormControl('');
  public SMSInProgress: boolean = false;
  public messageTemplateDialogModel: MessageTemplateDialogModel;
  public buidYourOwnCampaignMsgId: string;
  public expandedRows: {} = {};
  public HolidayEventList: HolidayEventVM[];
  public TimezoneList: TimezoneVM[];
  public loanStatusTypeList: LoanStatusType[];
  public contactsEventList: ContactsEventList[];
  public campaignBlockDetail: CampaignDetailBlockObject;
  public isSavedDesc: boolean;
  public validationString: string = '';
  public showValidateCampaignDialog: boolean = false;
  public showValidationFlag: boolean;
  public isDeactivated: boolean;
  private previousUrl: string;
  public selectItemsForWizard: TemplateHtmlData[]; //used only if called from create wizard
  public updateMsgModel: UpdateMsgModel;//called from create wizard
  public changeMessageDetails: any;
  public cancelledFromFilter: boolean;
  public showTestModeDialog: boolean;
  public userRoleFoundInToRecipientList: boolean;
  public userRoleNameFoundInToRecipientListMessage: string;
  activeIndex: number = 0;
  @ViewChild(MessageLogGridComponent) MessageLogGridComponent: MessageLogGridComponent;

  constructor(
    private http: HttpClient,
    private userAnalyticsService: UserAnalyticsService,
    private campaignsService: CampaignsService,
    private messagesService: MessagesService,
    private toastService: ToastService,
    private _Activatedroute: ActivatedRoute,
    private _router: Router,
    private contactTypeService: ContactTypeService,
    private confirmationService: ConfirmationService,
    private mergeTagsService: MergeTagsService,
    private messageTemplateService: MessageTemplatesService,
    private loanService: LoansService,
    private userManagementService: UserManagementService,
    private userTokenService: UserTokenService,
    private lookupService: LookupService,
    private companyObserverService: CompanyObserverService,
    private SMSService: SMSService,
    private communicationService: CommunicationService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private datePipe: DatePipe,
    private calService: CalendarAndTimeZoneService,
    public dialogService: DialogService,
    public celebrationService: CelebrationService
  ) {
    this.copyMessageTemplate = false;
    this.expiredtimeInHour = environment?.SessionStorageExpiredTimeInHourForCampagin ?? 1; //in hour
    this.storageHelper = new StorageHelper();
    this.canAddMessage = false;
    this.messageTemplateDialogModel = new MessageTemplateDialogModel();
    this.buidYourOwnCampaignMsgId = '';
    this.messageScheduleData = new MessageScheduleData();
    this.loanStatusTypeList = [];
    this.campaignBlockDetail = new CampaignDetailBlockObject();
    this.currentClientID = environment.clientId;
    this.isSavedDesc = false;
    this.invalidCampaignDetails = false;
    this.showValidationFlag = false;
    this.isDeactivated = false;
    this.selectItemsForWizard = [];
    this.updateMsgModel = new UpdateMsgModel();
    this.updateMsgModel.isCalledFromWizard = 0;//
    this.changeMessageDetails = null;
    this.smsMsgBody = [];
    this.cancelledFromFilter = false;
    this.showTestModeDialog = false;
    this.userRoleFoundInToRecipientList = false;
    this.userRoleNameFoundInToRecipientListMessage = "";
  }

  /** Occurs upon component initialization */
  async ngOnInit(): Promise<void> {

    let backURL = this.storageHelper.GetDataFromStorage("contentLibrarybackURL");//fetch 
    if (backURL?.value) {
      this.previousUrl = backURL?.value;
      //console.log('backURL?.value:' + backURL?.value);
    }
    let currentUrl = this._router?.url;
    if (currentUrl) {
      this.storageHelper.ClearStorageByKey("currentURL");
      this.storageHelper.SetDataInStorage("currentURL", currentUrl)
    }

    this._Activatedroute.paramMap.subscribe((params) => {
      this.campaignId = params.get('id');
      this.buidYourOwnCampaignMsgId = params.get('id2') ?? '';
      this.messageTemplateDialogModel.campaignId = this.campaignId;
      this.getData(this.campaignId);
      this.getMergeTagsData();
      this.getLast12AndPopularMergeTagsData();
      if (params && params.get('type') == 'build') {
        this.onShowNewMessageDialog();
      }
    });

    this.campaignsService.getNewCampaignId().subscribe((campaignId) => {
      this.clearCampaignDetails();
      this.messageTemplateDialogModel.campaignId = this.campaignId;
      this.campaignId = campaignId;
      this.messageTemplateDialogModel.campaignId = this.campaignId;
      this.getData(campaignId);
    });

    this.subscription = this.companyObserverService
      .getdefaultSenderEmail()
      .subscribe((res) => {
        if (res.length > 0) {
          this.userToken.TenantDefaultSenderEmail = res;
        }
      });

    //for instant ScheduleMessage change
    this.communicationService.CMS_Schedule$.subscribe((data: CMS_Data) => {
      if (data.campaignMessageId && data.isReload) {
        this.loadingPage = true;
        let campaignMessageId = data.campaignMessageId;
        //getting only single record.
        this.messagesService
          .GetCampaignMessageScheduleById(this.campaignId, campaignMessageId)
          .subscribe(
            (response) => {
              if (response?.data?.scheduleDataJSON) {
                let index = this.messages.data.findIndex(
                  (x) => x.campaignMessageId == campaignMessageId
                );
                let msg = this.messages.data[index];
                if (index > -1) {
                  msg.scheduleType = response.data.scheduleType;
                  msg.scheduleSubtype = response.data.scheduleSubtype;
                  msg.scheduleDataJSON = response.data.scheduleDataJSON;

                  this.CreateScheduleMessageSub(
                    msg.scheduleType,
                    msg.scheduleSubtype,
                    msg.scheduleDataJSON,
                    index
                  );
                }
              }
              //Generate ScheduleMessage for every message
              this.loadingPage = false;
            },
            (err) => {
              this.loadingPage = false;
            }
          );
      }
    });
    this.lookupService.GetCampaignCategories().subscribe(
      (result) => {
        if (result && result.data) {
          this.messageCategoryList = result.data.map((obj) => {
            return {
              item_id: obj.categoryId,
              item_text: obj.categoryName,
            };
          });
        }
      },
      (error) => {
        this.toastService.showError(error);
      }
    );
  }

  /** Retrieves all necessary data for this component. */
  async getData(campaignId: any) {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    this.isCampaignDetailsLoading = true;
    try {
      if (this.campaignId) {
        this.userToken = this.userTokenService.getUser();

        if (this.userToken.isCompanyUserWithMarketing()) {
          this.isCompanyUserWithMarketing = true;
        }

        this.campaignsService
          .GetCampaignbyId(this.campaignId)
          .subscribe((response) => {
            //redirect if campaign is deleted/unpublish. 
            if (!response?.data) {
              this._router.navigate(['/404']);
            }
            this.campaign = response.data;
            this.webAPI.data = this.campaignId;
            this.campaign.templateTypeCount = this.campaign.templateTypeCount || 0;
            this.copyCampaignDesc = this.campaign.description;

            //Fetching campaign Stats
            this.isCampaignStatsLoading = true;
            this.getCampaignStats(this.campaignId, this.campaign.templateTypeCount);

            forkJoin(
              this.messagesService.GetCampaignMessagesObs(this.campaignId),
              this.loanService.GetLoanStatusType(this.campaign.isPublishDbRecord),
              this.lookupService.GetCampaignCategories(this.campaign.isPublishDbRecord),
              this.userManagementService.GetAllUserList(this.campaign.publishTenantId),
              this.loanService.GetLoanStatusTypeMasterList(this.campaign.isPublishDbRecord),
              this.loanService.GetMasterContactTypes(this.campaign.isPublishDbRecord),
              this.loanService.GetLoanAssignTypeRoles(this.campaign.isPublishDbRecord),
              this.lookupService.GetHolidays(),
              this.lookupService.GetTimezones(),
              this.lookupService.GetContacts()
            ).subscribe(
              ([
                campaignMessageResponse,
                loanStatusTypeResponse,
                campaignCategoryResponse,
                userListResponse,
                loanStatusTypeMasterListResponse,
                masterContactTypeResponse,
                loanAssignTypeRoleResponse,
                Holidays,
                Timezones,
                contactresponse,
              ]) => {
                this.messages = campaignMessageResponse;
                this.loanStatusTypeList = loanStatusTypeResponse.data;
                this.contactsEventList = contactresponse.data;
                if (Holidays?.data?.length > 0) {
                  this.HolidayEventList = Holidays.data;
                }
                if (Timezones?.data?.length > 0) {
                  this.TimezoneList = Timezones.data;
                }

                //Generate ScheduleMessage for every message
                this.CreateScheduleMessage();

                //for instant ScheduleMessage change
                // this.communicationService.CMS_Schedule$.subscribe((data: CMS_Data) => {
                //   if (data.campaignMessageId && data.scheduleText) {
                //     let msg = this.messages.data.find(x => x.campaignMessageId == data.campaignMessageId);
                //     if (msg) {
                //       msg.ScheduleMessage = data.scheduleText;
                //       // message.scheduleDataJSON
                //     }
                //   }
                // });

                if (this.buidYourOwnCampaignMsgId) {
                  const cnt = this.messages.data.length - 1;
                  const thisRef = this;
                  this.messages.data.forEach(function (msg, index) {
                    thisRef.expandedRows[msg.campaignMessageId] =
                      msg.campaignMessageId.toLowerCase() ==
                        thisRef.buidYourOwnCampaignMsgId.toLowerCase()
                        ? true
                        : false;
                  });
                  //load stats for newly added/changed msg template.
                  let msg = this.messages.data.find(
                    (x) =>
                      x.campaignMessageId.toLowerCase() ==
                      thisRef.buidYourOwnCampaignMsgId.toLowerCase()
                  );
                  if (msg) {
                    this.getMessageDetails(msg);
                  }
                  //open last message
                  // const cnt = this.messages.data.length - 1;
                  // const thisRef = this;
                  // this.messages.data.forEach(function (msg, index) {
                  //   thisRef.expandedRows[msg.campaignMessageId] = (index == cnt) ? true : false;
                  // });
                }

                this.canAddMessage = true;

                if (campaignCategoryResponse && campaignCategoryResponse.data) {
                  this.campaignCategoryList = campaignCategoryResponse.data.map(
                    (obj) => {
                      return {
                        item_id: obj.categoryId,
                        item_text: obj.categoryName,
                      };
                    }
                  );
                }

                if (userListResponse?.data) {
                  if (this.currentClientID === 'AduvoAdmin') {
                    let tenantUserList: TenantUser[] = [
                      {
                        tenantUserId: '-1',
                        aspNetUserId: '-1',
                        name: 'All Users',
                        userType: 'All',
                        emailId: '',
                        phoneNumber: '',
                        losUser: 'NA',
                        losRole: 'NA',
                        losStatus: 'NA',
                      },
                    ];
                    this.SetListItems(tenantUserList);
                  } else {
                    let tenantUserList: TenantUser[] = userListResponse.data;
                    this.SetListItems(tenantUserList);
                  }
                }

                if (
                  loanStatusTypeMasterListResponse &&
                  loanStatusTypeMasterListResponse.data
                ) {
                  let masterList = loanStatusTypeMasterListResponse.data;
                  this.loanStatusTypeMasterList = masterList.map((obj) => {
                    return {
                      item_id: obj.masterListId,
                      item_text: obj.title,
                      item_is_contact: obj.isContactOnly,
                    };
                  });
                  this.loanStatusList = this.loanStatusTypeMasterList;
                }

                if (
                  masterContactTypeResponse &&
                  masterContactTypeResponse.data
                ) {
                  let contactTypes = masterContactTypeResponse.data;
                  this.masterContactTypes = contactTypes.map((obj) => {
                    return {
                      item_id: obj.masterContactTypeId,
                      item_text: obj.name,
                    };
                  });
                }

                if (
                  loanAssignTypeRoleResponse &&
                  loanAssignTypeRoleResponse.data
                ) {
                  let assignTypes = loanAssignTypeRoleResponse.data;
                  this.loanAssignTypeRoles = assignTypes.map((obj) => {
                    return {
                      item_id: obj.loanAssignTypeId,
                      item_text: obj.name,
                    };
                  });
                  this.loanAssignTypeRoles.sort((a, b) => { return a.item_text.toLowerCase() >= b.item_text.toLowerCase() ? 1 : -1 });
                  this.loanStatusList = this.loanAssignTypeRoles;
                }

                if (this.currentClientID === 'AduvoAdmin') {
                  // do nothing
                } else {
                  this.getScheduledQueueMessage();
                }
                this.refreshCampaignDetails();
              },
              (error) => {
                this.loadingPage = false;
                this.isCampaignDetailsLoading = false;
                this.toastService.showError(
                  'Error while loading campaign details, Please try again.'
                );
              }
            );
          });
      }
    } catch (message: any) {
      this.webAPI.paging.totalRecords = 0;
      this.loadingPage = false;
      this.isCampaignStatsLoading = false;
      this.isCampaignDetailsLoading = false;
      this.toastService.showError(message);
      this.canAddMessage = false;
    }
  }
  // OneTime	StatusQuickPick  =>[{"LoanStatusTypeId":"46081a50-d222-41df-b7d6-3fc2a06e1800","Days":"0"}]
  // OneTime	HolidayQuickPick =>
  //[{"HolidayEventId":"c59907e1-41d7-404b-a380-7ce4fb7fda2e","Time":"01:01:00.0000000","TimezoneId":"F0128093-9082-4C17-9C6F-20709DE037FE"}]

  CreateScheduleMessage() {
    if (this.messages?.data?.length) {
      //has messages
      for (let i = 0; i < this.messages.data.length; i++) {
        let scheduleType = this.messages.data[i].scheduleType;
        let ScheduleSubtype = this.messages.data[i].scheduleSubtype;
        let ScheduleDataJSON = this.messages.data[i].scheduleDataJSON;

        this.CreateScheduleMessageSub(
          scheduleType,
          ScheduleSubtype,
          ScheduleDataJSON,
          i
        );
      }
    }
  }

  private CreateScheduleMessageSub(
    scheduleType: string,
    ScheduleSubtype: string,
    ScheduleDataJSON: string,
    i: number
  ) {
    if (ScheduleDataJSON) {
      if (scheduleType === 'OneTime') {
        if (ScheduleSubtype === 'StatusQuickPick') {
          let savedObject: StatusQuickPickData =
            JSON.parse(ScheduleDataJSON)[0];
          let status =
            this.loanStatusTypeList.find(
              (x) => x.loanStatusTypeId == savedObject.loanStatusTypeId
            )?.displayName ?? '';
          let messageText = '';
          let dayType = '';
          let periodUnit = '';
          let timeType = '';
          let offset = 'before';
          if (savedObject.offsetTypeId) {
            if (savedObject.offsetTypeId == 'AFTER') {
              offset = 'after'
            }
            if (savedObject.day === '1') {
              periodUnit = `${savedObject.periodUnit.toLowerCase()}`;
            }
            else {
              periodUnit = `${savedObject.periodUnit.toLowerCase()}s`;
            }
            if (savedObject.sendOndayTypeId == 'ANYDAY') {
              dayType = 'anyday';
            }
            else {
              dayType = 'a weekday';
            }
            if (savedObject.time) {
              let timeZoneDisplayName = this.getTimeZoneDisplayName(
                savedObject.timezoneId.toLowerCase()
              );
              let time = this.Get12HourTime(savedObject.time);

              let timeString = `<span>${time}</span><span>${timeZoneDisplayName}</span>`;
              timeType = timeString;
            }
            else {
              timeType = 'anytime';
            }
            if (savedObject.periodUnit == 'NODELAY') {
              messageText = `Wait to send message <span>One Time </span>with <span>no delay ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
            } else if (savedObject.periodUnit == 'DAY') {
              messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
            } else if (savedObject.periodUnit == 'MONTH') {
              messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
            } else if (savedObject.periodUnit == 'WEEK') {
              messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
            } else if (savedObject.periodUnit == 'YEAR') {
              messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
            }
          }
          else {
            messageText = `Wait to send message <span>One Time </span>when <span>${status}</span>`;
          }

          this.messages.data[i].ScheduleMessage = messageText;
        } else if (ScheduleSubtype === 'HolidayQuickPick') {
          let savedObject: SavedHolidayQuickPick =
            JSON.parse(ScheduleDataJSON)[0];
          let calanderDisplayName = this.getCalanderDisplayName(
            savedObject.HolidayEventId.toLowerCase()
          );
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.TimezoneId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.Time);
          this.messages.data[
            i
          ].ScheduleMessage = `Send messages on <span>${calanderDisplayName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'ContactQuickPick') {
          let savedObject: SavedContactQuickPick =
            JSON.parse(ScheduleDataJSON)[0];
          let calanderDisplayName = this.getContactEventDisplayName(
            savedObject.ContactEventId.toLowerCase()
          );
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.TimezoneId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.Time);
          this.messages.data[
            i
          ].ScheduleMessage = `Send messages on <span>${calanderDisplayName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'CustomDate') {
          let savedObject: SavedOneTimeCustomDate =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.TimezoneId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.Time);
          var dateStr = this.datePipe.transform(
            savedObject.DeliverOn,
            'MM/dd/yyyy'
          );
          this.messages.data[
            i
          ].ScheduleMessage = `Send messages on <span>${dateStr}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
        }
      } else if (scheduleType === 'Repeating') {
        if (ScheduleSubtype === 'Monthly') {
          let savedObject: RepeatingMonthlyData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.timezoneId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.time);
          let days = this.calService.getOrdinalNumber(savedObject.ordinalName);

          // repeating -> Montlhy -> day of the month (1,30) ->amd Ordianlname,weekday =null|0
          // repeating-> first ,weekday of everymonth. -> amwd Ordinalname|weekday
          if (savedObject.repeatingMonthlyType.toLowerCase() == 'amd') {
            //Send messages every {{Nth}} day of every month at {{HH:MM AMPM}}{{TIMEZONE}}
            this.messages.data[
              i
            ].ScheduleMessage = `Send messages every <span>${days}</span> day of every month at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
          } else if (savedObject.repeatingMonthlyType.toLowerCase() == 'amwd') {
            let weekNumber = Number(savedObject.ordinalName);
            let weekDays = 'Last';
            if (weekNumber != 5) {
              weekDays = this.calService.getOrdinalNumber(weekNumber);
            }
            // Send messages every {{Nth Weekday}} of every {{Month}} at {{HH:MM AMPM}}{{TIMEZONE}}
            let weekName =
              this.calService.weekdays.find(
                (x) => x.code == savedObject.weekday
              )?.name ?? '-';
            this.messages.data[
              i
            ].ScheduleMessage = `Send messages every <span>${weekDays}</span> <span>${weekName}</span> of every month at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
          }
        } else if (ScheduleSubtype === 'YearlyCustom') {
          let savedObject: RepeatingYearlyCustomData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.timezoneId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.time);
          let monthName =
            this.calService.months.find((x) => x.code == savedObject.month)
              ?.name ?? '-';

          if (savedObject.repeatingMonthlyType.toLowerCase() == 'amd') {
            let days = this.calService.getOrdinalNumber(
              savedObject.onTheXofEveryMonthOrdinal
            );
            this.messages.data[
              i
            ].ScheduleMessage = `Send messages on the <span>${days}</span> day of every <span>${monthName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
          } else if (savedObject.repeatingMonthlyType.toLowerCase() == 'amwd') {
            let weekNumber = Number(
              savedObject.everyXweekdayOfEveryMonthOrdinal
            );
            let weekDays = 'Last';
            if (weekNumber != 5) {
              weekDays = this.calService.getOrdinalNumber(weekNumber);
            }
            let weekName =
              this.calService.weekdays.find(
                (x) => x.code == savedObject.weekday
              )?.name ?? '-';
            this.messages.data[
              i
            ].ScheduleMessage = `Send messages on the <span>${weekDays}</span> <span>${weekName}</span>
             of every <span>${monthName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
          }
        } else if (ScheduleSubtype === 'YearlyContact') {
          //`Send messages on the contact's {{Calendar Event Display Name}} at {{HH:MM AMPM}} {{TIMEZONE}} `
          let savedObject: RepeatingYearlyContactData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.timezoneId.toLowerCase()
          );
          let calanderDisplayName = this.getContactEventDisplayName(
            savedObject.contactEventId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.time);

          this.messages.data[
            i
          ].ScheduleMessage = `Send messages on the contact's <span>${calanderDisplayName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'WeeklyWeekday') {
          let savedObject: RepeatingWeeklyScheduleData =
            JSON.parse(ScheduleDataJSON)[0];
          let msg = '';
          let cnt = savedObject?.scheduleDetail?.length;
          if (savedObject && cnt > 0) {
            let time = this.Get12HourTime(savedObject.scheduleDetail[0].time);
            let timeZoneDisplayName = this.getTimeZoneDisplayName(
              savedObject.scheduleDetail[0].timezoneId.toLowerCase()
            );

            if (cnt === 1) {
              let weekName =
                this.calService.weekdays.find(
                  (x) => x.code == savedObject.scheduleDetail[0].weekday
                )?.name ?? '-';
              msg = `Send messages every <span>${weekName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
              // Send messages every {{Weekday}} at {{HH:MM AMPM}}{{TIMEZONE}} [Cancel messages button]
            } else if (cnt > 1) {
              // If there are two or more weekly schedules:
              // Send messages multiple weekly schedules at {{HH:MM AMPM}}{{TIMEZONE}} [Cancel messages button]
              //msg = `Send messages multiple weekly schedules at ${savedObject.scheduleDetail[0].weekday} at ${time} ${ timeZoneDisplayName }`;
              let postFix = 'Send messages every ';
              let preFix = '';
              for (
                let index = 0;
                index < savedObject.scheduleDetail.length;
                index++
              ) {
                var schedule = savedObject.scheduleDetail[index];
                if (schedule) {
                  let weekName =
                    this.calService.weekdays.find(
                      (x) => x.code == schedule.weekday
                    )?.name ?? '-';

                  let scheduleTime = this.Get12HourTime(schedule.time);
                  let scheduleTimeZone = this.getTimeZoneDisplayName(
                    schedule.timezoneId.toLowerCase()
                  );
                  msg += `${postFix} ${preFix} <span>${weekName}</span> at <span>${scheduleTime}</span><span>${scheduleTimeZone}</span>`;
                  preFix = ' and every ';
                  postFix = '';
                }
              }
            }
          }
          this.messages.data[i].ScheduleMessage = msg;
        } else if (ScheduleSubtype === 'YearlyHoliday') {
          let savedObject: RepeatingYearlyHolidayData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.timezoneId.toLowerCase()
          );
          let calanderDisplayName = this.getCalanderDisplayName(
            savedObject.holidayEventId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.time);
          let endYearMsg = '';
          if (savedObject.endingYear) {
            endYearMsg = ` | Ending Year <span>${savedObject.endingYear}</span>`;
          }
          this.messages.data[
            i
          ].ScheduleMessage = `Send messages on <span>${calanderDisplayName}</span> (Starting Year <span>${savedObject.startingYear}</span>${endYearMsg}) at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'DailyEvent') {
          let savedObject: RepeatingDailyData = JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName = this.getTimeZoneDisplayName(
            savedObject.timezoneId.toLowerCase()
          );
          let time = this.Get12HourTime(savedObject.time);
          this.messages.data[
            i
          ].ScheduleMessage = `Send messages daily at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
        }
        if (this.messages.data[i].ScheduleMessage) {
          this.showValidationFlag = false;
        }
      } else {
        this.messages.data[i].ScheduleMessage = 'No Schedule';
      }
    }
  }

  OnCancelQueueMessage(message: any) {
    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message:
          'Do you want to cancel sending the messages for ' +
          message.messageTemplateTitle +
          '?',
        successButtonText: 'Yes, I want to cancel',
        cancelButtonText: 'Cancel',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        this.cancelQueueMessage(message);
      }
    });
  }

  async cancelQueueMessage(message: any) {
    this.loadingPage = true;
    var response =
      await this.campaignsService.CancelCampaignMessagesQueuedSchedule(message);
    if (response && response.status == 200) {
      this.toastService.showSuccess(response.message);
    } else {
      this.toastService.showError(response.message);
    }
    this.getData(this.campaignId);
    const thisRef = this;
    this.messages.data.forEach(function (msg, index) {
      thisRef.expandedRows[msg.campaignMessageId] = false;
    });
    this.loadingPage = false;
  }

  async getScheduledQueueMessage() {
    if (this.messages?.data?.length) {
      return;
    }
    for (let i = 0; i < this.messages.data.length; i++) {
      var response =
        await this.campaignsService.GetCampaignMessagesQueuedSchedule(
          this.messages.data[i].campaignMessageId
        );
      let data = response.data;

      if (response.status == 200 && data.length > 0) {
        this.messages.data[i].campaignScheduleEventId =
          data[0].campaignScheduleEventId;
        this.messages.data[i].batchId = data[0].batchId;
        this.messages.data[i].campaignScheduleEventDateId =
          data[0].campaignScheduleEventDateId;

        let todaysDate: Date = new Date();
        let deliveryDate: Date = new Date(data[0].deliverOn);
        let deliveryTime = data[0].time;

        let deliveryDateTime: Date = new Date(data[0].deliverOn);
        deliveryDateTime.setHours(deliveryTime.split(':')[0]);
        deliveryDateTime.setMinutes(deliveryTime.split(':')[1]);

        var hours = this.TimezoneList.find(
          (x) => x.timezoneId == data[0].timezoneId
        ).currentTimeZoneOffsetInHours;

        deliveryDateTime.setTime(
          deliveryDateTime.getTime() - hours * 60 * 60 * 1000
        );

        let UTCDate = new Date(
          todaysDate.getTime() + todaysDate.getTimezoneOffset() * 60000
        );
        let UTCTime = UTCDate.toTimeString();
        let currentTimezoneUTCTime = this.getCurrentTimezoneTime(
          UTCTime,
          data[0].timeZoneOffsetInHours
        );
        let currentTimezoneUTCDateTime = new Date(
          todaysDate.getTime() + todaysDate.getTimezoneOffset() * 60000
        );
        currentTimezoneUTCDateTime.setHours(
          parseInt(currentTimezoneUTCTime.split(':')[0])
        );
        currentTimezoneUTCDateTime.setMinutes(
          parseInt(currentTimezoneUTCTime.split(':')[1])
        );

        const status = data[0].status.toUpperCase();
        if (
          status == 'QUEUED' &&
          this.currentClientID !== 'AduvoAdmin' &&
          deliveryDateTime >= currentTimezoneUTCDateTime
        ) {
          this.messages.data[i].isScheduleAlertShown = true;
          let days = 0;
          if (UTCDate.getDate() != deliveryDate.getDate()) {
            days = Math.abs(
              Math.floor(
                (UTCDate.getTime() - deliveryDate.getTime()) /
                1000 /
                60 /
                60 /
                24
              )
            );
          }
          let convertDaysIntoMinutes = days * 24 * 60;
          var currentTimePassedMinutes = this.getMintues(
            currentTimezoneUTCTime
          );
          var deliveryMinutes =
            this.getMintues(data[0].time) + convertDaysIntoMinutes;
          var num = deliveryMinutes - currentTimePassedMinutes;
          var hours = num / 60;
          var rhours = Math.floor(hours);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
          //var result = num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
          if (Math.floor(hours) == 0) {
            if (Math.floor(rminutes) == 1) {
              this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rminutes + ' minute';
            } else {
              this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rminutes + ' minutes';
            }
          } else if (Math.floor(hours) == 1) {
            if (Math.floor(rminutes) == 1) {
              this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rhours + ' hour and ' + rminutes + ' minute';
            } else {
              this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rhours + ' hour and ' + rminutes + ' minutes';
            }
          } else {
            if (Math.floor(rminutes) == 1) {
              this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rhours + ' hours and ' + rminutes + ' minute';
            }
            else {
              if (Math.floor(rminutes) == 0) {
                this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rhours + ' hours';
              } else {
                this.messages.data[i].QueuedMessage = 'This message will be sent in ' + rhours + ' hours and ' + rminutes + ' minutes';
              }
            }
          }
        }
      } else {
        if (this.messages?.data?.length) {
          this.messages.data[i].isScheduleAlertShown = false;
        }
      }
    }
  }

  getCurrentTimezoneTime(time: string, timeZoneOffsetInHours) {
    var hms = time; // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours
    var hours = +a[0] + timeZoneOffsetInHours;

    //hr:mm:sec
    var currentTimezoneTime = hours + ':' + a[1] + ':' + a[2];
    return currentTimezoneTime;
  }

  getMintues(time: string) {
    var hms = time; // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  private getTimeZoneDisplayName(timezoneId: string) {
    return this.TimezoneList.find(
      (x) => x.timezoneId.toLowerCase() === timezoneId
    )?.displayName;
  }
  private getCalanderDisplayName(holidayEventId: string) {
    return this.HolidayEventList.find(
      (x) => x.holidayEventId.toLowerCase() === holidayEventId
    )?.name;
  }
  private getContactEventDisplayName(contactEventId: string) {
    return this.contactsEventList.find(
      (x) => x.contactEventId.toLowerCase() === contactEventId
    )?.name;
  }

  private Get12HourTime(timeStr: string) {
    //HH:MM AMPM
    let hours = '',
      minutes = '',
      AmPM = '';

    if (timeStr?.length >= 6) {
      var timeArr = timeStr.substring(0, 6).split(':'); //[HH:MM]
      if (parseInt(timeArr[0]) >= 12) {
        if (parseInt(timeArr[0]) == 12) {
          hours = parseInt(timeArr[0]).toString(); //PM
        } else {
          hours = (parseInt(timeArr[0]) - 12).toString(); //PM
        }
        AmPM = 'PM';
      } else {
        if (parseInt(timeArr[0]) == 0) {
          hours = '12'; //AM
        } else {
          hours = parseInt(timeArr[0]).toString(); //AM
        }
        AmPM = 'AM';
      }
      minutes = parseInt(timeArr[1]).toString();
      hours = '0' + hours;
      hours = hours.substring(hours.length - 2);
      minutes = '0' + minutes;
      minutes = minutes.substring(minutes.length - 2);
    } else {
      return `00: 00 AM`;
    }

    return `${hours}:${minutes} ${AmPM} `;
  }

  private SetListItems(tenantUserList: TenantUser[]) {
    this.userList = tenantUserList.map((obj) => {
      if (obj.tenantUserId == this.campaign.ownerId) {
        return {
          item_id: obj.tenantUserId,
          item_text: obj.name,
          isDisabled: false,
          campiagnOwner: true,
        };
      } else {
        return {
          item_id: obj.tenantUserId,
          item_text: obj.name,
          isDisabled: false,
          campiagnOwner: false,
        };
      }
    });
    this.userList.sort((a, b) => { return a.item_text.toLowerCase() >= b.item_text.toLowerCase() ? 1 : -1 });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.messages.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        this.messages.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    if (event.previousIndex != event.currentIndex) {
      this.updateCampaignMessageOrder();
    }
  }

  async updateCampaignMessageOrder() {
    // if(this.IsOrderChanged()) {
    let campaignMessages = this.messages.data.map((el) => {
      return el.campaignMessageId;
    });
    let response = await this.campaignsService.updateCampaignMessageOrder(
      this.campaignId,
      campaignMessages
    );
    if (response && response.status == 200) {
      this.toastService.showSuccess(response.message);
    } else {
      this.toastService.showError(response.message);
    }
    //  }
  }

  // IsOrderChanged() {
  //   let result=false;
  //   for (let i=0;i<this.messages.data.length;i++) {
  //     if(this.messages.data[i].campaignMessageId!=this.previousCampaignMessages[i].campaignMessageId) {
  //       result=true;
  //       break;
  //     }
  //   }
  //   return result;
  // }

  onBack() {
    // this._router.navigate(['marketing/content-library']);
    let url = this.previousUrl ? this.previousUrl : 'marketing/content-library-filter/All'
    this.storageHelper.ClearStorageByKey("backURL");
    this._router.navigateByUrl(url);
  }

  dragEnd(event, index: number) {
    console.log('drag end', event, index, this.messages);
    this.drop(event);
    this.reorderMessage = undefined;
  }

  async onShowDescriptionDialog() {
    if (!this.campaign.isPublishDbRecord) {
      // save original data in case user cancels changes.
      this.originalName = this.campaign.name;
      this.originalDesc = this.campaign.description;

      setTimeout(() => {
        this.showCampaignDescDialog = true;
      }, 100);
    }
  }

  async onCloseDescriptionDialog() {
    this.showCampaignDescDialog = false;
    this.resetNameAndDesc();
    this.isSavedDesc = false;
  }

  async onCancelDescriptionDialog() {
    this.showCampaignDescDialog = false;
    // undo changes to original data
    this.resetNameAndDesc();
  }

  private resetNameAndDesc() {
    if (!this.isSavedDesc) {
      this.campaign.name = this.originalName;
      this.campaign.description = this.originalDesc;
    }
  }

  async onSaveDescriptionDialog() {
    if (this.campaign.isPublishDbRecord) {
      return;
    }
    this.isSavedDesc = true;
    var campaigndesc;

    //await this.campaignDescDialog.clearDialog();
    if (
      this.campaign.fromLoanAssignTypeId ==
      '00000000-0000-0000-0000-000000000000'
    ) {
      this.campaign.fromLoanAssignTypeId = null;
    }
    if (this.campaign.name != '') {
      campaigndesc = {
        campaignId: this.campaign.campaignId,
        name: this.campaign.name,
        description: this.campaign.description,
      };
      try {
        let response = await this.campaignsService.SaveCampaignNameDesc(
          campaigndesc
        );
        if (response && response.status != 200 && response.message) {
          this.toastService.showError(response.message);
          this.campaign.name = this.originalName;
          this.campaign.description = this.originalDesc;
        }
      } catch (message: any) {
        this.campaign.name = this.originalName;
        this.campaign.description = this.originalDesc;
        this.loadingPage = false;
        console.error(message);
        this.toastService.showError(message);
      }
    } else {
      this.campaign.name = this.originalName;
    }

    setTimeout(() => {
      this.showCampaignDescDialog = false;

      //if (this.refreshRequired)
      //  this.refreshData();
    }, 100);
  }

  async onShowDetailsDialog() {
    this.campaignDetails.setCampaignDetails(this.campaign);
    setTimeout(() => {
      this.showCampaignDetailsDialog = true;
    }, 100);
  }

  //event from parent
  openShowDetailsDialog(event) {
    if (event == 'ShowDetailsDialog') {

      this.campaignDetails.setCampaignDetails(this.campaign);

      if (this.campaign.published && (this.userTokenService.getUser().role !== 'FocusIT System Admin')) {

        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              "Are you sure you want to set '" +
              this.campaign.name +
              "' to inactive?",
            successButtonText: 'Yes',
            cancelButtonText: 'Cancel',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.onSwithInctive();
            setTimeout(() => {
              this.showCampaignDetailsDialog = true;
            }, 100);
          }
        });
      } else {
        setTimeout(() => {
          this.showCampaignDetailsDialog = true;
        }, 100);
      }
    }
  }

  async onShowCopyCampaignDialog() {
    // if(this.messages && this.messages.data && this.messages.data.length > 0){
    setTimeout(() => {
      this.copyCampaignName = this.campaign.name + " Copy";
      this.copyCampaignDesc = this.campaign.description;
      this.showCopyCampaignDialog = true;
    }, 100);
    // }
  }

  async onShowDeleteCampaignDialog() {
    if (!this.campaign.isPublishDbRecord) {
      setTimeout(() => {
        this.showDeleteCampaignDialog = true;
      }, 100);
    }
  }

  async onSaveCopyCampaignDialog() {
    if (this.copyCampaignName) {
      let response = await this.campaignsService.CopyCampaign(
        this.campaignId,
        this.copyCampaignName,
        this.copyCampaignDesc,
        this.copyMessageTemplate,
        this.campaign.isPublishDbRecord
      );
      if (response && response.status == 200 && response.data) {
        if (this.campaign.isPublishDbRecord) {
          //clean cache
          this.cleanDetails();
          //Clean Storage
          //this.storageHelper.ClearStorageForCamapign(this.campaign.isPublishDbRecord);
        }

        this.alertMessageDuplicateCampaignName = false;
        this.alertMessageDupCampaignNameString = '';
        this.showCopyCampaignDialog = false;
        this.saveClicked = false;
        this._router.navigate([
          '/marketing/campaigns/edit/' + response.data + '/default',
        ]);
      } else {
        this.toastService.showError(response.message);
        if (response?.message?.startsWith('Campaign with same name is')) {
          this.alertMessageDuplicateCampaignName = true;
          this.alertMessageDupCampaignNameString =
            "Campaign '" + this.copyCampaignName + "' already exists.";
          this.saveClicked = false;
        }
      }
    }
  }

  async onCloseCopyCampaignDialog() {
    this.showCopyCampaignDialog = false;
    this.alertMessageDuplicateCampaignName = false;
    this.alertMessageDupCampaignNameString = '';
  }

  async deleteCampaign() {
    if (this.loadingPage || this.campaign.isPublishDbRecord) return;

    this.loadingPage = true;
    try {
      await this.campaignsService.Delete(this.campaignId);
      this.loadingPage = false;
      this.toastService.showSuccess('Campaign deleted successfully.');
      let url = this.previousUrl ? this.previousUrl : 'marketing/content-library'
      this._router.navigateByUrl(url);
      //this._router.navigate([url]);
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onUpdateCampaignDetailsDialog() {
    if (this.campaign.isPublishDbRecord) {
      return;
    }
    let campaignDetails = this.campaignDetails.getCampaignDetails();
    let editCampaingDetails = {
      campaignId: this.campaignId,
      tenantUserIdCSV: (this.userList?.length == campaignDetails.tenantUserIdCSV.length) ? null : campaignDetails.tenantUserIdCSV ? campaignDetails.tenantUserIdCSV.map(x => x.item_id).join() : '',
      // tenantUserIdCSV: campaignDetails.tenantUserIdCSV
      //   ? campaignDetails.tenantUserIdCSV.map((x) => x.item_id).join()
      //   : '',
      fromLoanAssignTypeId: campaignDetails.fromLoanAssignTypeId
        ? campaignDetails.fromLoanAssignTypeId
        : null,
      fromEmailAddress: campaignDetails.fromEmailAddress
        ? campaignDetails.fromEmailAddress
        : '',
      fromEmailAddressName: campaignDetails.fromEmailAddressName
        ? campaignDetails.fromEmailAddressName
        : '',
      fromContactOwner: campaignDetails.fromContactOwner,
      isContactOnly: campaignDetails.IsContactOnly,
      // toReportCSV: campaignDetails.toReportCSV,
      toReportCSV: campaignDetails.toReportCSV
        ? campaignDetails.toReportCSV.map((x) => x.item_id).join()
        : '',
      toContactTypeIdCSV: campaignDetails.toContactTypeIdCSV
        ? campaignDetails.toContactTypeIdCSV.map((x) => x.item_id).join()
        : '',
      toLoanAssignTypeIdCSV: campaignDetails.toLoanAssignTypeIdCSV
        ? campaignDetails.toLoanAssignTypeIdCSV.map((x) => x.item_id).join()
        : '',
      toEmailCSV: campaignDetails.toEmailCSV ? campaignDetails.toEmailCSV : '',
      ccContactTypeIdCSV: campaignDetails.ccContactTypeIdCSV
        ? campaignDetails.ccContactTypeIdCSV.map((x) => x.item_id).join()
        : '',
      ccLoanAssignTypeIdCSV: campaignDetails.ccLoanAssignTypeIdCSV
        ? campaignDetails.ccLoanAssignTypeIdCSV.map((x) => x.item_id).join()
        : '',
      ccEmailCSV: campaignDetails.ccEmailCSV ? campaignDetails.ccEmailCSV : '',
      bccContactTypeIdCSV: campaignDetails.bccContactTypeIdCSV
        ? campaignDetails.bccContactTypeIdCSV.map((x) => x.item_id).join()
        : '',
      bccLoanAssignTypeIdCSV: campaignDetails.bccLoanAssignTypeIdCSV
        ? campaignDetails.bccLoanAssignTypeIdCSV.map((x) => x.item_id).join()
        : '',
      bccEmailCSV: campaignDetails.bccEmailCSV
        ? campaignDetails.bccEmailCSV
        : '',
      categoryId: campaignDetails.campaignCategoryId,
      isQuickStart: campaignDetails.isQuickStart,
      isRecommended: campaignDetails.isRecommended
    };

    let response = await this.campaignsService.SaveCampaignDetails(
      editCampaingDetails
    );
    if (response && response.status && response.status == 200) {
      this.toastService.showSuccess('Campaign details updated successfully.');
      this.campaignsService
        .GetCampaignbyId(this.campaignId)
        .subscribe((result) => {
          if (!result?.data) {
            this._router.navigate(['/404']);
          } else {
            this.campaignDetailBlock.updateValidationFlag(false);
            this.showValidationFlag = false;
            this.campaign = result.data;
            this.refreshCampaignDetails();
          }
        });
    }
    this.showCampaignDetailsDialog = false;
  }

  async refreshCampaignDetails() {
    this.cleanDetails();
    this.setFromValue();
    this.setCCValue();
    this.setBCCValue();
    this.setTOValue();
    this.setwhoCanUseCSV();
    this.setCampaignCategory();
    this.setToList();
    this.campaignBlockDetail.isLoading = false; //Hide block comp loader.
    this.campaignBlockDetail.isPublishCampaign =
      this.campaign.isPublishDbRecord;
    this.campaignBlockDetail.trimAllString();
    this.loadingPage = false;
    this.isCampaignDetailsLoading = false;
    this.campaignBlockDetail.isAllUsersWhoCanUseThisCampaign = this.campaign.isAllUsersWhoCanUseThisCampaign;
    this.campaignBlockDetail.isQuickStart = this.campaign.isQuickStart;
    this.campaignBlockDetail.isRecommended = this.campaign.isRecommended;
  }

  cleanDetails() {
    this.campaignBlockDetail.Clear();
  }

  private setFromValue() {
    if (this.campaign.fromLoanAssignTypeId) {
      let role = this.loanStatusList.find(
        (x) => x.item_id == this.campaign.fromLoanAssignTypeId
      );
      if (role) {
        this.campaignBlockDetail.fromValue = role.item_text;
      }
    } else if (this.campaign.fromContactOwner) {
      this.campaignBlockDetail.fromValue = 'Contact Owner';
    } else {
      this.campaignBlockDetail.fromValue = this.campaign.fromEmailAddress;
    }
  }

  private setCCValue() {
    // Start CC field manipulation
    let ccInformation = '';
    if (this.campaign.cc_ContactTypeIdCSV) {
      let Ids = this.campaign.cc_ContactTypeIdCSV.split(',');
      let Names = '';
      for (let i = 0; i < Ids.length; i++) {
        let username = this.masterContactTypes.find(
          (x) => x.item_id.toLowerCase() == Ids[i].toLowerCase()
        );
        if (username) {
          Names += username.item_text + ', ';
        }
      }
      ccInformation = Names;
    }

    if (this.campaign.cc_LoanAssignTypeIdCSV) {
      let Ids = this.campaign.cc_LoanAssignTypeIdCSV.split(',');
      let Names = '';
      for (let i = 0; i < Ids.length; i++) {
        let username = this.loanAssignTypeRoles.find(
          (x) => x.item_id.toLowerCase() == Ids[i].toLowerCase()
        );
        if (username) {
          Names += username.item_text + ', ';
        }
      }
      ccInformation = ccInformation + Names;
    }

    if (this.campaign.cc_EmailCSV) {
      ccInformation = ccInformation + this.campaign.cc_EmailCSV;
    }

    if (ccInformation) {
      ccInformation =
        ccInformation.lastIndexOf(', ') == ccInformation.length - 2
          ? ccInformation.slice(0, -2)
          : ccInformation;
      this.campaignBlockDetail.ccData = ccInformation;
      this.campaignBlockDetail['ccDataToolTip'] = ccInformation;
    }
  }

  private setBCCValue() {
    let bccInformation = '';
    if (this.campaign.bcc_ContactTypeIdCSV) {
      let Ids = this.campaign.bcc_ContactTypeIdCSV.split(',');
      let Names = '';
      for (let i = 0; i < Ids.length; i++) {
        let username = this.masterContactTypes.find(
          (x) => x.item_id.toLowerCase() == Ids[i].toLowerCase()
        );
        if (username) {
          Names += username.item_text + ', ';
        }
      }
      bccInformation = Names;
    }
    if (this.campaign.bcc_LoanAssignTypeIdCSV) {
      let Ids = this.campaign.bcc_LoanAssignTypeIdCSV.split(',');
      let Names = '';
      for (let i = 0; i < Ids.length; i++) {
        let username = this.loanAssignTypeRoles.find(
          (x) => x.item_id.toLowerCase() == Ids[i].toLowerCase()
        );
        if (username) {
          Names += username.item_text + ', ';
        }
      }
      bccInformation = bccInformation + Names;
    }
    if (this.campaign.bcc_EmailCSV) {
      bccInformation = bccInformation + this.campaign.bcc_EmailCSV;
    }

    if (bccInformation) {
      bccInformation =
        bccInformation.lastIndexOf(', ') == bccInformation.length - 2
          ? bccInformation.slice(0, -2)
          : bccInformation;
      this.campaignBlockDetail.bccData = bccInformation;
      this.campaignBlockDetail['bccDataToolTip'] = bccInformation;
    }
  }

  private setTOValue() {
    let toInformation = '';
    // Start to field manipulation
    if (this.campaign.to_ContactTypeIdCSV) {
      let toLists = this.campaign.to_ContactTypeIdCSV.split(',');
      let toList = '';
      for (let i = 0; i < toLists.length; i++) {
        let item = this.masterContactTypes.find(
          (x) => x.item_id.toLowerCase() == toLists[i].toLowerCase()
        );
        if (item) {
          toList += item.item_text + ', ';
        }
      }
      toInformation = toList;
    }

    if (this.campaign.to_LoanAssignTypeIdCSV) {
      let toLists = this.campaign.to_LoanAssignTypeIdCSV.split(',');
      let toList = '';
      for (let i = 0; i < toLists.length; i++) {
        let item = this.loanAssignTypeRoles.find(
          (x) => x.item_id.toLowerCase() == toLists[i].toLowerCase()
        );
        if (item) {
          toList += item.item_text + ', ';
        }
      }
      toInformation = toInformation + toList;
    }

    if (this.campaign.to_EmailCSV) {
      toInformation = toInformation + this.campaign.to_EmailCSV;
    }

    if (toInformation) {
      toInformation =
        toInformation.lastIndexOf(', ') == toInformation.length - 2
          ? toInformation.slice(0, -2)
          : toInformation;
      this.campaignBlockDetail.toRecipientData = toInformation;
      this.campaignBlockDetail['toRecipientDataToolTip'] = toInformation;
    }
  }

  private setwhoCanUseCSV() {
    if (this.currentClientID === 'AduvoAdmin') {
      this.campaignBlockDetail.whoCanUsers = 'All users';
    } else {
      if (this.campaign.whoCanUseCSV && this.userList) {
        let userIds = this.campaign.whoCanUseCSV.toLowerCase().split(',');
        if (this.userList?.length == userIds?.length) {//can also check IsAllUsersWhoCanUseThisCampaign flag. 
          this.campaignBlockDetail.whoCanUsers = 'All users';
          this.campaign.isAllUsersWhoCanUseThisCampaign = true;
        }
        else {
          let userNames = '';
          this.campaign.isAllUsersWhoCanUseThisCampaign = false;
          for (let i = 0; i < userIds.length; i++) {
            let username = this.userList.find(
              (x) => (x.item_id || '').toLowerCase() == userIds[i]
            );
            if (username) {
              userNames += username.item_text + ', ';
            }
          }
          this.campaignBlockDetail.whoCanUsers =
            userNames.lastIndexOf(', ') == userNames.length - 2
              ? userNames.slice(0, -2)
              : userNames;
        }
      }
    }
  }

  private setCampaignCategory() {
    if (this.campaign.campaignCategoryId) {
      let category = this.campaignCategoryList.find(
        (x) => x.item_id == this.campaign.campaignCategoryId
      );

      if (category) {
        this.campaignBlockDetail.campaignCategory = category.item_text;
      }
    }
  }

  private setToList() {
    if (this.campaign.toListCSV && this.loanStatusTypeMasterList) {
      let toLists = this.campaign.toListCSV.split(',');
      let toList = '';
      for (let i = 0; i < toLists.length; i++) {
        let item = this.loanStatusTypeMasterList.find(
          (x) => x.item_id.toLowerCase() == toLists[i].toLowerCase()
        );
        if (item) {
          toList += item.item_text + ', ';
        }
      }
      this.campaignBlockDetail.toList =
        toList.lastIndexOf(', ') == toList.length - 2
          ? toList.slice(0, -2)
          : toList;

      this.campaignBlockDetail['toListToolTip'] = toList;
    }
  }

  async onCloseDetailsDialog() {
    this.showCampaignDetailsDialog = false;
  }
  async onCloseDuplicateDialog() {
    this.showCopyCampaignDialog = false;
  }
  async onCloseDeleteCampaignDialog() {
    this.showCopyCampaignDialog = false;
  }

  async onSaveDetailsDialog() {
    setTimeout(() => {
      this.showCampaignDetailsDialog = false;
    }, 100);
  }

  async onShowMessageSubjectDialog(message: any) {
    if (this.campaign.isPublishDbRecord) {
      return;
    }
    this.messageSubject = '';
    this.preHeaderText = '';
    this.messageId = '';
    if (message) {
      this.currentMessage = message;
      this.messageSubject = message.subject;
      this.preHeaderText = message.preHeaderText;
      this.messageId = message.campaignMessageId;
    }
    setTimeout(() => {
      this.showMessageSubjectDialog = true;
    }, 100);
  }

  // async onShowMessagePreviewtexttDialog(message:any) {
  //   this.preHeaderText='';
  //   this.messageId='';
  //   if(message) {
  //     this.currentMessage=message;
  //     this.preHeaderText=message.preHeaderText;
  //     this.messageId=message.campaignMessageId;
  //   }
  //   setTimeout(() => {
  //     this.showMessagePreviewtextDialog = true;
  //   }, 100);
  // }

  async onCloseMessageSubjectDialog() {
    this.showMessageSubjectDialog = false;
  }

  async onCancelMessageSubjectDialog() {
    this.showMessageSubjectDialog = false;
  }

  async onCancelMessagePreviewtextDialog() {
    this.showMessagePreviewtextDialog = false;
  }

  async onSaveMessageSubjectDialog() {
    this.messagesService
      .UpdateSubjectAndPreviewTextField(
        this.campaignId,
        this.messageId,
        this.messageSubject,
        this.preHeaderText
      )
      .subscribe((data) => {
        this.currentMessage.subject = this.messageSubject;
        this.currentMessage.preHeaderText = this.preHeaderText;
        this.showMessageSubjectDialog = false;
      });
  }

  // async onSaveMessagePreviewtextDialog() {

  //   let message = new Message();
  //     message.MessageId=this.messageId;
  //     message.PreHeaderText=this.preHeaderText;
  //     await this.messagesService.UpdateMessageField('MessagePreviewText',message);
  //     this.currentMessage.PreHeaderText=this.preHeaderText;
  //   setTimeout(() => {
  //     this.showMessagePreviewtextDialog = false;

  //     //if (this.refreshRequired)
  //     //  this.refreshData();
  //   }, 100);
  // }

  async onShowMessageScheduleDialog(message: any) {
    if (this.campaign.isPublishDbRecord) {
      return;
    }
    if (message) {
      if (this.campaign.published && this.currentClientID !== 'AduvoAdmin') {//show confirmation dialog stating the campaign will be deactivated.
        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              'This will require deactivating the Current Campaign. Do you want to proceed?',
            successButtonText: 'Yes, I want to Proceed',
            cancelButtonText: 'Cancel',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.deactivateCampaign(this.campaign.campaignId);
            this.addMessageScheduleDialog(message);
          }
        });

      }
      else {// Normal flow. 
        this.addMessageScheduleDialog(message);
      }

    }
  }

  async addMessageScheduleDialog(message) {

    this.messageScheduleData.showMessageScheduleDialog = true;
    this.campaignMessageScheduleDataService.campaignMessageId = message.campaignMessageId;
    this.campaignMessageScheduleDataService.scheduleType = message.scheduleType;
    this.campaignMessageScheduleDataService.scheduleSubtype = message.scheduleSubtype;
    this.campaignMessageScheduleDataService.scheduleDataJSON = message.scheduleDataJSON;
    this.campaignMessageScheduleDataService.dialogTitle = message.messageTemplateTitle;
    this.campaignMessageScheduleDataService.isReload = false;
    this.campaignMessageScheduleDataService.tabIndex = 0; //default
    this.campaignMessageScheduleDataService.componentIndex = 0; //default
    this.campaignMessageScheduleDataService.campaignMessageType = message.templateTypeId;
    if (message.scheduleType) {
      this.campaignMessageScheduleDataService.tabIndex = message.scheduleType == 'OneTime' ? 0 : 1;
    }
    if (message.scheduleSubtype) {
      let index = 0;
      if (message.scheduleSubtype == 'StatusQuickPick') {
        index = 0;
      } else if (message.scheduleSubtype == 'HolidayQuickPick') {
        index = 1;
      } else if (message.scheduleSubtype == 'ContactQuickPick') {
        index = 10;
      } else if (message.scheduleSubtype == 'CustomDate') {
        index = 2;
      } else if (message.scheduleSubtype == 'YearlyCustom') {
        index = 4;
      } else if (message.scheduleSubtype == 'YearlyHoliday') {
        index = 5;
      } else if (message.scheduleSubtype == 'YearlyContact') {
        index = 6;
      } else if (message.scheduleSubtype == 'WeeklyWeekday') {
        index = 7;
      } else if (message.scheduleSubtype == 'Monthly') {
        index = 8;
      } else if (message.scheduleSubtype == 'DailyEvent') {
        index = 9;
      }
      this.campaignMessageScheduleDataService.componentIndex = index;
    }
    // console.log('tabIndex: ' + this.campaignMessageScheduleDataService.tabIndex);
    // console.log('componentIndex: ' + this.campaignMessageScheduleDataService.componentIndex);
    this.currentMessage = message;
    this.messageId = message.campaignMessageId;
  }

  async onCloseMessageScheduleDialog() {
    this.messageScheduleData.showMessageScheduleDialog = false;
    //this.showMessageScheduleDialog = false;
    //this.messageScheduleData.loanStatusTypeId = '';
    //this.loanStatusTypeId = '';
  }

  public DialogCloseEvent(event: any) {
    if (event) {
      this.messageScheduleData.showMessageScheduleDialog = false;
    }
  }
  async onCancelMessageScheduleDialog() {
    this.messageScheduleData.showMessageScheduleDialog = false;
    //this.showMessageScheduleDialog = false;
  }

  // async onSaveMessageScheduleDialog() {
  //   if (this.messageScheduleData.loanStatusTypeId) {
  //     await this.campaignsService.UpdateScheduleMessage(
  //       this.messageId,
  //       this.messageScheduleData.loanStatusTypeId
  //     );
  //     this.currentMessage.QuickPickStatusField = this.status.find(
  //       (x) => x.loanStatusTypeId == this.messageScheduleData.loanStatusTypeId
  //     ).displayName;
  //     this.currentMessage.QuickPickStatusFieldId = this.messageScheduleData.loanStatusTypeId;
  //     setTimeout(() => {
  //       this.messageScheduleData.showMessageScheduleDialog =false;
  //       //this.showMessageScheduleDialog = false;
  //       this.messageScheduleData.loanStatusTypeId = '';
  //     }, 100);
  //   }
  // }

  async onSelectTemplate(isSelected: boolean) {
    this.showNewMessageWizardVisible = false;
    //this.messages = await this.messagesService.GetCampaignMessages(this.campaignId);
    await this.updateMessageTypes();
    this.ClearMessageTemplate();

  }

  private ClearMessageTemplate() {
    if (this.messageTemplateDialogModel) {
      this.messageTemplateDialogModel.currentMessageType = 0; //show all
      this.messageTemplateDialogModel.oldMessageTemplateTitle = '';
      this.messageTemplateDialogModel.oldMessageTemplateId = '';
      this.messageTemplateDialogModel.isChangeMessage = false;
      this.updateMsgModel.campaignId = null;
      this.updateMsgModel.campaignMessageId = null;
    }
  }

  getCreateTemplate(templateModel: any) {
    if (templateModel.ChangeTemplate) {
      //reload template
      this.loadingPage = true;
      let campaignMessageId = this.messageTemplateDialogModel.campaignMessageId;
      //this.expandedRows[campaignMessageId] = true;
      //Load for Single Record only
      this.messagesService
        .GetCampaignMessageById(this.campaignId, campaignMessageId)
        .subscribe(
          (response) => {
            console.log(response);
            if (response?.data) {
              let index = this.messages.data.findIndex(
                (x) => x.campaignMessageId == campaignMessageId
              );
              let msg = this.messages.data[index];
              if (index > -1) {
                msg.templateId = response.data.templateId;
                msg.subject = response.data.subject;
                msg.scheduleType = response.data.scheduleType;
                msg.scheduleSubtype = response.data.scheduleSubtype;
                msg.scheduleJson = response.data.scheduleJson;
                msg.sentJson = response.data.sentJson;
                msg.messageTypeToSend = response.data.messageTypeToSend;
                msg.messageTemplateTitle =
                  response.data?.messageTemplateTitle ?? '';
                msg.preHeaderText = response.data.preHeaderText;
                msg.sentJson = response.data.sentJson;
                msg.isPublishingDb = response.data.isPublishingDb;
              }
            }
            this.loadingPage = false;
          },
          (err) => {
            this.loadingPage = false;
          }
        );
    } else {
      //this.newTempalteModel.emit({ data: response.data, ChangeTemplate: isChangeTemplate });
      this.messages.data.push(templateModel.data);
      this.expandedRows[templateModel.data.campaignMessageId] = true;
      this.setCampaignMessageType();
      //let textMessage = this.messages.data.find((o) => o.templateTypeId == 2);
      this.getMessageDetails(templateModel.data);
    }
  }

  async updateMessageTypes() {
    //let messageTypes=0;
    // let textMessage=this.messages.find(x=> x.MessageTypeId==1);
    // let emailMessage=this.messages.find(x=> x.MessageTypeId==0);
    // if(textMessage && emailMessage) {
    //   messageTypes=3;
    // } else if(textMessage) {
    //   messageTypes=2;
    // } else if(emailMessage) {
    //   messageTypes=1;
    // }
    // this.campaign.messageTypes=messageTypes;
    // await this.campaignsService.Save(this.campaign);
  }

  // async onSaveTemplateMessage(){
  //   this.loadingPage = true;
  //   try {
  //     await this.messagesService.SaveCampaignMessage(this.campaignId,this.messageModel);
  //     this.getData(this.campaignId);
  //     this.showNewMessageWizardVisible = false;

  //   } catch (message: any) {
  //     this.loadingPage = false;
  //     this.showNewMessageWizardVisible = false;
  //     this.toastService.showError(message);
  //   }
  // }

  onShowNewMessageDialog() {
    if (!this.campaign.isPublishDbRecord) {
      if (this.campaign.published && this.currentClientID !== 'AduvoAdmin') {//show confirmation dialog stating the campaign will be deactivated.

        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              'This will require deactivating the Current Campaign. Do you want to proceed?',
            successButtonText: 'Yes, I want to Proceed',
            cancelButtonText: 'Cancel',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.deactivateCampaign(this.campaign.campaignId);
            this.addMessage();
          }
        });

      }
      else {// Normal flow. 
        this.addMessage();
      }
    }
  }

  private addMessage() {
    this.updateMsgModel = new UpdateMsgModel();
    this.updateMsgModel.isCalledFromWizard = 4;    //called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4
    this.ClearMessageTemplate();
    this.updateMsgModel.campaignId = this.campaignId;
    this.updateMsgModel.campaignMessageId = null;
    setTimeout(() => {
      this.showNewMessageWizardVisible = true;
    }, 100);
  }
  // #endregion

  onCloseNewMessageDialog() {
    this.messageTemplateDialogModel = new MessageTemplateDialogModel();
    this.messageTemplateDialogModel.campaignId = this.campaignId;
    this.showNewMessageWizardVisible = false;
    this.messageTemplateDialogModel.resetPaging = true;
  }

  onSaveNewMessage() {
    this.saveMessagesFromFilter();
  }

  closeNewMessageDialog() {
    this.messageTemplateDialogModel = new MessageTemplateDialogModel();
    this.messageTemplateDialogModel.campaignId = this.campaignId;
    this.messageTemplateDialogModel.resetPaging = true;
    this.showNewMessageWizardVisible = false;
    this.changeMessageDetails = null;
    this.SMSInProgress = false;
    this.selectItemsForWizard = [];
  }

  /**
   * Called when the Preview context menu item is chosen.
   *
   * @param event
   */
  async onMessagePreview(event: any, message: any) {
    // message.isPublishingDb
    //Case #1 if the record bleongs to publish db
    //Case #2 if record doesn't belong to publish db but messagetemplate belongs to publishing db
    //as while cloning we do not clone message template and use publishdb template.
    let isPublishDbRecord = this.campaign?.isPublishDbRecord ?? false;
    if (!isPublishDbRecord) {
      isPublishDbRecord = message.isPublishingDb ?? false;
    }
    var template = await this.messageTemplateService.GetTemplateById(
      message.templateId,
      isPublishDbRecord
    );
    this.templateBody = template.data.previewBody;
    this.messageTitle = template.data.title;

    // TODO: need to use selected template.
    if (message.templateTypeId == 1) {
      let dlg: any = this.previewDialog;
      dlg.initializeDialog(this.templateBody, 616, 936);
      this.showPreviewDialog = true;
    } else {
      //SMS Preview
      if (template.data.body === '') {
        //do nothing
      } else {
        this.smsMsgBody = JSON.parse(this.templateBody) ?? [];
      }
      this.showTextPreviewDialog = true;
      this.messageCategoryList.forEach((item, index) => {
        if (item.item_id == template.data.categoryId) {
          this.messageCategory = item.item_text;
        }
      });
    }
  }

  async onSendTestEmail() {
    try {
      const request = {
        templateId: this.messageTemplateId,
        fromEmail: this.fromEmail,
        toEmails: this.toEmail,
        subject: this.emailSubject,
        preHeaderText: this.preHeaderText,
        isPublishingDb:
          this.campaign.isPublishDbRecord || this.isPublishedDbMessage,
      };
      this.messageTemplateService
        .SendTestEmail(request)
        .subscribe((response) => {
          // convert the 'data' property of the RequestResponse object's 'data' property JSON string value to an object.
          if (response) {
            this.isEmailResponse = true;
            if (response && response.data) {
              this.responseType = response.data;
              this.responseMessage = 'Email was sent successfully!';
            } else {
              this.responseType = false;
              this.responseMessage = 'Failed to send email.';
            }
          }
        });
    } catch (errorMessage) {
      this.editorState.loadingPage = false;
      this.responseType = false;
      this.responseMessage = 'Failed to send email.';
      this.isEmailResponse = true;
    }
  }

  async onChangeMessage(event: any, message: any) {
    this.changeMessageDetails = message;
    this.updateMsgModel.campaignId = this.campaign.campaignId;//replace case 
    this.updateMsgModel.campaignMessageId = message.campaignMessageId;//replace case 

    if (this.campaign.published && !this.campaign.isPublishDbRecord && this.currentClientID !== 'AduvoAdmin') {
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message:
            'This will require deactivating the Current Campaign. Do you want to proceed?',
          successButtonText: 'Yes, I want to Proceed',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          this.deactivateCampaign(this.campaign.campaignId);
          this.changeMessage(message);
        }
      });
    }
    else {
      this.changeMessage(message);
    }
  }

  private changeMessage(message: any) {
    this.messageTemplateDialogModel = new MessageTemplateDialogModel();
    this.messageTemplateDialogModel.campaignId = this.campaignId;
    this.messageTemplateDialogModel.currentMessageType = message.templateTypeId; //1 email/2 SMS [to filter Msg by Type]
    this.messageTemplateDialogModel.campaignMessageId = message.campaignMessageId;
    this.messageTemplateDialogModel.oldMessageTemplateTitle = message.messageTemplateTitle;
    this.messageTemplateDialogModel.oldMessageTemplateId = message.templateId;
    this.messageTemplateDialogModel.isChangeMessage = true;
    //called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4
    this.updateMsgModel.isCalledFromWizard = 3;//Change Msg Scenario.
    this.updateMsgModel.campaignId = this.campaignId;
    this.updateMsgModel.campaignMessageId = message.campaignMessageId;
    this.updateMsgModel.messageTypeToSend = message.messageTypeToSend;  //message.messageTypeToSend: "SMS"/"Email"
    this.updateMsgModel.oldMessageTemplateId = message.templateId;
    this.updateMsgModel.isChangeMessage = true;

    setTimeout(() => {
      this.showNewMessageWizardVisible = true;
    }, 100);
  }

  onSendAnother() {
    this.isEmailResponse = false;
    this.responseType = false;
    this.responseMessage = '';
    this.toEmail = '';
    this.fromEmail = this.userToken.TenantDefaultSenderEmail;
    this.validateEmailDialog();
  }

  onCloseSendTestEmailDialog() {
    this.showSendTestEmailDialog = false;
    //this.editorState.previewing = false;
    this.isEmailResponse = false;
    this.responseMessage = '';
    this.responseType = false;
    this.toEmail = '';
    this.isPublishedDbMessage = false;
  }

  openSendEmailDialoge(message: any) {
    this.showSendTestEmailDialog = true;
    this.messageTemplateId = message.templateId;
    this.emailSubject = message.subject;
    this.preHeaderText = message.preHeaderText;
    this.isEmailResponse = false;
    this.fromEmail = this.userToken.TenantDefaultSenderEmail;
    this.toEmail = '';
    this.isPublishedDbMessage = message?.isPublishingDb ?? false;
    this.validateEmailDialog();
  }

  validateEmailDialog() {
    let isValid = false;
    let errorMessage: string = '';
    let toEmails;
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var multipleEmailRegularExpression =
      /^(\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}[,;])*\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}$/;
    this.isEmailDialogeValid = true;
    this.emailDialogeErrorMessage = '';

    isValid = singleEmailRegularExpression.test(
      String(this.fromEmail).toLowerCase()
    );
    if (!isValid) {
      errorMessage += '*From email address is not valid; ';
      this.isEmailDialogeValid = false;
    }

    isValid = this.validateMultipleEmails(
      this.toEmail.replace(/\s/g, '').toLowerCase()
    );
    if (!isValid && this.toEmail != '') {
      errorMessage += '*To email address is not valid; ';
      this.isEmailDialogeValid = false;
    } else if (this.toEmail.trim() == '') {
      this.isEmailDialogeValid = false;
    }

    if (this.emailSubject.trim() == '') {
      errorMessage += '*Subject is not valid; ';
      this.isEmailDialogeValid = false;
    }
    this.emailDialogeErrorMessage = errorMessage;
  }

  validateMultipleEmails(emailIds: string) {
    let isValid = false;
    let isAllEmailvalid = true;
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailIds && emailIds.length > 0) {
      let emails = emailIds
        .split(';')
        .map((e) => e.split(','))
        .reduce((acc, cur) => cur.concat(acc), [])
        .filter((e) => !!e);
      if (emails && emails.length > 0) {
        for (var x = 0; x < emails.length; x++) {
          isValid = singleEmailRegularExpression.test(emails[x]);
          if (!isValid) {
            isAllEmailvalid = false;
          }
        }
      } else {
        isAllEmailvalid = false;
      }
    } else {
      isAllEmailvalid = false;
    }
    return isAllEmailvalid;
  }

  /**
   * Called when the Edit context menu item is chosen.
   *
   * @param event
   */
  onEditMessageClick(message: any) {
    //if (!this.campaign.isPublishDbRecord && this.currentClientID !== 'AduvoAdmin') {
    if (!this.campaign.isPublishDbRecord) {//PV6UI-1437 Bug
      if (this.campaign.published) {//show confirmation dialog stating the campaign will be deactivated.
        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              'This will require deactivating the Current Campaign. Do you want to proceed?',
            successButtonText: 'Yes, I want to Proceed',
            cancelButtonText: 'Cancel',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.deactivateCampaign(this.campaign.campaignId);
            this.editMessage(message);
          }
        });

      }
      else {// Normal flow. 
        this.editMessage(message);
      }
    }
  }

  editMessage(message) {
    // give time for the Stripo Template View to clear;
    if (!message?.isPublishingDb) {
      //can't edit messagetemplate whihc belong to PublishingDb
      setTimeout(async () => {
        //Sent message.isPublishingDb in GetTemplateById (Note: here campaign doesn't belong to isPublishDbRecord (it may be copied though)
        //Message has a isPublishingDb property, we are sending it as we are not copying message template
        //while creating clone/copy from publish site and to fetch template details from publish db this flag needs to be sent  )

        let messageTemplate = (
          await this.messageTemplateService.GetTemplateById(
            message.templateId,
            message.isPublishingDb
          )
        ).data;
        //await this.templateEditor.loadTemplate(messageTemplate);
        this.showTemplateEditor = true;
        this.messageTemplateService.setMessageTemplate(messageTemplate, window.location.href);
        // this._router.navigate([
        //   '/marketing/template-library/edit/' + message.templateId,
        // ]);
        this._router.navigate(['/marketing/template-library/edit/' + message.templateId], {
          state: {
            Mode: "CE"
          },
        });
      }, 250);
    }
  }

  async onSubscriberActivityLinkClick() {
    this.showSubscriberActivity = true;
    this.showDetails = false;
    this.showReports = false;
    this.showSentMessageLog = false;

    this.activeNav = 'subscriberActivity';
  }

  async onReportLinkClick() {
    this.showReports = true;
    this.showDetails = false;
    this.showSubscriberActivity = false;
    this.showSentMessageLog = false;

    this.activeNav = 'reports';
  }

  async onCampaignDetailsClick() {
    this.showSubscriberActivity = false;
    this.showReports = false;
    this.showDetails = true;
    this.showSentMessageLog = false;

    this.activeNav = 'details';
  }

  onSentMessageLogLinkClick() {
    this.showSubscriberActivity = false;
    this.showReports = false;
    this.showDetails = false;
    this.showSentMessageLog = true;

    this.activeNav = 'sentMessageLog';
  }

  onMessageDelete(event: any, message: any) {
    if (this.campaign.isPublishDbRecord) {
      console.log('PublishingDb campaign, cannot delete.');
      return;
    }

    this.deleteCampaignMessageTitle = 'Please confirm you want to delete ' + message.messageTemplateTitle +
      ' from ' + this.campaign.name + ' ?' + (this.campaign.published && this.currentClientID !== "AduvoAdmin" ? '<br>Removing message requires deactivating the campaign.' : '');

    if (this.campaign.published && (message.scheduleDataJSON != 'No Schedule' && message.scheduleDataJSON != null)) {
      let scheduledMsgCount = 0;
      this.messages.data.forEach((item, index) => {
        if ((item.scheduleType != '' || item.scheduleSubtype != '')) {
          scheduledMsgCount++;
        }
      });

      if (scheduledMsgCount == 1) {
        this.toastService.showError('Last scheduled message cannot be deleted of published campaign!');
      }
      else {
        this.showDeleteCampaignMessageDialog = true;
        this.currentMessage = message;
      }
    }
    else {
      this.showDeleteCampaignMessageDialog = true;
      this.currentMessage = message;
    }
  }

  async onDeleteCampaignMessage() {
    try {
      if (this.campaign.isPublishDbRecord) {
        return;
      }
      this.loadingPage = true;

      if (this.campaign.published && this.currentClientID !== "AduvoAdmin") {
        this.deactivateCampaign(this.campaignId);
      }

      let index = this.messages.data.findIndex(
        (x) => x.campaignMessageId === this.currentMessage.campaignMessageId
      );
      await this.messagesService.DeleteCampaignMessage(
        this.campaignId,
        this.currentMessage.campaignMessageId
      );
      this.messages.data.splice(index, 1);
      this.toastService.showSuccess('Campaign message deleted successfully.');
      this.loadingPage = false;
      this.showDeleteCampaignMessageDialog = false;
      this.setCampaignMessageType();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  /**
   * 'Cancel' of 'X' clicked on the preview dialog.
   */
  onClosePreviewDialog() {
    this.showPreviewDialog = false;
    this.showTextPreviewDialog = false;
  }

  async createEmailMessageDesign(event: any) {
    let template = new MessageTemplate();
    template.templateTypeId = 1;

    template.categoryDescription = 'Uncategorized';

    await this.templateEditor.loadTemplate(template);
    // give time for the Stripo Template View to clear;
    setTimeout(() => {
      this.showTemplateEditor = true;
    }, 250);
  }

  async createTextMessageDesign(event: any) {
    let template = new MessageTemplate();
    template.templateTypeId = 2;
    template.categoryDescription = 'Uncategorized';

    await this.templateEditor.loadTemplate(template);
    // give time for the Stripo Template View to clear;
    setTimeout(() => {
      this.showTemplateEditor = true;
    }, 250);
  }


  onSwithActive() {

    var startText =
      "\n";
    var text = '';
    var msgScheduled = false;

    if (this.messages?.data?.length == 0) {
      msgScheduled = false;
    }

    this.messages?.data?.forEach((item, index) => {
      if ((item.scheduleType != '' || item.scheduleSubtype != '') &&
        msgScheduled == false
      ) {
        msgScheduled = true;
      }
    });

    if (!msgScheduled) {
      text += " <li> At least one scheduled message </li> ";
      this.showValidationFlag = true;
    } else {
      this.showValidationFlag = false;
    }

    let userRoleNameFoundInToRecipientList = '';
    this.userRoleFoundInToRecipientList = false;
    if (this.campaignBlockDetail.toRecipientData) {
      let toRecipientList = this.campaignBlockDetail.toRecipientData.split(', ');

      this.loanAssignTypeRoles.forEach((item1) => {
        const index = toRecipientList.findIndex(obj => obj === item1.item_text)
        if (index > -1) {
          this.userRoleFoundInToRecipientList = true;
          userRoleNameFoundInToRecipientList += item1.item_text + ", ";
        }
      })
      var index = userRoleNameFoundInToRecipientList.lastIndexOf(", ");
      if (index > 0) {
        userRoleNameFoundInToRecipientList = userRoleNameFoundInToRecipientList.substring(0, index);
      }
    }

    if (!this.campaignBlockDetail.campaignCategory) {
      text += "<li> Campaign Category </li> ";
    }

    if (!this.campaignBlockDetail.fromValue) {
      text += " <li> From </li> ";
    }

    if (!this.campaignBlockDetail.toList) {
      text += "<li> To list </li> ";
    }

    if (!this.campaignBlockDetail.toRecipientData) {
      text += " <li> To recipients </li>  ";
    }

    let singleList = ''

    if (this.campaignBlockDetail.ccData) {
      singleList += this.campaignBlockDetail.ccData + ','
    }
    if (this.campaignBlockDetail.toRecipientData) {
      singleList += this.campaignBlockDetail.toRecipientData + ','
    }
    if (this.campaignBlockDetail.bccData) {
      singleList += this.campaignBlockDetail.bccData + ','
    }
    let commonInList = singleList.split(',');
    let commonFoundInList = this.toFindDuplicatesExists(commonInList);

    if (commonFoundInList) {
      text += " <li> Each role may only appear once within the To, CC, and BCC fields</li>  ";
    }

    if (this.userRoleFoundInToRecipientList) {
      this.userRoleNameFoundInToRecipientListMessage = "Please be aware that users such as Loan Officers placed in the 'To Recipients' field will only be processed for Status Quick Pick messages.";
    } else {
      this.userRoleNameFoundInToRecipientListMessage = '';
    }

    if (text) {
      this.validationString = startText + text;
      this.validationString = this.validationString.slice(0, -2);
      this.showValidateCampaignDialog = true;
      this.campaignDetailBlock.updateValidationFlag(true);
    } else {
      this.campaignDetailBlock.updateValidationFlag(false);
      this.showValidationFlag = false;
      this.validationString = '';
      this.showValidateCampaignDialog = false;
      if (msgScheduled) {
        this.showUpgradeSubscriptionDialog = true;
      }
    }
  }

  toFindDuplicatesExists(arry) {
    let toMap = {};
    let resultToReturn = false;
    for (let i = 0; i < arry.length; i++) {
      if (toMap[arry[i]]) {
        resultToReturn = true;
        // terminate the loop
        break;
      }
      toMap[arry[i]] = true;
    }
    return resultToReturn;
  }

  onSwithInctive() {
    this.activeInactiveCampaign(this.campaignId, false);
  }

  onCloseUpgradeSubscriptionDialog() {
    this.showUpgradeSubscriptionDialog = false;
  }

  onUpgradeSubscriptionDialog() {
    this.activeInactiveCampaign(this.campaignId, true);
    //this.showUpgradeSubscriptionDialog = false;
  }

  async getMergeTagsData() {
    if (ExternalMergeTags?.allTags?.length === 0) {
      const mergeTagData = await this.mergeTagsService.getMergeTagsData(
        true,
        true,
        true,
        false
      );
      ExternalMergeTags.allTags = mergeTagData.data;
    }
  }

  async getLast12AndPopularMergeTagsData() {
    let popularMergeTagData = (
      await this.mergeTagsService.getPopularMergeTagsData(
        true,
        true,
        true,
        false
      )
    ).data;

    this.mergeTagsService
      .getFinalMergeTagsToDisplay(popularMergeTagData)
      .then((x) => {
        ExternalMergeTags.changePopularTags(x);
      });
  }

  async onEmailSubjectMergeTagRequested() {
    ExternalMergeTags.open(this.onEmailSubjectMergeTagSelected.bind(this));
  }

  onEmailSubjectMergeTagSelected(tagVal) {
    let control: any = this.txtEmailSubject;
    let temp: any = '' + (this.messageSubject ?? '');

    let caretPos = control.nativeElement.selectionStart;
    caretPos = caretPos + tagVal.length;
    let prefix = temp.substr(0, control.nativeElement.selectionStart);
    let suffix = temp.substr(control.nativeElement.selectionEnd);
    temp = prefix + tagVal + suffix;

    control.nativeElement.focus();

    if (temp.length > 459) {
      return;
    }

    control.nativeElement.value = temp;
    this.messageSubject = temp;

    control.nativeElement.selectionStart = caretPos;
    control.nativeElement.selectionEnd = caretPos;

    this.mergeTagsService.updateLastUsedMergeTag(tagVal);
  }

  async onEmailPreviewTextMergeTagRequested() {
    ExternalMergeTags.open(this.onEmailPreviewTextMergeTagSelected.bind(this));
  }

  onEmailPreviewTextMergeTagSelected(tagVal) {
    let control: any = this.txtMessagePreviewtext;
    let temp: any = '' + (this.preHeaderText ?? '');

    let caretPos = control.nativeElement.selectionStart;
    caretPos = caretPos + tagVal.length;
    let prefix = temp.substr(0, control.nativeElement.selectionStart);
    let suffix = temp.substr(control.nativeElement.selectionEnd);
    temp = prefix + tagVal + suffix;

    control.nativeElement.focus();

    if (temp.length > 459) {
      return;
    }

    control.nativeElement.value = temp;
    this.preHeaderText = temp;

    control.nativeElement.selectionStart = caretPos;
    control.nativeElement.selectionEnd = caretPos;

    this.mergeTagsService.updateLastUsedMergeTag(tagVal);
  }

  showTooltipInfo() {
    //debugger;
    var startText =
      '\n ';
    var text = '';
    var msgScheduled = false;

    if (this.messages?.data?.length == 0) {
      msgScheduled = false;
    }

    this.messages?.data?.forEach((item, index) => {
      if (
        (item.scheduleType != '' || item.scheduleSubtype != '') &&
        msgScheduled == false
      ) {
        msgScheduled = true;
      }
    });

    if (!msgScheduled) {
      text += '<li> At least one scheduled message </li> ';
    }

    if (!this.campaignBlockDetail.campaignCategory) {
      text += '<li> Campaign Category </li> ';
    }

    // if (!this.campaignBlockDetail.whoCanUsers) {
    //   text += '<li> Who can use this campaign </li> ';
    // }

    if (!this.campaignBlockDetail.fromValue) {
      text += '<li> From </li> ';
    }

    if (!this.campaignBlockDetail.toList) {
      text += ' <li> To list </li> ';
    }

    if (!this.campaignBlockDetail.toRecipientData) {
      text += '<li> To recipients </li> ';
    }

    let singleList = ''
    //if (this.campaignBlockDetail.fromValue) {
    //  singleList = this.campaignBlockDetail.fromValue + ','
    //}
    if (this.campaignBlockDetail.ccData) {
      singleList += this.campaignBlockDetail.ccData + ','
    }
    if (this.campaignBlockDetail.toRecipientData) {
      singleList += this.campaignBlockDetail.toRecipientData + ','
    }
    if (this.campaignBlockDetail.bccData) {
      singleList += this.campaignBlockDetail.bccData + ','
    }
    let commonInList = singleList.split(',');
    let commonFoundInList = this.toFindDuplicatesExists(commonInList);

    if (commonFoundInList) {
      text += " <li> Each role may only appear once within the To, CC, and BCC fields</li>  ";
    }

    if (text) {
      this.validationString = startText + text;
      this.validationString = this.validationString.slice(0, -2);
      this.invalidCampaignDetails = true;
    } else {
      this.invalidCampaignDetails = false;
      this.validationString = '';
    }
  }

  async loadCampaign(campaignId: string){
    
  }

  async activeInactiveCampaign(campaignId: string, campaignStatus: boolean) {

    var numberOfCampaignsActivatedBefore = await this.campaignsService.HasActivated();
    this.loadingPage = true;
    try {
      await this.campaignsService.TogglePublishedCampaign(campaignId, this.campaign.isPublishDbRecord);
      var response = await this.campaignsService.Get();
      var runMode;
      response.data.forEach(element => {
        runMode = element.runMode
      });

      if (runMode === "TEST" && campaignStatus && this.currentClientID != 'AduvoAdmin') {
        this.showTestModeDialog = true;
      }

      this.campaign.published = campaignStatus;

      if (this.campaign.published){
        if(numberOfCampaignsActivatedBefore === 0){

          this.celebrationService.triggerCelebration();

        } else {

          this.toastService.showSuccess(`Campaign Activated`);

        }
      }
      else {
        this.toastService.showSuccess('Campaign Deactivated');
        this.isDeactivated = true;
      }

      this.showUpgradeSubscriptionDialog = false;
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  closeDeactivate() {
    this.isDeactivated = false;
  }

  isDefaultDate(date) {
    let dateTime: Date = new Date(date);
    if (dateTime.getFullYear() == 1900) {
      return true;
    } else {
      return false;
    }
  }

  async getMessageDetails(message: any) {
    try {

      if ((message.templateTypeId == 2) && message.messageBody != '') {
        message.messageBody = JSON.parse(message.messageBody) ?? [];
      }
      // if (
      //   !message.MessageStats &&
      //   (Number(this.campaignEmailStats?.EmailStats?.sentCount) > 0 ||
      //     Number(this.campaignEmailStats?.SmsStats?.sentCount) > 0)
      // ) {
      if (this.currentClientID != 'AduvoAdmin') {
        message.isCampaignMessageStatsLoading = true;
        this.userAnalyticsService
          .getCampaignMessageStats(
            message.campaignMessageId,
            message.templateTypeId == 1
          )
          .subscribe((response) => {
            let campaignMessageEmailStats = response.data;

            if (response.data) {
              let index = this.messages.data.findIndex(
                (element) =>
                  element.campaignMessageId == message.campaignMessageId
              );
              if (index != -1) {
                this.messages.data[index].MessageStats =
                  campaignMessageEmailStats;
              }
              //updatedItem.MessageStats=campaignMessageEmailStats;
            }
            message.isCampaignMessageStatsLoading = false;
          });
      }
    } catch (ex: any) {
      message.isCampaignMessageStatsLoading = false;
    }
  }

  validateCampaignDetails() {
    if (this.campaign.isPublishDbRecord) {
      return false;
    }
    if (this.campaignDetails.isToEmailInValid || this.campaignDetails.isBCCEmailInValid ||
      this.campaignDetails.isCCEmailInValid || this.campaignDetails.isFromEmailInValid) {
      return true;
    }
    return false; // this.campaignDetails.validateAllDetails();
  }

  async setCampaignMessageType() {
    if (this.messages.data && this.messages.data.length > 0) {
      let textMessage = this.messages.data.find((o) => o.templateTypeId == 2);
      let emailMessage = this.messages.data.find((o) => o.templateTypeId == 1);
      if (textMessage && emailMessage) {
        this.campaign.templateTypeCount = 3;
      } else if (textMessage && !emailMessage) {
        this.campaign.templateTypeCount = 2;
      } else if (!textMessage && emailMessage) {
        this.campaign.templateTypeCount = 1;
      } else {
        this.campaign.templateTypeCount = 0;
      }
    } else {
      this.campaign.templateTypeCount = 0;
    }
    this.isCampaignStatsLoading = true;

    this.getCampaignStats(this.campaignId, this.campaign.templateTypeCount);
  }

  getCampaignStats(campaignId: string, templateTypeCount: number) {
    if (this.currentClientID != "AduvoAdmin") {
      this.userAnalyticsService.getCampaignStats(campaignId, templateTypeCount).subscribe(
        (result) => {
          this.campaignEmailStats = result.data;
          this.isCampaignStatsLoading = false;
        },
        (err) => {
          this.isCampaignStatsLoading = false;
        }
      );
    }
  }

  clearCampaignDetails() {
    this.campaignCategory = '';
    this.WhoCanUsers = '';
    this.toList = '';
    this.toRecipientData = '';
    this.ccData = '';
    this.bccData = '';
    this.fromValue = '';
  }

  emojiClicked($event: any) {
    this.onEmailSubjectMergeTagSelected($event.emoji.native);
  }

  emojiClickedPreview($event: any) {
    this.onEmailPreviewTextMergeTagSelected($event.emoji.native);
  }
  //----
  openSendSMSDialog(campaignMessageId: string) {
    // this.showSendTestSMSDialog = true;
    // this.smsMsgBody = JSON.parse(message?.messageBody) ?? [];
    this.messagesService
      .getMessageBodyHtml(this.campaignId, campaignMessageId)
      .subscribe((resp) => {
        if (resp.data) {
          this.smsMsgBody = JSON.parse(resp?.data?.body) ?? [];
          // this.smsMsgBody = [];
          // for (const [key, value] of Object.entries((resp?.data?.body ?? []))) {
          //   this.smsMsgBody.push(value.toString());
          // }
          this.showSendTestSMSDialog = true;
        }
      });
  }

  onCloseSendTestSMSDialog() {
    this.showSendTestSMSDialog = false;
    this.smsSentSuccess = false;
    this.SMSInProgress = false;
    this.toNumber.setValue('');
  }

  sendTestSMS() {
    if (this.toNumber.value && this.smsMsgBody.length) {
      this.SMSInProgress = true;
      this.SMSService.SendSms(this.smsMsgBody, this.toNumber.value).subscribe(
        (response: RequestResponse) => {
          if (response?.data) {
            this.smsSentSuccess = true;
            this.toastService.showSuccess('Test Sms sent successfully.');
          } else {
            this.smsSentSuccess = false;
            this.toastService.showError(response.message);
            this.toNumber.setValue('');
          }
          this.SMSInProgress = false;
        },
        (err) => {
          this.SMSInProgress = false;
        }
      );
    } else {
      this.smsSentSuccess = false;
      this.toastService.showError('Please enter a correct number');
    }
  }

  private deactivateCampaign(campaignId: string) {
    this.campaign.published = false;
    this.campaignsService.inActivateCampaign(campaignId).subscribe((resp) => {
      //this.cdr.detectChanges();
    });
  }

  sendTestSMSAnother() {
    this.smsSentSuccess = false;
    this.SMSInProgress = false;
    this.toNumber.setValue('');
  }

  async saveMessagesFromFilter() {
    if (this.selectItemsForWizard.length) {
      this.SMSInProgress = true;//reusing loader comp
      //called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4
      //Repalce Scenario
      if (this.updateMsgModel.isCalledFromWizard == 3 && this.changeMessageDetails) {
        let messageModel = {
          campaignId: this.campaignId,
          campaignMessageId: this.changeMessageDetails.campaignMessageId,
          templateId: this.selectItemsForWizard[0].id,
          scheduleJson: '',
          sentJson: '',
          dateStartUtc: null,
          dateEndUtc: null,
          preHeaderText: this.changeMessageDetails.preHeaderText,
          isPublishingDb: this.selectItemsForWizard[0].isPublishDb,
          subject: this.selectItemsForWizard[0].html
        }
        let response = await this.messagesService.UpdateCampaignMessage(messageModel);
        this.reloadMessages(response?.data?.campaignMessageId);
      }//add multiple tempalte Scenario
      else if (this.updateMsgModel.isCalledFromWizard == 4) {
        try {
          let ret: boolean[] = [];
          for (let index = 0; index < this.selectItemsForWizard.length; index++) {
            let messageModel = new MessageCampaign();
            messageModel.campaignId = this.campaignId;
            messageModel.templateId = this.selectItemsForWizard[index].id;
            messageModel.isPublishingDb = this.selectItemsForWizard[index].isPublishDb ?? false;
            // messageModel.subject = this.changeMessageDetails?.subject ?? '';
            // messageModel.preHeaderText = this.changeMessageDetails?.preHeaderText ?? '';
            // messageModel.isPublishingDb = this.changeMessageDetails?.isPublishDB  ;
            var response = await this.messagesService.SelectCampaignMessage(messageModel);//create
            if (response.data) {
              ret.push(true)
            }
          }
          if (ret.length != this.selectItemsForWizard.length) {
            console.log('Error while selecting message.');
          }
          this.reloadMessages(response?.data?.campaignMessageId);
          this.SMSInProgress = false;
          this.changeMessageDetails = null;
        }
        catch (error) {
          this.changeMessageDetails = null;
          this.SMSInProgress = false;
        }
      }
    }
  }

  private reloadMessages(campaignMessageId = null) {
    this.messagesService.GetCampaignMessagesObs(this.campaignId).subscribe((data) => {
      this.messages = data;
      this.changeMessageDetails = null;
      this.SMSInProgress = false;

      //load stats for newly added/changed msg template.
      if (campaignMessageId) {
        let msg = this.messages.data.find((x) => x.campaignMessageId.toLowerCase() == campaignMessageId.toLowerCase());
        if (msg) {
          this.getMessageDetails(msg);
        }
      }

      this.setCampaignMessageType();

      this.CreateScheduleMessage();

      this.closeNewMessageDialog();
    }, err => { this.SMSInProgress = false; this.changeMessageDetails = null; });
  }

  onItemsSelectForWizardEvent(event: TemplateHtmlData[]) {
    this.selectItemsForWizard = [];
    if (event.length) {
      this.selectItemsForWizard = event;
    }
  }

  // getSmsArray(body: any) {
  //   let msgBody: string[] = [];
  //   for (const [key, value] of Object.entries(Object.entries(JSON.parse(body ?? [])))) {
  //     msgBody.push(value.toString());
  //   }
  //   return msgBody;
  // }

  ngOnDestroy(): void {
    this.changeMessageDetails = null;
    this.messages = null;
    this.webAPI = null;
    this.campaign = null;
    this.editorState = null;
    this.messages = null;
    this.messageScheduleData = null;
    this.campaignCategoryList = null;
    this.loanStatusList = null;
    this.userList = null;
    this.loanStatusTypeMasterList = null;
    this.masterContactTypes = null;
    this.loanAssignTypeRoles = null;
    this.campaignDetails = null;
    this.messageTemplateDialogModel = null;
    this.HolidayEventList = null;
    this.TimezoneList = null;
    this.loanStatusTypeList = null;
    this.contactsEventList = null;
    this.campaignBlockDetail = null;
    this.selectItemsForWizard = null;
    this.updateMsgModel = null;
  }

  GoToCompanyProfile() {
    this._router.navigate(['/company-profile']);
  }

  loadMessageLogsWithFiltered($event: any) {
    this.activeIndex = 2;
    this.MessageLogGridComponent.loadTableWithFilters($event);
  }
}
