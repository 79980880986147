import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasTenantUserId'
})
export class HasTenantUserIdPipe implements PipeTransform {

  transform(array: any[]): boolean {
    
    if (!Array.isArray(array)) {
      return false;
    }

    const hasInvalidTenantUserId = array.some(
      record => record.tenantUserId === '00000000-0000-0000-0000-000000000000'
    );

    return hasInvalidTenantUserId;
  }

}