import { AppSettings } from 'src/appSettings';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestResponse } from 'src/models/RequestResponse';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private http: HttpClient) { }

  GetMasterContactTypes(){
    return this.http.get<RequestResponse>(AppSettings.API_GET_LISTS + '/MasterContactType')
  }
}
