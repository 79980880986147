import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ListService } from '../services/list.service';
import { ContactsService } from 'src/services/contacts.service';

export class CustomValidators {
  static atLeastOne: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent) {
      return null;
    }

    const formGroup = control.parent as FormGroup;
    const emailControl = formGroup.get('email');
    const mobilePhoneControl = formGroup.get('mobilePhone');

    if (!emailControl || !mobilePhoneControl) {
      return null;
    }

    const emailValue = emailControl.value ? emailControl.value.trim() : '';
    const mobilePhoneValue = mobilePhoneControl.value ? mobilePhoneControl.value.trim() : '';
    const isAtLeastOneFilled = emailValue !== '' || mobilePhoneValue !== '';

    return isAtLeastOneFilled ? null : { atLeastOneRequired: true };
  };

}

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateContactDialogComponent implements OnChanges {

  @Input() isVisible: boolean = false;
  contactForm: FormGroup;
  contactTypes: Array<any>;
  focusedElement: string | null = null;
  displayCompanyFields: boolean = false;
  @Output() CloseDialog: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private listService: ListService, private contactsService: ContactsService) {

    this.contactForm = this.formBuilder.group({
      contactType: ['', Validators.required],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [CustomValidators.atLeastOne]],
      mobilePhone: ['', [CustomValidators.atLeastOne]],
      homePhone: ['', []],
      workPhone: ['', []],
      birthday: ['', []],

      companyName: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      phoneNumber: ['']
    });


    this.contactForm.get('email')?.valueChanges.subscribe(() => {
      this.contactForm.get('mobilePhone')?.updateValueAndValidity({ emitEvent: false });
    });

    this.contactForm.get('mobilePhone')?.valueChanges.subscribe(() => {
      this.contactForm.get('email')?.updateValueAndValidity({ emitEvent: false });
    });

    this.contactForm.get('contactType')?.valueChanges.subscribe(() => {
      const contactTypeName = this.contactTypes.find(s => s.masterContactTypeId === this.contactForm.get('contactType').value)?.name.toLowerCase();
      const containsBorrowerOrCoborrower = contactTypeName?.includes('borrower') || contactTypeName?.includes('coborrower') || contactTypeName?.includes('lead');

      const companyNameControl = this.contactForm.get('companyName');

      if (containsBorrowerOrCoborrower) {
        companyNameControl?.clearValidators();
        this.displayCompanyFields = false;
        this.contactForm.get('companyName')?.reset();
        this.contactForm.get('address')?.reset();
        this.contactForm.get('city')?.reset();
        this.contactForm.get('state')?.reset();
        this.contactForm.get('zip')?.reset();
        this.contactForm.get('phoneNumber')?.reset();
      } else {
        companyNameControl?.setValidators([Validators.required]);
        this.displayCompanyFields = true;
      }

      companyNameControl?.updateValueAndValidity();
            
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisible'] && changes['isVisible'].currentValue) {
      this.loadContactTypes();
    }
  }

  loadContactTypes() {
    this.listService.GetMasterContactTypes().subscribe((response) => {
      this.contactTypes = response.data;
      this.contactTypes = [...this.contactTypes];
    });
  }

  submit() {

    if (this.contactForm.valid) {

      const postContactRequest: any = {
        contactTypeId: this.contactForm.get('contactType')?.value,
        firstName: this.contactForm.get('firstName')?.value,
        lastName: this.contactForm.get('lastName')?.value,
        email: this.contactForm.get('email')?.value,
        mobilePhone: this.contactForm.get('mobilePhone')?.value,
        workPhone: this.contactForm.get('workPhone')?.value,
        homePhone: this.contactForm.get('homePhone')?.value,
        birthday: this.contactForm.get('birthday')?.value,
        companyName: this.contactForm.get('companyName')?.value,
        address: this.contactForm.get('address')?.value,
        city: this.contactForm.get('city')?.value,
        state: this.contactForm.get('state')?.value,
        zip: this.contactForm.get('zip')?.value,
        phoneNumber: this.contactForm.get('phoneNumber')?.value
      };

      this.contactsService.PostContact(postContactRequest).then((response) => {
        this.CloseDialog.emit();
        this.contactForm.reset();
      }).catch((error) => {
        console.error(error);
      });

    }

  }

}
