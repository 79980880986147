import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CelebrationService } from './celebration.service';
declare var lottie: any;
@Component({
  selector: 'app-celebration',
  templateUrl: './celebration.component.html',
  styleUrls: ['./celebration.component.scss'],
})
export class CelebrationComponent implements OnInit {

  // Declare the lottie variable globally
  lottie: any;
  isCelebrationDialogVisible = false;
  @ViewChild('lottiePlayer') lottiePlayer: ElementRef; 
  private autoCloseTimer: any;

  constructor(private celebrationService: CelebrationService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.celebrationService.getCelebrationObservable().subscribe(() => {
      this.showAnimation();
      this.startAutoCloseTimer();
    });
  }

  ngAfterViewInit() {

    customElements.whenDefined('dotlottie-player').then(() => {
      console.log('dotlottie-player is ready to use');
    });
  }

  showAnimation() {
    this.isCelebrationDialogVisible = true; // Show the animation dialog

    // Once the dialog is visible, you can replay the animation by seeking or reloading
    setTimeout(() => {
      if (this.lottiePlayer && this.lottiePlayer.nativeElement) {
        this.lottiePlayer.nativeElement.seek(0); // Reset the animation to the beginning
        this.lottiePlayer.nativeElement.play(); // Play the animation again
      }
    }, 0); // Short timeout to ensure the dialog is fully rendered
  }

  hideAnimation() {
    this.isCelebrationDialogVisible = false; // Hide the animation dialog

    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer);
    }

    this.cdr.detectChanges();
  }

  startAutoCloseTimer(): void {
    // Set the auto-close timer for 5 seconds (5000 milliseconds)
    this.autoCloseTimer = setTimeout(() => {
      this.hideAnimation(); // Automatically hide the dialog after 5 seconds
    }, 5000);
  }
}
