<!--
*************************************************************************************
Page Heading and Buttons
*************************************************************************************
-->
<div id="manageUsersDiv" class="content" role="main">
  <div class="content table-search-row second-header-fixed">
    <table class="text-wrapper">
      <tr>
        <td>
          <label id="lblPageHeader">Manage Users</label>
          <span class="p-input-icon-left p-float-label">
            <input [(ngModel)]="searchKeyword" class="search-input rounded-pill" pInputText type="text"
              (keyup.enter)="onApplyGlobalFilter()" (input)="onGlobalFilterChange()" placeholder="Search by keyword" />
          </span>
          <span type="button" pButton pRipple icon="fal fa-search search-fa" pTooltip="Execute Search"
            tooltipPosition="bottom" showDelay="500" (click)="onApplyGlobalFilter()"
            class="p-button-secondary execute-search" style="margin-bottom: 10px">
          </span>
          <div class="input-group search-field position-relative p-ml-4"></div>
        </td>
        <td align="right">
          <button pButton icon="pi pi-user-plus text-white" label="Add New User" class="header-button addnewuser-button"
            (click)="AddNewUser('account/manage-users/add')"></button>
        </td>
      </tr>
    </table>
  </div>
</div>

<!-- 
#########################
  Action Button
#########################
-->

<div class="text-wrapper py-1 mb-3 grid-section">
  <button class="btn btn-outline-primary dropdown-toggle create-btn rounded-pill font-size-16" type="button"
    id="create-new-dropdown" data-bs-toggle="dropdown" aria-expanded="false" *ngIf="webAPI.paging.totalRecords > 0">
    <span>Actions</span>
  </button>
  <ul class="dropdown-menu" aria-labelledby="create-new-dropdown">
    <ng-container  *ngIf="selectedRows | hasTenantUserId; else nonAduvoUserSelected">
      <li><a class="dropdown-item dropdown-item-disabled cursor-auto" pTooltip="Non-Aduvo users are selected, unselect them to use this feature.">Reset Password</a></li>
      <li><a class="dropdown-item dropdown-item-disabled cursor-auto" pTooltip="Non-Aduvo users are selected, unselect them to use this feature.">Change Subscription</a></li>
      <li><a class="dropdown-item dropdown-item-disabled cursor-auto" pTooltip="Non-Aduvo users are selected, unselect them to use this feature.">Delete</a></li>
      <li><a class="dropdown-item dropdown-item-disabled cursor-auto" pTooltip="Non-Aduvo users are selected, unselect them to use this feature.">Remove From Aduvo</a></li>
    </ng-container> 
    <ng-template #nonAduvoUserSelected>
      <li><a class="dropdown-item" (click)="onResetPasswords()">Reset Password</a></li>
      <li><a class="dropdown-item" (click)="onChangeSubscription()">Change Subscription</a></li>
      <li><a class="dropdown-item" (click)="onDeleteUsers()">Delete</a></li>
      <li><a class="dropdown-item" (click)="onRemoveFromAduvoUsers()">Remove From Aduvo</a></li>
    </ng-template>
    <li><a class="dropdown-item" (click)="reassignUser()">Reassign Contacts</a></li>
  </ul>
</div>

<!--
*************************************************************************************
Users Table
*************************************************************************************
-->
<div class="text-wrapper py-1 mb-5">
  <p-menu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #ellipseMenu [autoZIndex]="false"
    class="groundmenu" [popup]="true" [model]="contextMenuItems"></p-menu>
  <p-table #manageUserTableId class="pulseTable" [value]="userList" [lazy]="true" (onLazyLoad)="loadUsers($event)"
    [rows]="25" [rowsPerPageOptions]="[25, 50, 100]" [paginator]="webAPI.paging.totalRecords > 0"
    [totalRecords]="webAPI.paging.totalRecords" [loading]="loadingPage" [scrollable]="false" scrollWidth="100%"
    scrollHeight="100%" scrollDirection="both" [resizableColumns]="true" [style]="{ width: '100%' }"
    [virtualScroll]="false" sortField="name" [sortOrder]="1" [showCurrentPageReport]="true" [(first)]="pageIndex"
    [(selection)]="selectedRows">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>
          <!-- <p-checkbox [()] (click)="selectRow(e.checked)" #e></p-checkbox> -->
          <p-tableHeaderCheckbox (click)="selectRow(e.checked)" #e></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="name">
          Name <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="userType">
          User Type <p-sortIcon field="userType"></p-sortIcon>
        </th>
        <th>Email</th>
        <th>Mobile</th>
        <!-- <th *ngIf="currentClientID === 'Aduvo'">LOS USER</th> -->
        <th *ngIf="currentClientID === 'Aduvo'">LOS ROLE</th>
        <!-- <th *ngIf="currentClientID === 'Aduvo'">LOS STATUS</th> -->
        <th pSortableColumn="billingOwner">Billing Owner <p-sortIcon field="billingOwner"></p-sortIcon></th>
        <th pSortableColumn="subscriptionType">Subscription Type <p-sortIcon field="subscriptionType"></p-sortIcon></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-record let-rowIndex="rowIndex" let-columns="columns">
      <tr [pSelectableRow]="record">
        <td></td>
        <td pTooltip="You can't select non Aduvo users."
          [tooltipDisabled]="record.tenantUserId !== '00000000-0000-0000-0000-000000000000'" tooltipPosition="right"
          showDelay="700">
          <!--<p-tableCheckbox style="width: 30px;" [value]="record"
            [disabled]="record.tenantUserId == '00000000-0000-0000-0000-000000000000'">
          </p-tableCheckbox>-->
          <p-tableCheckbox style="width: 30px;" [value]="record">
          </p-tableCheckbox>
        </td>
        <td class="title-column" (click)="onRowEdit(row)" *ngIf="record.userType !== 'Reactivate'">
          <span class="userGridName"><a style="color: var(--primary-color-pulse);font-weight: normal;" [routerLink]="">{{
              record.name
              }}</a></span>
        </td>
        <td class="title-column" (click)="onRowReactivate(row, record)" *ngIf="record.userType === 'Reactivate'">
          <span class="userGridName"><a style="color: var(--primary-color-pulse);font-weight: normal;" [routerLink]="">{{
              record.name
              }}</a></span>
        </td>
        <td class="title-column" (click)="onRowEdit(row)" *ngIf="record.userType === 'Add to Aduvo'">
          <span class="userGridName"><a style="color: var(--primary-color-pulse);font-weight: normal;" [routerLink]="">{{
              record.userType
              }}</a></span>
        </td>
        <td class="title-column" (click)="onRowReactivate(row, record)" *ngIf="record.userType === 'Reactivate'">
          <span class="userGridName"><a style="color: var(--primary-color-pulse);font-weight: normal;" [routerLink]="">{{
              record.userType
              }}</a></span>
        </td>
        <td class="p-body-colum user-manager-table-text" *ngIf="
            record.userType !== 'Add to Aduvo' &&
            record.userType !== 'Reactivate'
          ">
          {{ record.userType }}
        </td>
        <td class="p-body-colum user-manager-table-text">{{ record.emailId }}</td>
        <td class="p-body-colum user-manager-table-text">{{ record.phoneNumber }}</td>
        <!-- <td class="p-body-colum" *ngIf="currentClientID === 'Aduvo'">{{ record.losUser }}</td> -->
        <td class="p-body-colum user-manager-table-text" *ngIf="currentClientID === 'Aduvo'">{{ record.losRole }}</td>
        <!-- <td class="p-body-colum" *ngIf="currentClientID === 'Aduvo'">{{ record.losStatus }}</td> -->
        <td class="p-body-colum user-manager-table-text">{{ record.billingOwner || "" }}</td>
        <td class="p-body-colum user-manager-table-text" *ngIf="record.subscriptionType !== 'Premium'">
          <button class="btn-limited remove-cursor">{{record.subscriptionType }} Access</button>
        </td>
        <td class="p-body-colum user-manager-table-text" *ngIf="record.subscriptionType !== 'Limited'">
          <button class="btn-premium remove-cursor">{{record.subscriptionType }} Access</button>
        </td>
        <td class="table-ellipse">
          <button type="button" aria-haspopup="true" class="p-column-filter-menu-button table-ellipse-button"
            (focus)="ellipseMenu.hide()" (click)="onEllipseClick($event, record)">
            <span class="pi pi-ellipsis-h text-primary"></span>
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No records found</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft" let-state>
      <label style="font-weight: 400">{{ webAPI.paging.totalRecords }}
        {{ webAPI.paging.totalRecords > 1 ? "results" : "result" }}</label>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
      <div>
        <label style="margin-top: 1px; margin-right: 8px; font-weight: 400">Go to Page</label>
        <input type="text" pInputText pKeyFilter="int" [(ngModel)]="webAPI.paging.currentPage" width="3" maxlength="3"
          style="
            text-align: center;
            width: 4rem;
            margin-right: 8px;
            border-radius: 20px;
          " (change)="onGotoPage()" />
      </div>
    </ng-template>
  </p-table>
</div>

<!--
*************************************************************************************
Reassign User Dialog
*************************************************************************************
-->
<p-dialog class="reassign-user-dialog" [(visible)]="showReassignUserDialog" [resizable]="false" header="Reassign Contacts"
  [modal]="true" [style]="{ width: '50%' }">
  <div class="reassign-user-content">
    <div class="font-weight-bold">You've selected the following user(s):</div>
    <div>
      <ul>
        <li *ngFor="let row of selectedRows">
          <div>{{ row.name }}</div>
        </li>
      </ul>
    </div>
    <div class="font-weight-bold">Reassign their contacts to the following premium user:</div>
    <div>
      <p-dropdown [options]="premiumUsers" [(ngModel)]="selectedPremiumUser" appendTo="body" [(ngModel)]="selectedPremiumUser" 
        optionLabel="fullName" placeholder="Select User"></p-dropdown>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row justify-content-end">
      <div class="flex flex-column justify-content-center">
        <a class="cancel-text" (click)="showReassignUserDialog = false">Cancel</a>
      </div>
      <div>
        <p-button [style]="{ 'margin-left': '17.5px' }" label="Reassign" [disabled]="selectedPremiumUser == null" (click)="reAssignUsers()"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog class="reassign-user-dialog" [(visible)]="showUsersUpdatedConfirmation" [resizable]="false" header="Reassignment Successful"
  [modal]="true" [style]="{ width: '50%' }">
  <div class="reassign-user-content">
    <div class="font-weight-bold" *ngIf="numberOfUsersUpdated > 0">Reassignment Successful. {{numberOfUsersUpdated}} contacts/loans were updated</div>
    <div class="font-weight-bold" *ngIf="numberOfUsersUpdated === 0 && selectedRows?.length === 1">This user has no contacts to reassign</div>
    <div class="font-weight-bold" *ngIf="numberOfUsersUpdated === 0 && selectedRows?.length > 1">These users have no contacts to reassign</div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row justify-content-end">
      <div>
        <p-button [style]="{ 'margin-left': '17.5px' }" label="Ok" (click)="showUsersUpdatedConfirmation = false"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Confirm Dialogs
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000"></p-confirmDialog>
<p-dialog class="custom-alert custom-user-alert" id="delete_user" #deleteUserDialog [(visible)]="showDeleteDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.deleteMessage }}</span>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button [style]="{ 'margin-left': '17.5px' }" class="" (click)="deleteUser()" label="Yes, I want to delete"
        styleClass="p-button-rounded"></p-button>
      <p-button (click)="showDeleteDialog = false" [style]="{ 'margin-left': '17.5px' }"
        styleClass="p-button-secondary">
        Cancel</p-button>
      <!-- <a
        (click)="showDeleteDialog = false"
        styleClass="p-button-secondary"
        style="color:#666666;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;"
      >Cancel</a> -->
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Confirm Dialogs Remove From Pulse
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000"></p-confirmDialog>
<p-dialog class="custom-alert custom-user-alert" #removeFromPulseDialog [(visible)]="showRemoveFromPulseDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.deleteMessage }}</span>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="removeFromPulse()"
        label="Yes, I want to remove" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showRemoveFromPulseDialog = false" label="No"
        styleClass="p-button-secondary" style="margin-left: 10px"></p-button>
    </div>
  </ng-template>
</p-dialog>
<!--
*************************************************************************************
Reset Password  Confirmation Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" #removeFromPulseDialog [(visible)]="showResetPasswordDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.resetPasswordMessage }}</span>
  </div>
  <div class="pb-35">
    <ul class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;"
      *ngIf="isMultiUserPasswordResetRequest">
      <div *ngFor="let row of selectedRows">
        <li *ngIf="row.isEmailConfirmed">
          <div>{{row.name}}</div>
        </li>
      </div>
    </ul>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="resetPasswordConfirmation()"
        label="Yes, I want to reset password" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showResetPasswordDialog = false" label="No"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>
<p-confirmPopup></p-confirmPopup>
<!--
*************************************************************************************
Reset Two Factor  Confirmation Dialogs 
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000"></p-confirmDialog>
<p-dialog class="custom-alert custom-user-alert" #removeFromPulseDialog [(visible)]="showResetTFADialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">Please confirm you want to reset two factor authentication</span>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-left': '17.5px' }" class="" (click)="resetRsetTwoFactorConfirmation()"
        label="Yes, I want to reset" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showResetTFADialog = false" label="No"
        styleClass="p-button-secondary" style="margin-left: 10px"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Confirm Dialogs
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000"></p-confirmDialog>
<p-dialog class="custom-dialog" id="change_email" #changeEmailDialog [(visible)]="showChangeEmailDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <p-header>
    <span class="dialog-head">{{ changeUserEmailAddress }} </span>
  </p-header>
  <!-- <div>
   <div> {{changeUserEmailAddress}} </div>
    <i role="button" class="pi pi-times text-white" (click)="showChangeEmailDialog = false"></i>
  </div> -->
  <div class="modal-content">
    <div class="mb-4 row">
      <label class="col-md-8 pl-0 col-form-label text-dark">Current email address</label>
      <span class="alert-header pl-0" style="font-size: 14px !important; color: #797a7b !important">{{ currentEmailId
        }}</span>
    </div>
    <div class="mb-2 row">
      <label class="col-md-12 pl-0 col-form-label text-dark">Replace with new email address</label>
      <div class="col-md-12 pl-0">
        <input id="newEmailAddress" class="form-control" [(ngModel)]="blankEmailAddress"
          (input)="validateUserChangeEmail()" />
      </div>
    </div>
  </div>
  <div *ngIf="emailChangeFail" style="text-align: left; font-weight: 500; color: red">
    Email address already exists!
  </div>
  <div *ngIf="!isValidChangeEmail" style="text-align: keft; font-weight: 500; color: red">
    Email address is invalid!
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="text-right">
      <a (click)="showChangeEmailDialog = false" class="link-text" style="
          color: #2e2e2e;
          cursor: pointer;
          margin-right: 17.5px;
          line-height: 34px;
          text-decoration: underline;
        " styleClass="p-button-secondary">Cancel</a>
      <p-button (click)="saveChangeEmail()" [style]="{ 'margin-left': '17.5px' }" label="Save"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Update subscription
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000"></p-confirmDialog>
<p-dialog class="custom-dialog" id="updateSubscription" #Subscription [(visible)]="showUpdateSubscriptionDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <p-header>
    <span class="dialog-head">Change Subscription</span>
  </p-header>

  <div class="modal-content">
    <!----main content-->
    <div *ngIf="isSubscriptionDialogInitiate">
      <div class="subsection pb-0">
        <div class="row">
          <div class="p-field p-col-12 p-md-4 pt-0">
            <label for="subType" class="col-form-label member-section">MEMBERS</label>
            <div>
              <ul class="user-menu">
                <li *ngFor="let userName of selectedRows">
                  {{userName.name}}
                </li>
              </ul>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-4 pt-0">
            <label for="subType" class="col-form-label text-primary">SUBSCRIPTION TYPE</label>
            <p-dropdown id="subscriptionType" #subscriptionType [options]="subscriptionTypes"
              [(ngModel)]="selectedSubscriptionType" optionLabel="name" optionValue="lookupid" placeholder="Select">
            </p-dropdown>
          </div>
          <div class="p-field p-col-12 p-md-4 pt-0" *ngIf="isDisplayBillingOwner()">
            <label for="billOwner" class="col-form-label text-primary">BILLING OWNER</label>
            <p-dropdown style="width:150px" id="billingOwner" #billingOwner [options]="billingOwners"
              [(ngModel)]="selectedBillingOwner" optionLabel="displayName" optionValue="lookupid" placeholder="Select">
            </p-dropdown>
          </div>
        </div>
      </div>

      <div class="divider-hor"></div>
      <div class="subsection">
        <div class="subscription-box-accessPre">
          <div>
            <img _ngcontent-lkm-c215="" src="assets/images/badge.png" alt="" style="margin-top:-7px; margin-right: 5px;"
              class="ng-star-inserted">
            <label for="subType" class="col-form-label text-primary font-18">Premium Access</label>
            <div class="payingtag">PAYING SUBSCRIBER</div>
            <div class="payingadmin">Recommended for Loan Originators</div>
            <ul class="user-menu ml-50">
              <li>
                <div><i class="pi pi-check"></i> Create and edit email and test messages</div>
              </li>
              <li>
                <div><i class="pi pi-check"></i> Create and edit campaigns</div>
              </li>
              <li>
                <div><i class="pi pi-check"></i> Create and edit lists</div>
              </li>
              <li>
                <div><i class="pi pi-check"></i> Unlimited email and text message sends from the paying subscriber</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="subscription-box-accessLimit">
          <div>
            <img _ngcontent-lkm-c215="" src="assets/images/lock.png" alt="" style="margin-top:-7px; margin-right: 5px;"
              class="ng-star-inserted">
            <label for="subType" class="col-form-label text-primary font-18">Limited Access</label>
            <div class="payingtag">FREE ACCOUNT</div>
            <div class="payingadmin">Recommended for Support, Admin, and Marketing Staff</div>
            <ul class="user-menu ml-50">
              <li>
                <div><i class="pi pi-check"></i> Create and edit email and text messages</div>
              </li>
              <li>
                <div><i class="pi pi-check"></i> Create and edit campaigns</div>
              </li>
              <li>
                <div><i class="pi pi-check"></i> Create and edit lists</div>
              </li>
              <li>
                <div><i class="pi pi-times"></i> No emails or text messages will be sent from a limited user</div>
                <small><i>A limited user can send emails on behalf of a paying subscriber</i></small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--End--->
    <!---Success content-->
    <div class="subsection pb-0" *ngIf="!isSubscriptionDialogInitiate">
      <div class="font-18">An email invitation has been sent to the following user(s):</div>
      <div>
        <ul class="user-menu">
          <li *ngFor="let userName of selectedRows">
            <div>{{userName.name}}</div>
          </li>
        </ul>
      </div>
      <div class="font-weight-normal">
        These users will need to add payment info to continue using premium feature.
      </div>
    </div>
  </div>

  <div class="pb-35"></div>
  <div class="divider-hor"></div>

  <ng-template pTemplate="footer text-left">
    <div class="text-right subsection" *ngIf="isSubscriptionDialogInitiate">
      <a (click)="showUpdateSubscriptionDialog = false" class="link-text" style="
            color: #2e2e2e;
            cursor: pointer;
            margin-right: 17.5px;
            line-height: 34px;
            text-decoration: underline;
          " styleClass="p-button-secondary">Cancel</a>
      <p-button (click)="updateSubscription()" [style]="{ 'margin-left': '17.5px' }" label="Save Changes"></p-button>
    </div>
    <div class="text-right subsection" *ngIf="!isSubscriptionDialogInitiate">
      <p-button (click)="closeAfterSubscriptionEmailSent()" [style]="{ 'margin-left': '17.5px' }"
        label="Close"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Remove User Confirmation Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" [(visible)]="showMultiRemoveUserDialog" [resizable]="false"
  [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.removeMessage }}</span>
  </div>
  <div class="pb-35">
    <ul class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;"
      *ngIf="isMultiUserRemoveRequest">
      <div *ngFor="let row of selectedRows">
        <li *ngIf="row.losUser.toUpperCase() === 'NO'">
          <div>{{row.name}}</div>
        </li>
      </div>
    </ul>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="RemoveConfirmation()"
        label="Yes, I want to remove" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showMultiRemoveUserDialog = false" label="No"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Remove LOS User/Remove From Aduvo Confirmation Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" [(visible)]="showMultiRemoveLOSUserDialog" [resizable]="false"
  [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.removeMessage }}</span>
  </div>
  <div class="pb-35">
    <ul class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;"
      *ngIf="isMultiLOSUserRemoveRequest">
      <div *ngFor="let row of selectedRows">
        <li *ngIf="row.losUser.toUpperCase() === 'YES'">
          <div>{{row.name}}</div>
        </li>
      </div>
    </ul>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="RemoveLOSUserConfirmation()"
        label="Yes, I want to remove" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showMultiRemoveLOSUserDialog = false" label="No"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Generic message when no user in selected list Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" [(visible)]="showGenericMessageDialog" [resizable]="false"
  [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.genericMessage }}</span>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showGenericMessageDialog = false" label="Close"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Premium User Profile Validation Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" [(visible)]="showPremiumUserValidationDialog" [resizable]="false"
  [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.premiumUserValidationMessage }}</span>
  </div>
  <div class="pb-35">
    <ul class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;" *ngIf="isMultiUserSelected"
        [innerHTML]="nmlsUserString"></ul>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="showPremiumUserValidationDialog = false"
        label="Close" styleClass="p-button-rounded"></p-button>
    </div>
  </ng-template>
</p-dialog>