import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestResponse } from 'src/models/RequestResponse'
import { PagingData } from 'src/models/PagingData';
import { AppSettings } from 'src/appSettings';
import { NGENTenant, NGENTenantCollection } from 'src/models/NGENTenant';

@Injectable()
export class TenantService {
  constructor(private http: HttpClient) { }

  // ********************************************************************************
  // WEB API Methods
  // ********************************************************************************

  /**
   * Retrieves the Tenant Information for the currently logged on user.
   */
  async Get() {
    console.info('Call /GET in the webapi Tenant Controller');
    return await this.http.get<RequestResponse>(AppSettings.API_GET_TENANT)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetTenant(tenantId: string) {
    console.info('Call /GET in the webapi Tenant Controller');
    return await this.http.get<RequestResponse>(AppSettings.API_GET_TENANT + '/' + tenantId)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetAllTenant(requestResponse?: RequestResponse) {
    if (requestResponse === undefined || requestResponse === null) {
      requestResponse = new RequestResponse();
    }
    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      searchValue: requestResponse.data?.length > 0 ? requestResponse.data : '',
      orderBy: requestResponse.paging.orderBy,
    };

    return await this.http
      .post<RequestResponse>(AppSettings.API_GET_TENANT + '/allTenantList', searchModel)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async AddCompany(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Tenant Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_TENANT, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async EditCompany(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Tenant Controller');
    return await this.http.put<RequestResponse>(AppSettings.API_GET_TENANT, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async DeleteTenant(tenantId: string) {
    console.info("Call /DeleteTenant in the webapi Tenant Controller");
    return await this.http.delete<any>(AppSettings.API_GET_TENANT + '/' + tenantId)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /**
 * Saves a Company RunMode (tenant) record.
 */
  async SetCompanyRunMode(tenantId: string, runMode: string) {
    console.info('Call /SetCompanyRunMode in the webapi Tenant Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_TENANT + '/setRunMode?tenantId=' + tenantId + '&runMode=' + runMode, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }


  async SendCancellationRequest(tenantId: string, tenantUserId: string, isIndividual: boolean = false) {
    console.info('Call /cancelSubscription in the webapi Tenant Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_TENANT + '/cancelSubscription?tenantId=' + tenantId + '&tenantUserId=' + tenantUserId + '&isIndividual=' + isIndividual, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }


  /**
   * Error processing.
   *
   * @param caught The caught error information.
   */
  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }

  // ********************************************************************************
  // Helper Methods
  // ********************************************************************************

  toCollectionInstance(data: any[]) {
    let result: NGENTenantCollection = new NGENTenantCollection();
    for (let x = 0; x < data.length; x++)
      result.Items.push(this.toInstance(data[x]));
    return result;
  }

  toInstance(data: any) {
    let result = new NGENTenant(
      data.tenantId,
      data.name,
      data.lenderId,
      data.website,
      data.addressLine1,
      data.city,
      data.state,
      data.zip,
      data.email,
      data.workPhone,
      data.fax,
      data.Suite,
      data.defaultReplyEmail,
      data.defaultSenderEmail,
      data.stateLicenses,
      data.nmls,
      data.faceBookUrl,
      data.twitterUrl,
      data.linkedInUrl,
      data.leadIntakeUrl,
      data.leadIntakeDescription,
      data.signature,
      data.logo,
      '',
      0,
      data.runMode,
      data.lenderId,
      data.dbConnectionString,
      data.timezoneId,
      data.sendgridApiKeyLoanStatus,
      data.sendgridApiKeyMarketing,
      data.ein,
      data.legalBusinessName,
      data.businessType,
      data.businessRegistrationType,
      data.businessRegionsOfOperation,
      data.legalBusinessStreet,
      data.legalBusinessCity,
      data.legalBusinessCountry,
      data.legalBusinessState,
      data.legalBusinessZip,
      data.authorizedContactFirstName,
      data.authorizedContactLastName,
      data.authorizedContactEmail,
      data.authorizedContactBusinessTitle,
      data.authorizedContactCountry,
      data.authorizedContactPhone,
      data.authorizedContactJobPosition,
      data.companyAdminEmail,
      data.companyAdminFirstName,
      data.companyAdminLastName,
      data.integrationType,
      data.encompassClientId,
      data.encompassClientSecret,
      data.twilioAccountSid,
      data.twilioAPIKey,
      data.twilioMessagingServiceLoanStatus,
      data.twilioMessagingServiceMarketing,
      data.addressLine2,
      data.twilioEnabled
    );
    return result;
  }
} 
