<div>
    <form [formGroup]="contactForm" class="flex flex-column w-full create-contact-form" (ngSubmit)="submit()">
        <div class="flex flex-row w-full">
            <div class="flex flex-column" [ngClass]="{'pr-4 border-right':displayCompanyFields}">
                <div class="flex flex-column mb-2">
                    <label for="contactType">Contact Type</label>
                    <p-dropdown appendTo="body"
                        [options]="contactTypes" 
                        formControlName="contactType"
                        optionLabel="name"
                        optionValue="masterContactTypeId"
                        placeholder="Select a Contact Type"></p-dropdown>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="firstName">First Name</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="firstName" 
                            [placeholder]="'First Name is Required'"
                            class="create-contact-form-input"
                            aria-describedby="firstName"
                            formControlName="firstName" 
                            (focus)="focusedElement = 'firstName'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'firstName'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="lastName">Last Name</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="lastName" 
                            [placeholder]="'Last Name is Required'"
                            class="create-contact-form-input"
                            aria-describedby="lastName"
                            formControlName="lastName" 
                            (focus)="focusedElement = 'lastName'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon"  *ngIf="focusedElement === 'lastName'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="email">Email</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="email" 
                            [placeholder]="'Email is Required'"
                            class="create-contact-form-input"
                            aria-describedby="email"
                            formControlName="email" 
                            (focus)="focusedElement = 'email'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'email'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="mobilePhone">Mobile Phone</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="mobilePhone" 
                            [placeholder]="'Mobile Phone is Required'"
                            class="create-contact-form-input"
                            aria-describedby="mobilePhone"
                            formControlName="mobilePhone"
                            (focus)="focusedElement = 'mobilePhone'" (blur)="focusedElement = null" />
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'mobilePhone'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="homePhone">Home Phone</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="homePhone"
                            class="create-contact-form-input" 
                            aria-describedby="homePhone"
                            formControlName="homePhone" 
                            (focus)="focusedElement = 'homePhone'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'homePhone'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="workPhone">Work Phone</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="workPhone" 
                            class="create-contact-form-input"
                            aria-describedby="workPhone"
                            formControlName="workPhone" 
                            (focus)="focusedElement = 'workPhone'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'workPhone'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="birthday">Birthday</label>
                    <span class="p-input-icon-right flex flex-row">
                        <p-inputMask
                            styleClass="input-text-color"
                            class="custom-input-mask"
                            type="text" 
                            id="birthday"     
                            mask="99-99"
                            formControlName="birthday"
                            [placeholder]="'MM-DD'"
                            aria-describedby="birthday"
                            (onFocus)="focusedElement = 'birthday'" (onBlur)="focusedElement = null"
                            ></p-inputMask>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'birthday'"></i>
                    </span>
                </div>
            </div>
            <div class="flex flex-column ml-4" *ngIf="displayCompanyFields">
                <div class="flex flex-column mb-2">
                    <label for="companyName">Company Name</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="companyName" 
                            [placeholder]="'Company Name is Required'"
                            class="create-contact-form-input"
                            aria-describedby="companyName"
                            formControlName="companyName" 
                            (focus)="focusedElement = 'companyName'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'companyName'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="address">Address</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="address"
                            class="create-contact-form-input" 
                            aria-describedby="address"
                            formControlName="address" 
                            (focus)="focusedElement = 'address'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'address'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="city">City</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="city"
                            class="create-contact-form-input" 
                            aria-describedby="city"
                            formControlName="city" 
                            (focus)="focusedElement = 'city'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'city'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="state">State</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="state" 
                            class="create-contact-form-input"
                            aria-describedby="state"
                            formControlName="state" 
                            (focus)="focusedElement = 'state'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'state'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="zip">Zip</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="zip" 
                            class="create-contact-form-input"
                            aria-describedby="zip"
                            formControlName="zip" 
                            (focus)="focusedElement = 'zip'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'zip'"></i>
                    </span>
                </div>
                <div class="flex flex-column mb-2">
                    <label for="phoneNumber">Phone Number</label>
                    <span class="p-input-icon-right">
                        <input pInputText type="text" id="phoneNumber"
                            class="create-contact-form-input"
                            aria-describedby="phoneNumber"
                            formControlName="phoneNumber" 
                            (focus)="focusedElement = 'phoneNumber'" (blur)="focusedElement = null"/>
                        <i class="pi pi-pencil input-icon" *ngIf="focusedElement === 'phoneNumber'"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-content-end mt-4">
            <p-button label="Save" [disabled]="!contactForm.valid" type="submit"></p-button>
        </div>
    </form>
</div>