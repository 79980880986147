import { Component, OnInit } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { LookupService } from 'src/services/lookup.service';
import { BillingService } from 'src/services/billingService';
import { OnBoardingService } from '../../../services/onBoarding.service';
import { ToastService } from 'src/services/toast.service';
import { UserTokenService } from 'src/services/user-token.service';
export { }; declare global { interface Window { Calendly: any; } } 

@Component({
  selector: 'app-book-time',
  templateUrl: './book-time.component.html',
  styleUrls: ['./book-time.component.scss']
})
export class BookTimeComponent implements OnInit {
  public loadingPage: boolean = false;
  public selectedLabel: string;
  public selectedItem: any;
  public date: Date[];
  public showFullDate: string;
  public time: string;
  public navigationStack: string[] = [];
  public userData: any;
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";
  public tenantId: any = '00000000-0000-0000-0000-000000000000';
  public calendlyLink: string = '';
  public salesRep: string = '';
  public user: any;

  
  constructor(
    private router: Router,
    private lookupService: LookupService,
    private billingService: BillingService,
    private onBoardingService: OnBoardingService,
    private toastService: ToastService,
    private userTokenService: UserTokenService
  ) {
    this.date = null;
    this.showFullDate = '';
    this.time = '';
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.userData = this.storageHelper.GetDataFromStorage('Aduvo_userData');
    this.billingService.SaveCompanyOnBoarding(true);
    this.navigationStack = stack?.value;
   }

  async ngOnInit(): Promise<void> {
    this.user = this.userTokenService.getUser();
    //await new Promise(f => setTimeout(f, 5000));
     await this.getData();
     window.Calendly.initInlineWidget({
       url: this.calendlyLink,
       parentElement: document.querySelector('.calendly-inline-widget'),
       prefill: {
         name: this.userData.given_name + ' ' + this.userData.family_name,
         email: this.userData.email,
        },
     });
  }

  async getData() {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      var response = await this.onBoardingService.Get(this.tenantId);

      // when data is retrieved, set this.loadingPage to false.
      this.salesRep = response.data.salesRep;

      var calendlyLinkResponse = await this.lookupService.GetCalendlyLink(this.salesRep);

      this.calendlyLink = calendlyLinkResponse.data;

      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  redirectToCTAScreenDirectly(): void {
    let navigateTo = '/cta-screen';
    let navigateFrom = '/book-time';
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    this.storageHelper.SetDataInStorage("onBoardingCompleted", "true", 20);
    this.navigationStack.push(navigateFrom);
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    this.router.navigate([navigateTo]);
  }
}
