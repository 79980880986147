import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CelebrationService {

  private subject = new Subject<void>();
  
  constructor() { }

  triggerCelebration(): void {
    this.subject.next();
  }

  getCelebrationObservable() {
    return this.subject.asObservable();
  }
}
